import React, { useMemo, useState } from 'react';
import './style.css';
import { SubScriberCard } from './SubscriberCard';
import search from '../../../../assets/images/icons/search.svg';
import { ISubscriberModel } from '../../../../services/api/endpoints/task';
import { useTranslation } from 'react-i18next';

interface ListTaskSubscibersProps {
  subscribers: ISubscriberModel[];
  published: string;
}

export const TaskSubscribers: React.FC<ListTaskSubscibersProps> = ({ subscribers, published }) => {
  const { t } = useTranslation('common');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const searchedSubscribers = useMemo(() => {
    return subscribers.filter((subscriber) =>
      String(subscriber.user.name).toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, subscribers]);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <div className="block-content__input">
        <input
          value={searchQuery}
          onChange={changeHandler}
          className="block-content__search"
          placeholder={t('modals.subscribers.placeholder')}
        />
        <img src={search} className="block-content__icon" alt="" />
      </div>
      <div className="block-content__title">{t('modals.subscribers.subscribers')}</div>
      <div className="block-content__users">
        {!searchedSubscribers.length ? (
          <p>{t('modals.subscribers.notFound')}</p>
        ) : (
          searchedSubscribers.map((item) => {
            return (
              <div key={item.user.id}>
                <SubScriberCard
                  status={item.status}
                  published={published}
                  name={item.user.name}
                  userId={item.user.id}
                />
              </div>
            );
          })
        )}
      </div>
      <div className="block-content__title">{t('modals.subscribers.customers')}</div>
    </>
  );
};
