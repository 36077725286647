export enum TimereportFormFields {
  date = 'date',
  hrs = 'hrs',
  mins = 'mins',
  comment = 'comment',
}

export type ITimereportFormFields = {
  [TimereportFormFields.date]: Date;
  [TimereportFormFields.hrs]: string;
  [TimereportFormFields.mins]: string;
  [TimereportFormFields.comment]: string;
};
