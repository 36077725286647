import { ITimeReportsTabState } from './types/index';
import { RootState } from '../../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { IPagination, TimeReport } from '../../../services/api/endpoints/type';
import { ITimeReportParams } from '../../../services/api/endpoints/timeReport';
import { due_time_report } from '../../../utils/constants';

export const DEFAULT_COUNT = 100;

export const fetchTimeReportsAsync = createAsyncThunk<any, boolean, { state: RootState }>(
  'timeReportsTab/fetchTimeReports',
  async function (isPagination: boolean, { getState, dispatch }) {
    if (!isPagination) {
      dispatch(resetPagination());
    }

    const state = getState();

    const params: ITimeReportParams = {
      filter: state.timeReportsTab.filter,
      pagination: {
        page: state.timeReportsTab.pagination ? state.timeReportsTab.pagination.page : undefined,
        count: state.timeReportsTab.countPerPage || undefined,
      },
    };

    const response = await api.timeReports.getList(params);
    return response;
  }
);

export const fetchProjectsAsync = createAsyncThunk<any, void, { state: RootState }>(
  'timeReportsTab/fetchProjects',
  async function () {
    const response = await api.project.myProjects({ open_only: false });
    const data = response.data;
    return data;
  }
);

const initialState: ITimeReportsTabState = {
  filter: {
    date: '',
    date_until: '',
    taskid: '',
    user: '',
    date_enum: due_time_report[4].value, // 4 - текущий месяц
    project: undefined,
  },
  pagination: null,
  countPerPage: DEFAULT_COUNT,
  loading: {
    projects: false,
    data: false,
  },
  groupByDay: false,
  data: {
    projects: [],
    timeReports: [],
  },
};

const timeReportsTabSlice = createSlice({
  name: 'timeReportsTab',
  initialState: initialState,
  reducers: {
    changeFilterParams(state, action: PayloadAction<{ [key: string]: any }>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    changeGroupByDay(state, action: PayloadAction<{ [key: string]: any }>) {
      state.groupByDay = !action.payload.value;
    },
    resetPagination(state) {
      state.pagination = null;
    },
    changePage(state, action: PayloadAction<number>) {
      if (!state.pagination) return;

      if (action.payload <= state.pagination.pages_total && action.payload > 0) {
        state.pagination.page = action.payload;
      }
    },
    goToPrevPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page - 1 > 0) {
        state.pagination.page = state.pagination.page - 1;
      }
    },
    goToNextPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page + 1 <= state.pagination.pages_total) {
        state.pagination.page = state.pagination.page + 1;
      }
    },
    goToFirstPage(state) {
      if (!state.pagination) return;

      state.pagination.page = 1;
    },
    goToLastPage(state) {
      if (!state.pagination) return;

      state.pagination.page = state.pagination.pages_total;
    },
    changePaginationCount(state, action: PayloadAction<string>) {
      if (action.payload === '' || action.payload === null) {
        state.countPerPage = null;
      } else {
        state.countPerPage = Number(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsAsync.pending, (state, action) => {
        state.loading.projects = true;
      })
      .addCase(fetchProjectsAsync.fulfilled, (state, action) => {
        state.data.projects = action.payload;
        state.loading.projects = false;
      })
      .addCase(fetchTimeReportsAsync.pending, (state, action) => {
        state.loading.data = true;
      })
      .addCase(
        fetchTimeReportsAsync.fulfilled,
        (state, action: PayloadAction<IPagination<TimeReport>>) => {
          state.pagination = action.payload.pagination;
          state.data.timeReports = action.payload.data;
          state.loading.data = false;
        }
      );
  },
});

export const {
  changeFilterParams,
  changeGroupByDay,
  changePage,
  resetPagination,
  goToPrevPage,
  goToNextPage,
  goToFirstPage,
  goToLastPage,
  changePaginationCount,
} = timeReportsTabSlice.actions;
export default timeReportsTabSlice.reducer;
