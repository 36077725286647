import React from 'react';
import './style.css';
import { useDrag } from 'react-dnd';
import { ITask } from '../../../store/slices/tasks/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import treeArrow from '../../../assets/images/icons/tree-arrow.svg';
import treeArrowLong from '../../../assets/images/icons/tree-arrow-long.svg';
import ChangePriority from '../../changePriority';
import ChangeDue from '../../changeDue';
import ChangeAssignee from '../../changeAssignee';
import { Link } from 'react-router-dom';
import { changeTaskPriorityAsync } from '../../../store/slices/tasks';
import { Permissions } from '../../../types/permissions';

interface CardProps {
  task: ITask;
}

const getTreeArrow = (index: number) => {
  if (index > 0) {
    let arrow;
    if (index === 1) {
      arrow = treeArrow;
    } else if (index === 2) {
      arrow = treeArrowLong;
    }
    return <img src={arrow} alt="" />;
  } else {
    return '';
  }
};

const Card: React.FC<CardProps> = ({ task }) => {
  const { data } = useAppSelector((state) => state.tasks);
  const { tasks } = data;
  const dispatch = useAppDispatch();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'task',
    item: { task: task },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const getParentTasks = (taskChain: ITask[]): ITask[] => {
    const currentTask = taskChain[0];
    if (currentTask.parent_task === null) {
      return taskChain;
    }
    const parentTask = tasks.find((item) => item.id === currentTask.parent_task);
    if (!parentTask) {
      return taskChain;
    }
    taskChain.unshift(parentTask);
    return getParentTasks(taskChain);
  };

  const changePriorityHandler = (priority: number) => {
    dispatch(
      changeTaskPriorityAsync({
        id: task.id,
        priority,
      })
    );
  };

  return (
    <div ref={drag} className="b-kanban-card" style={{ opacity: isDragging ? 0.7 : 1 }}>
      <div className="b-kanban-card-chain">
        {getParentTasks([task]).map((item: ITask, index) => {
          return (
            <div className="b-kanban-card-chain-item" key={item.id}>
              {getTreeArrow(index)}
              <Link to={`/tasks/${item.id}`}>{item.name}</Link>
            </div>
          );
        })}
      </div>
      <div className="b-kanban-card-string">
        <div className="b-kanban-card-priority">
          {task.priority && task.priority > 0 ? (
            <ChangePriority
              onChangePriority={changePriorityHandler}
              priority={task.priority}
              permission={Permissions.update_tasks}
            />
          ) : (
            ''
          )}
        </div>
        <div className="b-kanban-card-due">
          <ChangeDue task={task}></ChangeDue>
        </div>
      </div>
      <div className="b-kanban-card-assignee">
        <ChangeAssignee task={task}></ChangeAssignee>
      </div>
    </div>
  );
};

export default Card;
