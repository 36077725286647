import React, { useState } from 'react';
import { useAppDispatch } from '../../../../hooks';
import { changeFilterParams } from '../../../../store/slices/projects';
import { projectsStatuses } from '../../../../utils/constants';
import Select from 'react-select';
import { ISelectOptions } from '../../../../types';
import { useTranslation } from 'react-i18next';

const ANY_PROJECT_STATUS = '';

export const ProjectFilterStatus: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const anyProjectStatus: ISelectOptions = {
    name: 'filter.anyStatus',
    value: ANY_PROJECT_STATUS,
  };
  const statusesList = [anyProjectStatus, ...projectsStatuses];
  const [selectedStatus, setSelectedStatus] = useState<ISelectOptions | null>(statusesList[1]);

  const handleProjectStatusFilter = (status: ISelectOptions | null) => {
    if (status) {
      dispatch(changeFilterParams({ status: status.value }));
    }
  };

  return (
    <div className="b-select-light">
      <Select
        options={statusesList}
        value={selectedStatus}
        getOptionLabel={(option) => t(option.name)}
        onChange={(option) => {
          setSelectedStatus(option);
          handleProjectStatusFilter(option);
        }}
        classNamePrefix="custom-select"
      />
    </div>
  );
};
