import { Column } from 'react-table';
import { projectTabHeaders, projectsStatuses } from '../../../../utils/constants';
import Translation from '../../../translation';
import { ChangeProjectDescription } from '../../../projectTab/changeProjectDescription';
import ChangePriority from '../../../changePriority';
import { useAppDispatch } from '../../../../hooks';
import {
  changeProjectPriorityAsync,
  changeProjectStatusAsync,
} from '../../../../store/slices/projects';
import ChangeStatus from '../../../changeStatus';
import cl from './style.module.scss';
import { Link } from 'react-router-dom';
import { Permissions } from '../../../../types/permissions';

export const COLUMNS: Column[] = [
  {
    Header: <Translation text={projectTabHeaders[0].name} />,
    accessor: 'id',
    Cell: (props: any) => {
      return props.row.original.id;
    },
  },
  {
    Header: <Translation text={projectTabHeaders[1].name} />,
    accessor: 'name',
    Cell: (props: any) => {
      return (
        <Link to={`/projects/${props.row.original.id}`} className={cl.projectTableColumnName}>
          {props.row.original.name}
        </Link>
      );
    },
  },
  {
    Header: <Translation text={projectTabHeaders[2].name} />,
    accessor: 'description',
    Cell: (props: any) => {
      return (
        <ChangeProjectDescription
          id={props.row.original.id}
          description={props.row.original.description}
          permission={Permissions.update_projects}
        />
      );
    },
  },
  {
    Header: <Translation text={projectTabHeaders[3].name} />,
    accessor: 'priority',
    Cell: (props: any) => {
      const dispatch = useAppDispatch();
      const changePriorityHandler = (newPriority: number) => {
        dispatch(
          changeProjectPriorityAsync({
            id: props.row.original.id,
            priority: +newPriority,
          })
        );
      };
      return (
        <ChangePriority
          priority={props.row.original.priority}
          onChangePriority={changePriorityHandler}
          permission={Permissions.update_projects}
        />
      );
    },
  },
  {
    Header: <Translation text={projectTabHeaders[4].name} />,
    accessor: 'status',
    Cell: (props: any) => {
      const dispatch = useAppDispatch();
      const changeStatusHandler = (newStatus: number) => {
        dispatch(
          changeProjectStatusAsync({
            id: props.row.original.id,
            status: +newStatus,
          })
        );
      };
      return (
        <ChangeStatus
          status={props.row.original.status}
          statuses={projectsStatuses}
          onChangeStatus={(newStatus: number) => changeStatusHandler(newStatus)}
          permission={Permissions.update_projects}
        />
      );
    },
  },
  {
    Header: <Translation text={projectTabHeaders[5].name} />,
    accessor: 'organization',
    Cell: (props: any) => {
      return props.row.original.organization ? props.row.original.organization.name : '';
    },
  },
];
