import { useEffect, useState } from 'react';
import './style.css';
import { useAppSelector } from '../../../../hooks';
import { getSumTime } from '../../../../utils/helpers';
import api from '../../../../services/api';
import { useTranslation } from 'react-i18next';
import { TimeReport } from '../../../../services/api/endpoints/type';

const TaskTimeLocal: React.FC = () => {
  const data = useAppSelector((state) => state.taskTime.data.timeReports);
  const { task } = useAppSelector((state) => state.task);
  const { filter /* sort */ } = useAppSelector((state) => state.taskTime);
  const [localeActualTime, setLocaleActualTime] = useState<string>(task?.actual_time || '');
  const { t } = useTranslation('common');

  const changeSumTime = (data: TimeReport[]) => {
    if (data.length) {
      const time = data.reduce(
        (res, item) =>
          getSumTime(res, Number(item.hours.split(':')[0]), Number(item.hours.split(':')[1])),
        ''
      );
      setLocaleActualTime(time);
    }
  };

  async function changeTime() {
    if (task?.id) {
      const time = await api.timeReports.getTaskTime(task.id, {
        filter: filter,
        pagination: null,
        // sort: sort
      });
      changeSumTime(time.data);
    }
  }

  useEffect(() => {
    changeTime();
  }, [data]);

  return (
    <div className={['totalwrapper', !data?.length ? 'totalwrapper_empty' : ''].join(' ')}>
      <div>{t('timeTask.total')}</div>
      <div>{localeActualTime}</div>
    </div>
  );
};

export default TaskTimeLocal;
