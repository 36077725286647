import { RootState } from '../../../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import { IChangePermissionParams, PermissionsState } from './types';
import { AxiosError } from 'axios';
import { IErrorResponse } from '../../../../types';
import { IAllPermissionsResponse } from '../../../../services/api/endpoints/permissions';

const initialState: PermissionsState = {
  permissions: null,
  loading: false,
};

export const fetchPermissions = createAsyncThunk<any, void, { state: RootState }>(
  'permissions/fetchPermissions',
  async function (_, { rejectWithValue }) {
    try {
      const response = await api.permissions.allPermissions();
      return response.data;
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changePermissionAsync = createAsyncThunk<
  any,
  IChangePermissionParams,
  { state: RootState }
>(
  'permissions/changePermissionAsync',
  async function ({ permission, role, newValue }, { rejectWithValue }) {
    try {
      await api.permissions.updatePermission({
        role: role,
        operation_type: permission,
        enabled: newValue,
      });

      return {
        permission,
        role,
        newValue,
      };
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        fetchPermissions.fulfilled,
        (state, action: PayloadAction<IAllPermissionsResponse>) => {
          state.permissions = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchPermissions.rejected, (state, action) => {
        alert(action.payload || 'Не удалось загрузить разрешения');
        state.loading = false;
      })
      .addCase(changePermissionAsync.pending, (state, action) => {})
      .addCase(
        changePermissionAsync.fulfilled,
        (state, action: PayloadAction<IChangePermissionParams>) => {
          if (state.permissions) {
            state.permissions[action.payload.permission][action.payload.role] =
              action.payload.newValue;
          }
        }
      )
      .addCase(changePermissionAsync.rejected, (state, action) => {
        alert(action.payload || 'Не удалось обновить разрешение');
      });
  },
});

// export const {  } = permissionsSlice.actions;

export default permissionsSlice.reducer;
