import auth from './endpoints/auth';
import task from './endpoints/task';
import project from './endpoints/project';
import user from './endpoints/user';
import milestone from './endpoints/milestone';
import timeReports from './endpoints/timeReport';
import comments from './endpoints/comments';
import discussion from './endpoints/discussion';
import files from './endpoints/files';
import activity from './endpoints/activity';
import tag from './endpoints/tag';
import permissions from './endpoints/permissions';
import company from './endpoints/company';

const api = {
  auth,
  task,
  project,
  user,
  milestone,
  timeReports,
  comments,
  discussion,
  files,
  activity,
  tag,
  permissions,
  company,
};

export default api;
