import { UserRoles } from '../../../types';
import { Permissions } from '../../../types/permissions';
import { axios } from '../axios';

export interface IListRolesToAccess {
  admin: boolean;
  pm: boolean;
  user: boolean;
  customer: boolean;
  disabled: boolean;
}

export interface IAllPermissionsResponse {
  [key: string]: IListRolesToAccess;
}

export interface IUpdatePermissionRequest {
  role: UserRoles;
  operation_type: Permissions;
  enabled: boolean;
}

const endpoints = {
  allPermissions: () => axios.get<IAllPermissionsResponse>('/acl/get-all-permissions'),
  updatePermission: (data: IUpdatePermissionRequest) => axios.put('/acl/update-permission', data),
};

export default endpoints;
