import React, { useState } from 'react';
import { useAppDispatch } from '../../hooks';
import api from '../../services/api';
import { changeAssignee } from '../../store/slices/tasks';
import { ITask } from '../../store/slices/tasks/types';
import './style.css';
import editImage from '../../assets/images/icons/edit.svg';
import UserChangePopover from '../tasksTable/userChangePopover';
import { IErrorResponse, IEntity } from '../../types';
import axios, { AxiosError } from 'axios';
import PermissionsGate from '../permissionsGate';
import { Permissions } from '../../types/permissions';

interface ChangeAssigneeProps {
  task: ITask;
}

const ChangeAssignee: React.FC<ChangeAssigneeProps> = ({ task }) => {
  const dispatch = useAppDispatch();
  const [isAssigneePopoverOpen, setIsAssigneePopoverOpen] = useState(false);
  const [userList, setUserList] = useState<IEntity[]>([]);
  const [isAssigneePreloaderVisible, setIsAssigneePreloaderVisible] = useState(false);
  const [isAssigneeSaved, setIsAssigneeSaved] = useState(true);

  const prepareUserData = async (projId: number) => {
    const response = await api.user.get({ id: projId });
    const data: IEntity[] = response.data;
    setUserList(data);
  };

  const handleRadioButtonClick = (e: IEntity) => {
    if (e.id === task.assigned_to?.id) {
      return;
    }
    let prevAssignee = task.assigned_to || { id: -1, name: '' };
    let done = false;
    let doneWaiting = false;
    dispatch(
      changeAssignee({
        id: task.id,
        user_id: e.id,
        user_name: e.name,
      })
    );
    api.task
      .editAssignee({ id: task.id, user_id: e.id })
      .then(() => {
        done = true;
        if (doneWaiting) {
          setIsAssigneeSaved(true);
        }
      })
      .catch((error: Error | AxiosError<IErrorResponse>) => {
        setIsAssigneeSaved(true);
        dispatch(
          changeAssignee({
            id: task.id,
            user_id: prevAssignee.id,
            user_name: prevAssignee.name,
          })
        );
        alert(axios.isAxiosError(error) ? error.response?.data.error_message : error);
      });
    setTimeout(() => {
      doneWaiting = true;
      if (done) {
        setIsAssigneeSaved(true);
      }
    }, 2000);
    setIsAssigneePopoverOpen(false);
    setIsAssigneeSaved(false);
  };

  return (
    <div className="b-assignee">
      <div className={isAssigneeSaved ? 'text' : 'text green-text'}>
        {task.assigned_to ? task.assigned_to.name : ''}
      </div>

      <PermissionsGate permissions={[Permissions.update_tasks]}>
        <div className="btn-edit">
          <UserChangePopover
            handler={handleRadioButtonClick}
            preloaderVisible={isAssigneePreloaderVisible}
            task={task}
            userList={userList}
            isOpen={isAssigneePopoverOpen}
            setOpen={setIsAssigneePopoverOpen}
          >
            <img
              className="assignee-image-size"
              src={editImage}
              onClick={() => {
                if (!isAssigneePopoverOpen) {
                  setIsAssigneePreloaderVisible(true);
                  prepareUserData(task.project_id).then(() => {
                    setIsAssigneePreloaderVisible(false);
                  });
                }
                setIsAssigneePopoverOpen(!isAssigneePopoverOpen);
              }}
              alt=""
            />
          </UserChangePopover>
        </div>
      </PermissionsGate>
    </div>
  );
};

export default ChangeAssignee;
