import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OutsideAlerter from '../../outsideAlerter';
import './style.css';

interface quotePopoverProps {
  linksArray?: number[];
}

export const QuotePopover: React.FC<quotePopoverProps> = ({ children, linksArray = null }) => {
  const [quotePopoverClose, setQuotePopoverClose] = useState(false);
  const [coppiedRecently, setCoppiedRecently] = useState(false);
  const { t } = useTranslation('common');

  const closeHandler = () => {
    setQuotePopoverClose(false);
  };

  const onTextClick = (linksArray: number[] | null) => {
    setCoppiedRecently(true);
    if (linksArray) {
      if (linksArray.length > 0) {
        const copytextRussian = `[b]Оценка[/b]\r\n${linksArray.reduce(
          (prev, item) => prev + `- ${window.location.origin}/tasks/${item} - 0\n`,
          ''
        )}[b]Итого: ${linksArray.length}[/b]\n\r\n[b]Комментарии[/b]\r\n1.\r\n2.\r\n\n`;
        const copytextEnglish = `[b]Quote[/b]\r\n${linksArray.reduce(
          (prev, item) => prev + `- ${window.location.origin}/tasks/${item} - 0\n`,
          ''
        )}[b]Total: ${linksArray.length}[/b]\n\r\n[b]Comments[/b]\r\n1.\r\n2.\r\n\n`;
        const copyText = copytextRussian + copytextEnglish;
        navigator.clipboard.writeText(copyText);
      } else {
        setCoppiedRecently(false);
      }
    } else {
      navigator.clipboard.writeText(window.location.origin + window.location.pathname);
    }
    setTimeout(() => setCoppiedRecently(false), 1000);
  };

  const popoverQuote = (
    <Popover className="popoverquote">
      <OutsideAlerter handler={() => closeHandler()}>
        <Popover.Body className="quotepopover-wrapper">
          <div className="quotepopover-header">
            <Button
              size="sm"
              className="quotepopover-button"
              onClick={() => onTextClick(linksArray)}
            >
              {t('detailPage.copyTaskQuote')}
            </Button>
            <p className="quotepopover-warning">
              {coppiedRecently ? `${t('popover.coppied')}` : null}
            </p>
          </div>
          <div className="quotepopover-text">
            {linksArray ? (
              <p>
                [b]Оценка[/b]
                <br />
                {linksArray.map((el) => {
                  return (
                    <p>
                      - {window.location.origin}/tasks/{el} - 0
                    </p>
                  );
                })}
                [b]Итого: {linksArray.length}[/b]
                <br />
                <br />
                [b]Комментарии[/b]
                <br />
                1.
                <br />
                2.
                <br />
                <br />
                [b]Quote[/b]
                <br />
                {linksArray.map((el) => {
                  return (
                    <p>
                      - {window.location.origin}/tasks/{el} - 0
                    </p>
                  );
                })}
                [b]Total: {linksArray.length}[/b]
                <br />
                <br />
                [b]Comments[/b]
                <br />
                1.
                <br />
                2.
                <br />
                <br />
              </p>
            ) : (
              `${window.location.origin}${window.location.pathname}`
            )}
          </div>
        </Popover.Body>
      </OutsideAlerter>
    </Popover>
  );

  return (
    <OverlayTrigger show={quotePopoverClose} placement="bottom" overlay={popoverQuote}>
      <div
        onClick={() => {
          if (linksArray) {
            if (linksArray.length > 0) {
              setQuotePopoverClose(true);
            } else {
              setQuotePopoverClose(false);
            }
          } else {
            setQuotePopoverClose(true);
          }
        }}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
};
