import { ICompanyModel } from '../../../store/slices/admin/companiesAndUsers/types';
import { IEntity, IOKResult } from '../../../types';
import { axios } from '../axios';
import { IPagination } from './type';

export interface ICompanyListRequest {
  pagination: {
    page?: number;
    count?: number;
  } | null;
  filter?: {
    name: string;
  };
}

export interface ICompanyCreateRequest {
  name: string;
  address?: string;
  phone?: string;
  url?: string;
  email?: string;
  hourly_rate?: number;
}

export interface ICompanyInfoModel {
  id: number;
  name: string;
  address1: string;
  address2: string;
  zip_code: string;
  city: string;
  country: string;
  phone: string;
  fax: string;
  url: string;
  email: string;
  hourly_rate: number;
  users: IEntity[];
  projects: IEntity[];
}

const endpoints = {
  list: (data: ICompanyListRequest) =>
    axios.get<IPagination<ICompanyModel>>('/company/list', {
      params: data,
    }),
  create: (data: ICompanyCreateRequest) =>
    axios.post<IOKResult<ICompanyInfoModel>>('/company/create', data),
  update: (id: number, data: ICompanyCreateRequest) =>
    axios.put<IOKResult<ICompanyInfoModel>>(`/company/update/${id}`, data),
  getInfoCompany: (id: number) => axios.get<ICompanyInfoModel>(`/company/${id}`),
};

export default endpoints;
