import React from 'react';
import styles from './style.module.scss';
import preloader from '../../assets/images/preloader.svg';

interface PreloaderProps {
  size?: number;
}

const Preloader: React.FC<PreloaderProps> = ({ size = 60 }) => {
  return (
    <div className={styles.preloader}>
      <img src={preloader} alt="" style={{ width: size, height: size }} />
    </div>
  );
};

export default Preloader;
