import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { DetailTabs } from '../../components/detailTabs';
import TaskPopover from '../../components/popovers/taskPopover';
import Preloader from '../../components/preloader';
import { TaskCard } from '../../components/taskCard';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteTaskAsync, fetchTaskAsync, resetTask } from '../../store/slices/task';
import { resetComments } from '../../store/slices/task/comments';
import { resetFiles } from '../../store/slices/task/files';
import { resetTimeReports } from '../../store/slices/task/timeReports';
import { ITaskDetailModel } from '../../store/slices/task/types';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

interface TaskProps {}

const Task: React.FC<TaskProps> = () => {
  const urlParams = useParams();
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { task, loading, error } = useAppSelector((state) => state.task);
  const [editCard, setEditCard] = useState<boolean>(false);

  const destroyTask = () => {
    dispatch(resetTask());
    dispatch(resetComments());
    dispatch(resetFiles());
    dispatch(resetTimeReports());
  };

  useEffect(() => {
    destroyTask();
    dispatch(fetchTaskAsync(Number(urlParams.id)));
    return () => {
      destroyTask();
    };
  }, [urlParams.id]);

  useEffect(() => {
    document.title = task ? task.name : `${t('common.task')} #${urlParams.id}`;
  }, [task]);

  const deleteTask = (task: ITaskDetailModel) => {
    if (task.has_subitems) {
      alert(t('task.haveSubtasks'));
      return;
    }
    if (window.confirm(`${t('task.delete')} ${task.type.charAt(0).toUpperCase()}-${task.id}?`)) {
      dispatch(deleteTaskAsync()).then(() => {
        navigate('/tasks/');
      });
    }
  };

  if (error)
    return (
      <div className={styles.error}>
        <div className={styles.errorMessage}>{error}</div>
        <Link to="/tasks">{t('common.backToHomepage')}</Link>
      </div>
    );

  return (
    <>
      {loading.task || !task ? (
        <Preloader />
      ) : (
        <div>
          <ul className={styles.breadcrumbs}>
            <li>
              <Link to="/projects">{t('common.projects')}</Link>
            </li>
            <li>
              <Link to={`/projects/${task.project.id}`}>{task.project.name}</Link>
            </li>
            <li>{task.name}</li>
          </ul>

          <div className={styles.taskDetail}>
            {editCard ? (
              <div className={styles.taskForm}>
                <TaskPopover
                  defaultProject={task.project}
                  task={task}
                  onClose={() => setEditCard((value) => !value)}
                  fromDetail={true}
                />
              </div>
            ) : (
              <div className={styles.taskForm}>
                <TaskCard onEdit={() => setEditCard((value) => !value)} onDelete={deleteTask} />
              </div>
            )}

            <div className={styles.detailTaskTabs}>
              <DetailTabs />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Task;
