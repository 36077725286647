import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../..';
import {
  IDeletedComment,
  IDiscussionData,
  IEditCommentParams,
  INewCommentParams,
  ISubscribersAndComments,
  TaskFilesState,
} from './types';
import { IErrorResponse } from '../../../../types';
import api from '../../../../services/api';
import { IComment, ICommentModel, IUpdateSubscribers } from '../types';
import { startDiscussion } from '..';
import { IChangeSubscriber } from '../../../../services/api/endpoints/task';

const initialState: TaskFilesState = {
  teamComments: {
    subscribers: [],
    comments: [],
    loading: false,
  },
  customerComments: {
    subscribers: [],
    comments: [],
    loading: false,
  },
};

export const fetchTeamComments = createAsyncThunk<any, void, { state: RootState }>(
  'taskComments/fetchTeamComments',
  async function (_, { rejectWithValue, getState }) {
    try {
      const state = getState();
      if (!state.task.task) return [];

      const taskId = state.task.task.id;
      const subscribers = await api.task.getSubscribers(taskId, 1);

      let comments: ICommentModel[] = [];
      if (state.task.task.has_discussion_team) {
        const response = await api.comments.getComments(taskId, '1');
        comments = response.data;
      }

      return {
        subscribers: subscribers.data.subscribers,
        comments: comments,
      };
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const fetchCustomerComments = createAsyncThunk<any, void, { state: RootState }>(
  'taskComments/fetchCustomerComments',
  async function (_, { rejectWithValue, getState }) {
    try {
      const state = getState();
      if (!state.task.task) return [];

      const taskId = state.task.task.id;
      const subscribers = await api.task.getSubscribers(taskId, 0);

      let comments: ICommentModel[] = [];
      if (state.task.task.has_discussion) {
        const response = await api.comments.getComments(taskId, '0');
        comments = response.data;
      }

      return {
        subscribers: subscribers.data.subscribers,
        comments,
      };
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const deleteCommentAsync = createAsyncThunk<any, IDeletedComment, { state: RootState }>(
  'taskComments/deleteComment',
  async function (comment: IDeletedComment, { rejectWithValue, dispatch }) {
    try {
      const response = await api.comments.deleteComment(comment.commentId);
      // dispatch(deleteComment(comment));
      if (comment.published === '1') {
        dispatch(restoreTeamComment(comment.commentId));
      } else {
        dispatch(restoreCustomerComment(comment.commentId));
      }
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const startDiscussionAsync = createAsyncThunk<any, IDiscussionData, { state: RootState }>(
  'taskComments/startDiscussion',
  async function (data: IDiscussionData, { rejectWithValue, getState, dispatch }) {
    try {
      await api.discussion.startDiscussion(data.taskId, data.published);
      dispatch(startDiscussion(data.published));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const addCommentAsync = createAsyncThunk<any, INewCommentParams, { state: RootState }>(
  'taskComments/addComment',
  async function (params: INewCommentParams, { rejectWithValue, getState, dispatch }) {
    try {
      const response = await api.comments.addComment(params.data, params.taskId, params.published);
      if (params.published === '1') {
        dispatch(addTeamComment(response.data.model));
      }
      if (params.published === '0') {
        dispatch(addCustomerComment(response.data.model));
      }
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const editCommentAsync = createAsyncThunk<any, IEditCommentParams, { state: RootState }>(
  'taskComments/editComment',
  async function (params: IEditCommentParams, { rejectWithValue, getState, dispatch }) {
    try {
      const response = await api.comments.editComment(params.data, params.commentId);
      if (params.published === '1') {
        dispatch(editTeamComment(response.data.model));
      }
      if (params.published === '0') {
        dispatch(editCustomerComment(response.data.model));
      }
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changeSubscriberAsync = createAsyncThunk<any, IChangeSubscriber, { state: RootState }>(
  'taskComments/changeSubscriber',
  async function (params: IChangeSubscriber, { rejectWithValue, getState, dispatch }) {
    try {
      await api.task.changeStatusUser(params);
      dispatch(
        changeSubscriberStatus({
          published: params.published,
          userId: params.user_id,
        })
      );
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const taskCommentsSlice = createSlice({
  name: 'taskComments',
  initialState,
  reducers: {
    addTeamComment(state, action: PayloadAction<IComment>) {
      state.teamComments.comments = [action.payload, ...state.teamComments.comments];
    },
    addCustomerComment(state, action: PayloadAction<IComment>) {
      state.customerComments.comments = [action.payload, ...state.customerComments.comments];
    },
    restoreCustomerComment(state, action: PayloadAction<number>) {
      const comment = state.customerComments.comments.find((item) => item.id === action.payload);
      if (comment) {
        comment.deleted = !comment.deleted;
      }
    },
    restoreTeamComment(state, action: PayloadAction<number>) {
      const comment = state.teamComments.comments.find((item) => item.id === action.payload);
      if (comment) {
        comment.deleted = !comment.deleted;
      }
    },
    editTeamComment(state, action: PayloadAction<ICommentModel>) {
      const comment = state.teamComments.comments.find((item) => item.id === action.payload.id);
      if (comment) {
        comment.message = action.payload.message;
        comment.show_on_top = action.payload.show_on_top;
        comment.files = action.payload.files;
      }
    },
    editCustomerComment(state, action: PayloadAction<ICommentModel>) {
      const comment = state.customerComments.comments.find((item) => item.id === action.payload.id);
      if (comment) {
        comment.message = action.payload.message;
        comment.show_on_top = action.payload.show_on_top;
        comment.files = action.payload.files;
      }
    },
    deleteComment(state, action: PayloadAction<IDeletedComment>) {
      if (action.payload.teamComment) {
        state.teamComments.comments = state.teamComments.comments.filter(
          (item) => item.id !== action.payload.commentId
        );
      } else {
        state.customerComments.comments = state.customerComments.comments.filter(
          (item) => item.id !== action.payload.commentId
        );
      }
    },
    changeSubscriberStatus(state, action: PayloadAction<IUpdateSubscribers>) {
      if (action.payload.published === '1') {
        const subscriber = state.teamComments.subscribers.find(
          (item) => item.user.id === action.payload.userId
        );
        if (subscriber) {
          subscriber.status = subscriber.status === 1 ? 0 : 1;
        }
      } else {
        const subscriber = state.customerComments.subscribers.find(
          (item) => item.user.id === action.payload.userId
        );
        if (subscriber) {
          subscriber.status = subscriber.status === 1 ? 0 : 1;
        }
      }
    },
    resetComments() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamComments.pending, (state, action) => {
        state.teamComments.loading = true;
      })
      .addCase(
        fetchTeamComments.fulfilled,
        (state, action: PayloadAction<ISubscribersAndComments>) => {
          state.teamComments.subscribers = action.payload.subscribers;
          state.teamComments.comments = action.payload.comments;
          state.teamComments.loading = false;
        }
      )
      .addCase(fetchTeamComments.rejected, (state, action) => {
        state.teamComments.loading = false;
      })
      .addCase(fetchCustomerComments.pending, (state, action) => {
        state.customerComments.loading = true;
      })
      .addCase(
        fetchCustomerComments.fulfilled,
        (state, action: PayloadAction<ISubscribersAndComments>) => {
          state.customerComments.subscribers = action.payload.subscribers;
          state.customerComments.comments = action.payload.comments;
          state.customerComments.loading = false;
        }
      )
      .addCase(fetchCustomerComments.rejected, (state, action) => {
        state.customerComments.loading = false;
      })
      .addCase(deleteCommentAsync.rejected, (state, action) => {
        alert(action.payload || 'Не удалось удалить пост');
      })
      .addCase(startDiscussionAsync.rejected, (state, action) => {
        alert(action.payload || 'Не удалось создать дискуссию');
      })
      .addCase(changeSubscriberAsync.rejected, (state, action) => {
        alert(action.payload || 'Не удалось изменить статус подписчика');
      });
  },
});

export const {
  addTeamComment,
  addCustomerComment,
  editTeamComment,
  editCustomerComment,
  deleteComment,
  changeSubscriberStatus,
  restoreTeamComment,
  restoreCustomerComment,
  resetComments,
} = taskCommentsSlice.actions;

export default taskCommentsSlice.reducer;
