import React, { useEffect, useState } from 'react';
import './style.scss';
import '../../../../components/filter/style.css';
import userImage from '../../../../assets/images/icons/user-time.svg';
import addImage from '../../../../assets/images/icons/add-time.svg';
import calendarImage from '../../../../assets/images/icons/calendar-time.svg';
import deleteImage from '../../../../assets/images/icons/delete-time.svg';
import editImage from '../../../../assets/images/icons/edit-time.svg';
import { TimeEntry } from '../../../tasksTable/timeEntry/timeEntry';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { changeActualTime, fetchTaskAsync } from '../../../../store/slices/task';
import api from '../../../../services/api';
import { DateRange, ISelectOptions } from '../../../../types';
import { getSumTime, useGetCheckedIds } from '../../../../utils/helpers';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../modal';
import ChangeTimeTask from '../TaskTimeEdition';
import {
  addWorkedTime,
  changeModalEdit,
  changeModalParams,
  changeTaskFilterParam,
} from '../../../../store/slices/task/timeReports';
import PermissionsGate from '../../../permissionsGate';
import { Permissions } from '../../../../types/permissions';
import { getValidSelectOptions } from '../../../../utils/helpers/selects';
import { TimeReport } from '../../../../services/api/endpoints/type';

interface TimeTabFilterProps {}

const ALL_USER_VALUE = 0;

export const TaskTimeHeader: React.FC<TimeTabFilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { task } = useAppSelector((state) => state.task);
  const { modalEdit, checkedId, data, filter } = useAppSelector((state) => state.taskTime);
  const { loading } = useAppSelector((state) => state.users);

  const allUser: ISelectOptions = {
    name: 'timeTask.labelFilterUser',
    value: ALL_USER_VALUE,
  };
  const usersForTask = task?.users.length ? task.users : [];
  const userList = [allUser, ...getValidSelectOptions(usersForTask)];
  const [dateRange, setDateRange] = useState<DateRange>([
    filter.date ? new Date(filter.date) : null,
    filter.date_until ? new Date(filter.date_until) : null,
  ]);
  const [startDate, endDate] = dateRange;
  const [currentUser, setCurrentUser] = useState<ISelectOptions | null>(allUser);
  const defaultDate = new Date();
  const hasCheckboxChecked = data.timeReports.find((el) => el.checked === true)?.checked;
  const checkedIds = useGetCheckedIds();

  const onSaveTimeReport = (newTime: TimeReport) => {
    if (task) {
      const [hours, minutes] = newTime.hours.split(':');
      const actualTime = getSumTime(task.actual_time, +hours, +minutes);
      dispatch(changeActualTime(actualTime));
    }

    dispatch(addWorkedTime(newTime));
  };

  const handleDatePicker = (rangeDate: DateRange) => {
    if (rangeDate[0]) {
      if (rangeDate[1]) {
        let [dateStart, dateEnd] = rangeDate;
        dateStart.setDate(dateStart.getDate());
        dateEnd.setDate(dateEnd.getDate());
        dispatch(
          changeTaskFilterParam({
            date_enum: 0,
            date: dayjs(dateStart).format('YYYY-MM-DD'),
            date_until: dayjs(dateEnd).format('YYYY-MM-DD'),
          })
        );
      }
    } else {
      dispatch(changeTaskFilterParam({ date_enum: 0, date: '', date_until: '' }));
    }
  };

  const handleUserFilterChange = (option: ISelectOptions | null) => {
    if (option) {
      dispatch(changeTaskFilterParam({ user: option.value }));
    }
  };

  const deleteWorkedTime = async () => {
    if (checkedIds?.length && task?.id) {
      if (window.confirm(`${t('timeTask.delete')}: ${checkedIds}`)) {
        await api.timeReports.deleteWorkedTime(checkedIds);
        dispatch(fetchTaskAsync(task?.id));
      }
    }
  };

  const btnEditHandler = () => {
    if (hasCheckboxChecked) {
      const currEl = data.timeReports.find((el) => checkedIds?.includes(el.id));
      let currHrs = '0';
      let currMins = '0';
      let currTime = '0:00';
      let currComment = '';
      if (currEl) {
        currHrs = currEl.hours.split(':')[0];
        currMins = currEl.hours.split(':')[1];
        currTime = currEl.hours;
        currComment = currEl.comments;
      }
      dispatch(
        changeModalParams({
          startDate: defaultDate,
          data: data.timeReports,
          hrs: currHrs,
          mins: currMins,
          time: currTime,
          comment: currComment,
        })
      );
      dispatch(changeModalEdit(true));
    }
  };

  useEffect(() => {
    if (checkedId) {
      const currEl = data.timeReports.find((el) => el.id === checkedId);
      let currHrs = '0';
      let currMins = '0';
      let currTime = '0:00';
      let currComment = '';
      if (currEl) {
        currHrs = currEl.hours.split(':')[0];
        currMins = currEl.hours.split(':')[1];
        currTime = currEl.hours;
        currComment = currEl.comments;
      }
      dispatch(
        changeModalParams({
          startDate: defaultDate,
          data: data.timeReports,
          hrs: currHrs,
          mins: currMins,
          time: currTime,
          comment: currComment,
        })
      );
      dispatch(changeModalEdit(true));
    }
  }, [data.timeReports, checkedId]);

  return (
    <div className="greycorner">
      <div className="filter wrapper">
        <div className="editbuttons">
          {task && (
            <PermissionsGate permissions={[Permissions.add_time]}>
              <div className="filter add">
                <TimeEntry taskId={task.id} onSave={onSaveTimeReport}>
                  <img src={addImage} alt="" />
                </TimeEntry>
              </div>
            </PermissionsGate>
          )}

          <PermissionsGate permissions={[Permissions.update_time]}>
            <img
              onClick={btnEditHandler}
              className={['filter edit', hasCheckboxChecked && 'edit_active'].join(' ')}
              src={editImage}
              alt=""
            />
          </PermissionsGate>
          {modalEdit && (
            <Modal title={t('timeTask.labelTimeEntry')}>
              <ChangeTimeTask />
            </Modal>
          )}

          <PermissionsGate permissions={[Permissions.delete_time]}>
            <img
              onClick={deleteWorkedTime}
              className={['filter delete', hasCheckboxChecked && 'delete_active'].join(' ')}
              src={deleteImage}
              alt=""
            />
          </PermissionsGate>
        </div>
        <div className="filterbuttons">
          <div className="b-filter-field b-select-light">
            <img src={userImage} alt="" className="filter user" />
            <Select
              options={userList}
              value={currentUser}
              getOptionLabel={(option) =>
                option.value === ALL_USER_VALUE ? t(option.name) : option.name
              }
              onChange={(option) => {
                setCurrentUser(option);
                handleUserFilterChange(option);
              }}
              classNamePrefix="custom-select"
              isLoading={loading}
            />
          </div>
          <div className="b-filter-field date-picker-size">
            <img src={calendarImage} alt="" className="filter calendaricon" />
            <DatePicker
              className="dropdown-box"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText={t('datePicker.placeholder')}
              onChange={(dates: DateRange) => {
                setDateRange(dates);
                handleDatePicker(dates);
              }}
              isClearable={true}
              calendarStartDay={1}
              dateFormat="dd.MM.yyyy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
