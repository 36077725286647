import { Toggle } from '../toggle';
import PermissionsGate from '../permissionsGate';
import { Permissions } from '../../types/permissions';
import Avatar from '../avatar';
import { Menu, MenuItem } from 'react-pro-sidebar';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router';
import styles from './style.module.scss';
import api from '../../services/api';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useEffect } from 'react';
import { fetchUserInfo } from '../../store/slices/currentUser';
import { fetchUsersAsync } from '../../store/slices/users';
import arrowLeft from '../../assets/images/icons/arrow-left.svg';
import arrowRight from '../../assets/images/icons/arrow-right.svg';
import tasks from '../../assets/images/icons/tasks.svg';
import blocks from '../../assets/images/icons/blocks.svg';
import clock from '../../assets/images/icons/clock.svg';
import settings from '../../assets/images/icons/settings.svg';
import tasksBlue from '../../assets/images/icons/tasks-blue.svg';
import blocksBlue from '../../assets/images/icons/blocks-blue.svg';
import clockBlue from '../../assets/images/icons/clock-blue.svg';
import settingsBlue from '../../assets/images/icons/settings-blue.svg';

interface MenuSidebarProps {
  lang: string;
  languages: string[];
  collapsed: boolean;
  setLang: (lang: string) => void;
  setCollapsed: (collapsed: boolean) => void;
}

const MenuSidebar: React.FC<MenuSidebarProps> = ({
  languages,
  lang,
  setLang,
  collapsed,
  setCollapsed,
}) => {
  const { t, i18n } = useTranslation('common');
  const { name: username } = useAppSelector((state) => state.currentUser);
  const { removeToken } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changeLanguage = (activeField: string) => {
    const newLang = activeField === 'En' ? 'en' : 'ru';
    i18n.changeLanguage(newLang);
    localStorage.setItem('lang', newLang);
    setLang(activeField);
  };

  const LogoutHandler = async () => {
    await api.auth
      .logout()
      .catch((error) => {
        if (error && error.status === 400) {
          console.log(error);
        }
      })
      .finally(() => {
        removeToken();
        navigate('/login');
      });
  };

  useEffect(() => {
    const defaultLang =
      localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'en' ? 'En' : 'Ру';
    setLang(defaultLang);

    dispatch(fetchUserInfo());
    dispatch(fetchUsersAsync());
  }, []);

  return (
    <Menu className={clsx(styles.menuSidebar, collapsed && styles.menuSidebarCollapsed)}>
      <div className={styles.top}>
        {!collapsed && (
          <div className={styles.langButton}>
            <Toggle items={languages} activeItem={lang} handler={changeLanguage} />
          </div>
        )}
        <div onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? (
            <img src={arrowRight} alt="arrow-right" className={styles.arrow} />
          ) : (
            <img src={arrowLeft} alt="arrow-left" className={styles.arrow} />
          )}
        </div>
      </div>

      <div className={styles.blockAvatar}>
        <Avatar width={collapsed ? 35 : 60} height={collapsed ? 35 : 60} />

        {!collapsed && (
          <div className={styles.blockPersonInfo}>
            <p>{username}</p>
            <Button className={styles.logOut} onClick={LogoutHandler} variant="primary">
              {t('header.logOut')}
            </Button>
          </div>
        )}
      </div>

      <div className={styles.menuItems}>
        <NavLink to="/tasks">
          {({ isActive }) => (
            <MenuItem
              component="div"
              icon={
                <img src={isActive ? tasksBlue : tasks} className={styles.menuIcon} alt="tasks" />
              }
            >
              {!collapsed && t('header.menu.tasks')}
            </MenuItem>
          )}
        </NavLink>
        <NavLink to="/projects">
          {({ isActive }) => (
            <MenuItem
              component="div"
              icon={
                <img
                  src={isActive ? blocksBlue : blocks}
                  className={styles.menuIcon}
                  alt="projects"
                />
              }
            >
              {!collapsed && t('header.menu.projects')}
            </MenuItem>
          )}
        </NavLink>
        <PermissionsGate permissions={[Permissions.view_time_report]}>
          <NavLink to="/time">
            {({ isActive }) => (
              <MenuItem
                component="div"
                icon={
                  <img src={isActive ? clockBlue : clock} className={styles.menuIcon} alt="time" />
                }
              >
                {!collapsed && t('header.menu.timeReport')}
              </MenuItem>
            )}
          </NavLink>
        </PermissionsGate>
        <PermissionsGate permissions={[Permissions.view_permissions]}>
          <NavLink to="/admin">
            {({ isActive }) => (
              <MenuItem
                component="div"
                icon={
                  <img
                    src={isActive ? settingsBlue : settings}
                    className={styles.menuIcon}
                    alt="admin"
                  />
                }
              >
                {!collapsed && t('header.menu.admin')}
              </MenuItem>
            )}
          </NavLink>
        </PermissionsGate>
      </div>
    </Menu>
  );
};

export default MenuSidebar;
