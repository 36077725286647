import React, { useEffect, useState } from 'react';
import calendarImage from '../../../assets/images/icons/calendar.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFilterParam } from '../../../store/slices/tasks';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { due } from '../../../utils/constants';
import { DateRange, ISelectOptions } from '../../../types';
import dayjs from 'dayjs';
import Select from 'react-select';
import styles from './styles.module.scss';

const ANY_DUE_DATE = '';

interface FilterProps {}

const DueFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { filter } = useAppSelector((state) => state.tasks);

  const anyDueDate: ISelectOptions = {
    name: 'due.anyDueDate',
    value: ANY_DUE_DATE,
  };
  const dueList: ISelectOptions[] = [anyDueDate, ...due];

  const [currentDue, setCurrentDue] = useState<ISelectOptions | null>(anyDueDate);
  const [isShowDateDue, setShowDateDue] = useState(false);
  const [dateRangeDue, setDateRangeDue] = useState<DateRange>([
    filter.due_date ? new Date(filter.due_date) : null,
    filter.due_date_until ? new Date(filter.due_date_until) : null,
  ]);
  const [startDateDue, endDateDue] = dateRangeDue;

  const handleDueFilterChange = (option: ISelectOptions | null) => {
    if (option) {
      if (option.value === '0') {
        setShowDateDue(true);
      } else {
        setShowDateDue(false);
        dispatch(changeFilterParam({ due_enum: option.value, due_date: '', due_date_until: '' }));
      }
    }
  };

  const handleDueDatePicker = (rangeDate: DateRange) => {
    // TODO: пересмотреть логику
    if (rangeDate[0] && rangeDate[1]) {
      let [dateStart, dateEnd] = rangeDate;
      dateStart.setDate(dateStart.getDate());
      dateEnd.setDate(dateEnd.getDate());
      dispatch(
        changeFilterParam({
          due_enum: '0',
          due_date: dayjs(dateStart).format('YYYY-MM-DD'),
          due_date_until: dayjs(dateEnd).format('YYYY-MM-DD'),
        })
      );
    } else {
      dispatch(changeFilterParam({ due_enum: '', due_date: '', due_date_until: '' }));
    }
  };

  useEffect(() => {
    if (filter.due_enum) {
      const dueCheck = dueList.find((item) => item.value === filter.due_enum);
      if (dueCheck) {
        setCurrentDue(dueCheck);
      }
    }
    if (filter.due_enum === '0') {
      setShowDateDue(true);
    }
  }, []);

  return (
    <>
      <div className="b-select-light">
        <Select
          className={styles.select}
          options={dueList}
          value={currentDue}
          getOptionLabel={(option) => t(option.name)}
          onChange={(option) => {
            setCurrentDue(option);
            handleDueFilterChange(option);
          }}
          classNamePrefix="custom-select"
          isSearchable={false}
        />
      </div>
      {isShowDateDue ? (
        <div className="b-filter-field date-picker-size">
          <DatePicker
            className="dropdown-box"
            selectsRange={true}
            startDate={startDateDue}
            endDate={endDateDue}
            placeholderText={t('datePicker.placeholder')}
            onChange={(dates: DateRange) => {
              setDateRangeDue(dates);
              handleDueDatePicker(dates);
            }}
            isClearable={true}
            calendarStartDay={1}
            dateFormat="dd.MM.yyyy"
          />
          {!isShowDateDue ? (
            <div className="calendar">
              <img src={calendarImage} alt="" />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default DueFilter;
