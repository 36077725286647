import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styles from './style.module.scss';

interface ToggleProps {
  items: string[];
  activeItem: string;
  handler: (activeItem: string) => void;
  translationPrefix?: string;
}

export const Toggle: React.FC<ToggleProps> = ({
  items,
  activeItem,
  handler,
  translationPrefix = '',
}) => {
  const { t } = useTranslation('common');
  const uuid = uuidv4();

  return (
    <ToggleButtonGroup
      className={styles.toggle}
      type="radio"
      name={`toggle-${uuid}`}
      value={activeItem}
      onChange={(value) => handler(value)}
    >
      {items.map((item, index) => (
        <ToggleButton
          key={index}
          id={`${uuid}-${index}`}
          type="radio"
          name="radio"
          size="sm"
          variant={activeItem === item ? 'primary' : 'outline-primary'}
          value={item}
        >
          {translationPrefix ? t(`${translationPrefix}${item}`) : item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
