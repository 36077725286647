import React from 'react';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  toggleCheckedAllProjectTasks,
  toggleProjectOpen,
  toggleCheckedAllProjectSprints,
} from '../../store/slices/tasks';
import { IProject } from '../../store/slices/tasks/types';
import './style.css';
import arrowUpImage from '../../assets/images/icons/arrow-up.svg';
import arrowDownImage from '../../assets/images/icons/arrow-down.svg';

interface ProjectRowProps {
  project: IProject;
}

const ProjectRow: React.FC<ProjectRowProps> = ({ project }) => {
  const dispatch = useAppDispatch();
  const { groupBy } = useAppSelector((state) => state.tasks);
  return (
    <tr className={`tr project ${project.isChecked ? 'checked' : ''}`}>
      <td className="td" style={{ flexGrow: 0, flexBasis: '70px' }}>
        <Form.Check
          className="cell-checkbox"
          aria-label={`checkbox-${project.id}`}
          checked={project.isChecked}
          onChange={(e) => {
            dispatch(
              toggleCheckedAllProjectTasks({
                id: project.id,
                value: e.target.checked,
              })
            );
            if (groupBy.sprint) {
              dispatch(
                toggleCheckedAllProjectSprints({
                  id: project.id,
                  value: e.target.checked,
                })
              );
            }
          }}
        />
      </td>
      <td className="td">
        <div
          className="btn-open btn-row"
          onClick={() => {
            dispatch(toggleProjectOpen({ id: project.id }));
          }}
        >
          <img src={project.isMinimised ? arrowDownImage : arrowUpImage} alt="" />
        </div>
        {project.name}
      </td>
    </tr>
  );
};

export default ProjectRow;
