import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import CsvDownloader from 'react-csv-downloader';
import { TimeReport } from '../../../services/api/endpoints/type';
import { TimeReportDueFilter } from './dueFilter';
import { ProjectFilterTimeReports } from './projectFilter';
import { TimeReportsUserFilter } from './userFilter';
import filterImage from '../../../assets/images/icons/filter.svg';
import { Button, Form } from 'react-bootstrap';
import './style.css';
import downloadArrow from '../../../assets/images/icons/download.svg';
import { changeGroupByDay } from '../../../store/slices/timeReportsTab';

export const TimeReportFilters = () => {
  const { data, groupByDay } = useAppSelector((state) => state.timeReportsTab);
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const csv_Headers = [
    { displayName: t('timeEntry.date'), id: 'Date' },
    { displayName: t('filter.created'), id: 'Created' },
    { displayName: t('common.user'), id: 'Author' },
    { displayName: t('common.item'), id: 'Item' },
    { displayName: t('timeEntry.comment'), id: 'Comment' },
    { displayName: t('common.hours'), id: 'Hours' },
  ];
  type arrayType = {
    timeReports: TimeReport[];
  };
  const makeExportData = (array: arrayType) => {
    return array.timeReports.map((item) => {
      return {
        Date: item.date,
        Created: item.created,
        Author: item.author.name,
        Item: item.task.name,
        Comment: item.comments,
        Hours: item.hours,
      };
    });
  };
  return (
    <div className="b-timereport-filters">
      <div className="b-export-csv">
        <CsvDownloader
          datas={makeExportData(data)}
          columns={csv_Headers}
          filename="CSV_EXPORT"
          separator=";"
        >
          <Button className="download-btn">
            <span className="text">{t('timeReport.exportCSV')}</span>
            <img src={downloadArrow} className="icon" alt="" />
          </Button>
        </CsvDownloader>
      </div>
      <div className="b-toggle">
        <Form.Check
          label={t('timeReport.groupByDay')}
          id={`groupByDay`}
          className="cell-checkbox"
          checked={groupByDay}
          onChange={(e) => {
            dispatch(changeGroupByDay({ value: !e.target.checked }));
          }}
        />
      </div>
      <div className="filter-popups">
        <div className="filter-icon">
          <img src={filterImage} alt="" />
        </div>
        <ProjectFilterTimeReports />
        <TimeReportsUserFilter />
        <TimeReportDueFilter />
      </div>
    </div>
  );
};
