import React, { useState, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  toggleStateCheckboxTask,
  changeTimeEntry,
  toggleTaskChildrenCheckbox,
  setAllChildrenTaskCheckboxes,
  toggleProjectChecked,
  toggleSprintChecked,
  toggleTaskOpen,
  changeTaskPriorityAsync,
  changeTaskStatusAsync,
} from '../../store/slices/tasks';
import arrowUpImage from '../../assets/images/icons/arrow-up.svg';
import arrowDownImage from '../../assets/images/icons/arrow-down.svg';
import timeImage from '../../assets/images/icons/plus.svg';
import './style.css';
import { IProject, ITask } from '../../store/slices/tasks/types';
import { TimeEntry } from './timeEntry/timeEntry';
import { DetailTabsEnum, statuses } from '../../utils/constants';
import ChangeDue from '../changeDue';
import ChangeAssignee from '../changeAssignee';
import { Link } from 'react-router-dom';
import ChangeStatus from '../changeStatus';
import { Permissions } from '../../types/permissions';
import PermissionsGate from '../permissionsGate';
import clsx from 'clsx';
import ChangePriority from '../changePriority';
import { TimeReport } from '../../services/api/endpoints/type';
import { getSumTime } from '../../utils/helpers';

interface TasksTableProps {
  task: ITask;
  depth?: number;
}

const TaskRow: React.FC<TasksTableProps> = ({ task, depth = 1 }) => {
  const dispatch = useAppDispatch();
  const { groupBy, data } = useAppSelector((state) => state.tasks);
  const { projects, sprints, tasks } = data;

  const [isTimeEntryGreen, setTimeEntryGreen] = useState(false);

  const onSaveTimeReport = (newTime: TimeReport) => {
    const [hours, minutes] = newTime.hours.split(':');
    const actualTime = getSumTime(task.actual_time, +hours, +minutes);

    dispatch(changeTimeEntry({ id: newTime.task.id, actual_time: actualTime }));
  };

  const toggleProjectCheckBox = (checked: boolean) => {
    const parentProject = projects.find((project: IProject) => project.id === task.project_id);
    if (parentProject) {
      let allChecked = true;
      tasks
        .filter((t: ITask) => t.project_id === task.project_id)
        .forEach((t) => {
          if (task.id === t.id) {
            if (!checked) {
              allChecked = false;
              return;
            }
          } else if (!t.isChecked) {
            allChecked = false;
          }
        });
      dispatch(toggleProjectChecked({ id: task.project_id, value: allChecked }));
    }
  };

  const changePriorityHandler = (priority: number) => {
    dispatch(
      changeTaskPriorityAsync({
        priority,
        id: task.id,
      })
    );
  };

  const changeStatusHandler = (status: number) => {
    dispatch(
      changeTaskStatusAsync({
        status,
        id: task.id,
      })
    );
  };

  return (
    <>
      <tr className={clsx(`tr row-depth-${depth} ${task.isChecked && 'checked'}`)}>
        <td className={clsx('td', 'td-checkboxes')}>
          <Form.Check
            className={clsx('cell-checkbox', `task-priority-${task.priority}`)}
            aria-label={`checkbox-${task.id}`}
            checked={task.isChecked}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              dispatch(toggleStateCheckboxTask({ id: task.id }));

              if (groupBy.sprint) {
                let sprint;
                if (task.sprint_id === null) {
                  sprint = sprints.find((s) => s.project_id === task.project_id && s.isUnsorted);
                } else {
                  sprint = sprints.find((s) => s.id === task.sprint_id);
                }
                if (sprint) {
                  if (!e.target.checked) {
                    dispatch(toggleSprintChecked({ id: sprint.id, value: false }));
                  }
                  let allChecked = true;
                  tasks
                    .filter(
                      (t) =>
                        t.sprint_id === task.sprint_id &&
                        t.project_id === task.project_id &&
                        !t.has_subitems
                    )
                    .forEach((t) => {
                      if (t.id === task.id) {
                        if (!e.target.checked) {
                          allChecked = false;
                        }
                      } else {
                        if (!t.isChecked) {
                          allChecked = false;
                        }
                      }
                    });
                  if (allChecked) {
                    dispatch(toggleSprintChecked({ id: sprint.id, value: true }));
                  }
                }
              }

              toggleProjectCheckBox(e.target.checked);

              if (depth > 1) {
                const parentTask = tasks.find((item: ITask) => item.id === task.parent_task);
                if (parentTask) {
                  let allChecked = true;
                  tasks
                    .filter((item) => item.parent_task === parentTask.id)
                    .forEach((t) => {
                      if (t.id === task.id) {
                        if (!e.target.checked) {
                          allChecked = false;
                          return;
                        }
                      } else if (!t.isChecked) {
                        allChecked = false;
                      }
                    });
                  if (
                    (allChecked && !parentTask.childrenAreChecked) ||
                    (!allChecked && parentTask.childrenAreChecked)
                  ) {
                    dispatch(toggleTaskChildrenCheckbox({ id: parentTask.id }));
                  }
                }
              }
            }}
          />
          {task.has_subitems && !task.isMinimised && (
            <Form.Check
              className="cell-checkbox"
              aria-label={`checkbox-children-${task.id}`}
              checked={task.childrenAreChecked}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                toggleProjectCheckBox(e.target.checked);
                dispatch(toggleTaskChildrenCheckbox({ id: task.id }));
                dispatch(setAllChildrenTaskCheckboxes({ id: task.id, data: e.target.checked }));
              }}
            />
          )}
          {task.priority && task.priority > 0 ? (
            <ChangePriority
              onChangePriority={changePriorityHandler}
              priority={task.priority}
              permission={Permissions.update_tasks}
              showPriority={false}
            />
          ) : (
            ''
          )}
        </td>
        <td className="td td-status">
          <ChangeStatus
            onChangeStatus={changeStatusHandler}
            statuses={statuses}
            status={task.status}
            permission={Permissions.update_tasks}
          />
        </td>
        <td className="td td-id font-medium">
          {task.type.charAt(0).toUpperCase() + '-' + task.id}
        </td>
        <td className="td td-name font-medium">
          <div className="td-name-cont text">
            {task.has_subitems ? (
              <div
                className="btn-open"
                onClick={() => {
                  dispatch(toggleTaskOpen({ id: task.id, value: !task.isMinimised }));
                }}
              >
                <img src={task.isMinimised ? arrowDownImage : arrowUpImage} alt="" />
              </div>
            ) : (
              ''
            )}
            <Link to={`/tasks/${task.id}`} className="text">
              {task.name}
            </Link>
          </div>
        </td>
        <td className="td td-tag">
          <div className="b-tags">
            {task.tags.slice(0, 2).map((tag) => {
              return (
                <div className="tag" key={tag.id}>
                  {tag.text}
                </div>
              );
            })}
          </div>
          {task.tags.length > 2 ? <div className="rest-tags">+{task.tags.length - 2}</div> : ''}
        </td>
        <td className="td td-message-count-team">
          <Link to={`/tasks/${task.id}#${DetailTabsEnum.commentTeam}`} className="text">
            {task.msg_count_team}
          </Link>
        </td>
        <td className="td td-message-count-customer">
          <Link to={`/tasks/${task.id}#${DetailTabsEnum.commentCustomer}`} className="text">
            {task.msg_count_customer}
          </Link>
        </td>
        <td className="td td-files-count">
          <Link to={`/tasks/${task.id}#${DetailTabsEnum.files}`} className="text">
            {task.files_count ? task.files_count : ''}
          </Link>
        </td>
        <td className="td td-assigned-to font-medium">
          <ChangeAssignee task={task}></ChangeAssignee>
        </td>
        <td className="td td-due-date font-light">
          <ChangeDue task={task}></ChangeDue>
        </td>
        <td className="td td-time font-light">
          <div className="text">{task.estimated_time}</div>
          <div className="actual">
            <Link
              to={`/tasks/${task.id}#time`}
              className="text"
              style={{ color: isTimeEntryGreen ? '#8EECC4' : '' }}
            >
              {task.actual_time}
            </Link>
            <PermissionsGate permissions={[Permissions.add_time]}>
              <TimeEntry taskId={task.id} onSave={onSaveTimeReport}>
                <div className="actual-time-image">
                  <img className="actual-time-image-size" src={timeImage} alt="" />
                </div>
              </TimeEntry>
            </PermissionsGate>
          </div>
        </td>
        {/* <div className='td'>{task.customer_visible}</div> */}
      </tr>
      {task.has_subitems && !task.isMinimised
        ? tasks
            .filter((item) => item.parent_task === task.id)
            .map((subtask: ITask) => (
              <TaskRow
                task={subtask}
                depth={depth + 1}
                key={subtask.project_id + '-' + subtask.sprint_id + '-' + subtask.id}
              />
            ))
        : null}
    </>
  );
};

export default TaskRow;
