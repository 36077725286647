import { IErrorResponse, IEntity, IFile, IChangePriority } from './../../../types/index';
import { AxiosError } from 'axios';
import { RootState } from '../../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { ITaskDetail, ITaskDetailModel, TaskState } from './types';
import { SubscribersType } from '../../../services/api/endpoints/project';
import { DetailTabsEnum } from '../../../utils/constants';

const initialState: TaskState = {
  task: null,
  loading: {
    task: false,
  },
  currentTab: null,
  error: null,
};

export const fetchTaskAsync = createAsyncThunk<any, number, { state: RootState }>(
  'task/fetchTask',
  async function (id: number, { rejectWithValue }) {
    try {
      const response = await api.task.get(id);
      const users = await api.project.users({ id: response.data.model.project.id });

      return {
        task: response.data.model,
        tags: response.data.tags,
        taskFiles: response.data.files,
        users: users.data,
      };
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const deleteTaskAsync = createAsyncThunk<any, void, { state: RootState }>(
  'task/deleteTask',
  async function (_, { rejectWithValue, getState, dispatch }) {
    try {
      const state = getState();
      if (!state.task.task) return;

      await api.task.delete(state.task.task.id);
      dispatch(resetTask());
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changeTaskDetailPriorityAsync = createAsyncThunk<
  any,
  IChangePriority,
  { state: RootState }
>(
  'task/changeTaskDetailPriority',
  async function (params: IChangePriority, { rejectWithValue, dispatch }) {
    try {
      await api.task.setPriority({ id: params.id, priority: params.priority });
      dispatch(changePriorityTask(+params.priority));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    changeCurrentTab(state, action: PayloadAction<DetailTabsEnum>) {
      state.currentTab = action.payload;
    },
    changePriorityTask(state, action: PayloadAction<number>) {
      if (state.task) state.task.priority = action.payload;
    },
    changeActualTime(state, action: PayloadAction<string>) {
      if (state.task) state.task.actual_time = action.payload;
    },
    startDiscussion(state, action: PayloadAction<string>) {
      if (state.task) {
        if (action.payload === '1') {
          state.task.has_discussion_team = true;
        } else {
          state.task.has_discussion = true;
        }
      }
    },
    resetTask() {
      return {
        ...initialState,
        currentTab: DetailTabsEnum.commentTeam,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskAsync.pending, (state, action) => {
        state.loading.task = true;
      })
      .addCase(
        fetchTaskAsync.fulfilled,
        (
          state,
          action: PayloadAction<{
            task: ITaskDetailModel;
            tags: IEntity[];
            taskFiles: IFile[];
            users?: SubscribersType;
          }>
        ) => {
          const task: ITaskDetail = {
            ...action.payload.task,
            tags: action.payload.tags,
            taskFiles: action.payload.taskFiles,
            users: action.payload.users || [],
          };
          state.task = task;
          state.error = null;
          state.loading.task = false;
        }
      )
      .addCase(fetchTaskAsync.rejected, (state, action) => {
        state.error = String(action.payload);
        state.loading.task = false;
      });
  },
});

export const {
  changeCurrentTab,
  changeActualTime,
  startDiscussion,
  resetTask,
  changePriorityTask,
} = taskSlice.actions;

export default taskSlice.reducer;
