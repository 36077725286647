import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

interface CountPerPageProps {
  onChange: (count: string) => void;
  countPerPage: number | null;
  options?: number[];
}

const CountPerPage: React.FC<CountPerPageProps> = ({
  countPerPage,
  onChange,
  options = [50, 100, 200],
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.paginationCount}>
      <Form.Select onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
          <option key={option} value={option} selected={countPerPage === option}>
            {option} {t('pagination.entries')}
          </option>
        ))}
        <option value="" selected={countPerPage === null}>
          {t('pagination.allEntries')}
        </option>
      </Form.Select>
    </div>
  );
};

export default CountPerPage;
