import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { TimeReportFilters } from './timeReportsFilter/index';
import { TimeReportsHeader } from './timeReportsHeader';
import {
  changePage,
  changePaginationCount,
  fetchProjectsAsync,
  fetchTimeReportsAsync,
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPrevPage,
} from '../../store/slices/timeReportsTab';
import { TimeReportTableComponent } from './timeReportTableComponent';
import Pagination from '../pagination';
import Preloader from '../preloader';
import './style.css';
import CountPerPage from '../countPerPage';

interface TimeReportsProps {}

export const TimeReportTable: React.FC<TimeReportsProps> = () => {
  const dispatch = useAppDispatch();
  const { filter, pagination, countPerPage, loading } = useAppSelector(
    (state) => state.timeReportsTab
  );

  useEffect(() => {
    dispatch(fetchProjectsAsync());
  }, []);

  useEffect(() => {
    dispatch(fetchTimeReportsAsync(false));
  }, [filter]);

  return (
    <div className="timeReport">
      <TimeReportFilters />
      <TimeReportsHeader />

      {loading.data ? (
        <Preloader />
      ) : (
        <>
          <TimeReportTableComponent />
          <div className="timeReport-container">
            {pagination ? (
              <Pagination
                currentPage={pagination.page}
                pagesTotal={pagination.pages_total}
                onChangePage={(i: number) => {
                  dispatch(changePage(i));
                  dispatch(fetchTimeReportsAsync(true));
                }}
                onGoToFirstPage={() => {
                  dispatch(goToFirstPage());
                  dispatch(fetchTimeReportsAsync(true));
                }}
                onGoToPrevPage={() => {
                  dispatch(goToPrevPage());
                  dispatch(fetchTimeReportsAsync(true));
                }}
                onGoToNextPage={() => {
                  dispatch(goToNextPage());
                  dispatch(fetchTimeReportsAsync(true));
                }}
                onGoToLastPage={() => {
                  dispatch(goToLastPage());
                  dispatch(fetchTimeReportsAsync(true));
                }}
              />
            ) : (
              <div></div>
            )}

            <CountPerPage
              countPerPage={countPerPage}
              onChange={(count: string) => {
                dispatch(changePaginationCount(count));
                dispatch(fetchTimeReportsAsync(false));
              }}
              options={[100, 500, 1000]}
            />
          </div>
        </>
      )}
    </div>
  );
};
