import React, { useEffect } from 'react';
import './style.css';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchProjectsAsync, fetchSprintsAsync, fetchTasksAsync } from '../../store/slices/tasks';
import TaskRow from './taskRow';
import HeaderTable from './headerTable';
import ProjectRow from './projectRow';
import SprintRow from './sprintRow';
import Preloader from '../preloader';
import { ITask } from '../../store/slices/tasks/types';
import axios, { AxiosError } from 'axios';
import { IErrorResponse } from '../../types';
import { useTranslation } from 'react-i18next';
import task from '../../store/slices/task';

interface TasksTableProps {}

const TasksTable: React.FC<TasksTableProps> = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { filter, groupBy, sort, data, loading } = useAppSelector((state) => state.tasks);
  const { projects, sprints, tasks } = data;

  useEffect(() => {
    dispatch(fetchProjectsAsync())
      .then(() => {
        dispatch(fetchSprintsAsync());
      })
      .catch((error: Error | AxiosError<IErrorResponse>) => {
        alert(axios.isAxiosError(error) ? error.response?.data.error_message : error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (filter.project) {
      dispatch(fetchTasksAsync());
    }
  }, [filter, dispatch]);

  const sortFn = (a: ITask, b: ITask) => {
    if (sort.direction === 0) {
      return 0;
    }
    let direction = -1;
    if (sort.direction === 2) {
      direction = 1;
    }
    switch (sort.column) {
      case 'priority': {
        return (a.priority > b.priority ? 1 : -1) * direction;
      }
      case 'status': {
        return (a.status > b.status ? 1 : -1) * direction;
      }
      case 'name': {
        return (a.name > b.name ? 1 : -1) * direction;
      }
      case 'id': {
        return (a.id > b.id ? 1 : -1) * direction;
      }
      case 'assignee': {
        if (!a.assigned_to) {
          return 1;
        }
        if (!b.assigned_to) {
          return -1;
        }
        return (a.assigned_to.name > b.assigned_to.name ? 1 : -1) * direction;
      }
      case 'due': {
        return (a.due_date > b.due_date ? 1 : -1) * direction;
      }
    }
    return 1;
  };

  const getTable = (content: JSX.Element[], id = 1) => {
    return content.length ? (
      <table className="b-table tasks-table" key={`${id}`}>
        <tbody>{content}</tbody>
      </table>
    ) : (
      <div className="no-tasks">{t('tasks.empty')}</div>
    );
  };

  const getTasksWithGroups = () => {
    const output: JSX.Element[] = [];
    let tableRows: JSX.Element[] = [];
    const project = projects.find((project) => project.id === Number(filter.project));

    if (groupBy.project && groupBy.sprint && project) {
      tableRows = [];
      tableRows.push(<ProjectRow project={project} key={`project-${project.id}`} />);
      if (!project.isMinimised) {
        sprints
          .filter((sprint) => sprint.project_id === project.id)
          .forEach((sprint) => {
            const tasksList = tasks.filter(
              (task) =>
                (task.sprint_id === sprint.id || (sprint.isUnsorted && task.sprint_id === null)) &&
                task.project_id === project.id // TODO: task.parent_task === null?
            );
            tableRows.push(<SprintRow sprint={sprint} key={`${project.id}-${sprint.id}`} />);
            if (!sprint.isMinimised) {
              let tasksSorted = [...tasksList];
              tasksSorted.sort(sortFn);
              tasksSorted.forEach((task: ITask) => {
                if (task.has_subitems) {
                  return;
                }
                tableRows.push(
                  <TaskRow task={task} key={`${project.id}-${sprint.id}-${task.id}`} />
                );
              });
            }
          });
      }
      output.push(getTable(tableRows, +project.id));
    } else if (groupBy.project && project) {
      tableRows = [];
      tableRows.push(<ProjectRow project={project} key={`project-${project.id}`} />);
      const tasksList = tasks.filter(
        (task: ITask) =>
          task.project_id === project.id && (task.parent_task === null || task.parent_task === 0)
      );
      if (!project.isMinimised) {
        let tasksSorted = [...tasksList];
        tasksSorted.sort(sortFn);
        tasksSorted.forEach((task: ITask) => {
          tableRows.push(<TaskRow task={task} key={`${project.id}-${task.id}`} />);
        });
      }
      output.push(getTable(tableRows, +project.id));
    } else {
      // const sprint = sprints.find((sprint: ISprint) => sprint.id === -1);
      // if (sprint) {
      //   tableRows.push(<SprintRow sprint={sprint} key={-1} hide={true}></SprintRow>);
      // }

      let tasksSorted = [
        ...tasks.filter((task) => task.parent_task === null || task.parent_task === 0),
      ];
      tasksSorted.sort(sortFn);

      tasksSorted.forEach((task: ITask) => {
        tableRows.push(<TaskRow task={task} key={`task-${task.id}`} />);
      });
      output.push(getTable(tableRows));
    }

    return output;
  };

  return (
    <>
      {loading.tasks ? (
        <Preloader />
      ) : (
        <>
          <HeaderTable />
          {getTasksWithGroups()}
        </>
      )}
    </>
  );
};

export default TasksTable;
