import React, { useState } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import TaskPopover from '../../popovers/taskPopover';
import { useAppSelector } from '../../../hooks';
import { Button, Modal } from 'react-bootstrap';
import PermissionsGate from '../../permissionsGate';
import { Permissions } from '../../../types/permissions';
import SprintForm from '../../forms/sprintForm';

const EntityCreator = () => {
  const { t } = useTranslation('common');
  const { filter, data } = useAppSelector((state) => state.tasks);
  const [taskIsOpen, setTaskIsOpen] = useState(false);
  const [modalSprintShow, setModalSprintShow] = useState(false);

  const openTaskPopover = () => {
    setTaskIsOpen((prev) => !prev);
  };

  const closeModal = () => setModalSprintShow(false);
  const showModal = () => setModalSprintShow(true);

  const modal = (
    <Modal size="lg" centered show={modalSprintShow} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t('addEntity.sprint.create')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SprintForm closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  );

  return (
    <div className={styles.container}>
      <PermissionsGate permissions={[Permissions.create_tasks]}>
        <Button onClick={openTaskPopover}>+ {t('common.task')}</Button>
      </PermissionsGate>
      <PermissionsGate permissions={[Permissions.add_milestones]}>
        <Button onClick={showModal}>+ {t('common.sprint')}</Button>
      </PermissionsGate>

      {modal}

      <div className={styles.popoverCont}>
        {taskIsOpen ? (
          <div className={styles.popoverTask}>
            <TaskPopover
              fromDetail={false}
              defaultProject={data.projects.find((item) => item.id === Number(filter.project))}
              onClose={() => setTaskIsOpen(false)}
            ></TaskPopover>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EntityCreator;
