import React, { useState } from 'react';
import { useAppDispatch } from '../../hooks';
import api from '../../services/api';
import { ITask } from '../../store/slices/tasks/types';
import './style.css';
import editImage from '../../assets/images/icons/edit.svg';
import OutsideAlerter from '../outsideAlerter';
import DatePicker from 'react-datepicker';
import { changeDue } from '../../store/slices/tasks';
import axios, { AxiosError } from 'axios';
import { IErrorResponse } from '../../types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PermissionsGate from '../permissionsGate';
import { Permissions } from '../../types/permissions';
interface ChangeDueProps {
  task: ITask;
}

const ChangeDue: React.FC<ChangeDueProps> = ({ task }) => {
  const dispatch = useAppDispatch();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dueDate, setDueDate] = useState(
    task.due_date === '--' ? new Date() : new Date(task.due_date)
  );
  const [isDueGreen, setDueGreen] = useState(false);

  const handleDatePickerChange = (date: Date) => {
    setIsCalendarOpen(!isCalendarOpen);
    setDueDate(date);
    const id = task.id;
    const due_date = dayjs(date).format('YYYY-MM-DD');
    const old_due_date = task.due_date;
    let done = false;
    let doneWaiting = false;
    setTimeout(() => {
      doneWaiting = true;
      if (done) {
        setDueGreen(false);
      }
    }, 2000);
    setDueGreen(true);
    dispatch(changeDue({ id: id, due_date: due_date }));
    api.task
      .setDue({
        id,
        due_date,
      })
      .then((response) => {
        done = true;
        if (doneWaiting) {
          setDueGreen(false);
        }
      })
      .catch((error: Error | AxiosError<IErrorResponse>) => {
        setDueGreen(false);
        dispatch(changeDue({ id: id, due_date: old_due_date }));
        alert(axios.isAxiosError(error) ? error.response?.data.error_message : error);
      });
  };

  const handleDueDateClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setIsCalendarOpen(!isCalendarOpen);
  };

  const { t } = useTranslation('common');
  function dateFormat(date: string) {
    let formatedDate = date;
    if (dayjs(date, 'YYYY-MM-DD').isValid()) {
      const unixTaskDue = dayjs(task.due_date, 'YYYY-MM-DD').unix();
      formatedDate = dayjs.unix(unixTaskDue).format(t('common.dateFormat'));
    }
    return formatedDate;
  }

  return (
    <div className="b-due">
      <div style={{ color: isDueGreen ? '#8EECC4' : '' }}>{dateFormat(task.due_date)}</div>

      <PermissionsGate permissions={[Permissions.update_tasks]}>
        <div className="btn-edit" onClick={handleDueDateClick}>
          <img src={editImage} alt="" />
        </div>
        {isCalendarOpen && (
          <OutsideAlerter handler={() => setIsCalendarOpen(false)}>
            <div className="datepicker-due">
              <DatePicker
                selected={dueDate}
                onChange={handleDatePickerChange}
                calendarStartDay={1}
                dateFormat={t('common.dateFormat')}
                inline
              />
            </div>
          </OutsideAlerter>
        )}
      </PermissionsGate>
    </div>
  );
};

export default ChangeDue;
