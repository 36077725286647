import React, { useEffect } from 'react';
import { TimeReportTable } from '../../components/timeReportTable/index';
import { useTranslation } from 'react-i18next';

interface TimeReportProps {}

export const TimeReport: React.FC<TimeReportProps> = () => {
  const { t } = useTranslation('common');

  useEffect(() => {
    document.title = t('header.menu.timeReport');
  }, []);

  return <TimeReportTable />;
};

export default TimeReport;
