import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks';
import { statusNames } from '../../../utils/constants';
import { TimeReport } from '../../../services/api/endpoints/type';
import { Link } from 'react-router-dom';

interface TableComponentProps {}

interface GroupedByDays {
  [key: string]: TimeReport[];
}

export const TimeReportTableComponent: React.FC<TableComponentProps> = () => {
  const { t } = useTranslation('common');
  const { data, groupByDay } = useAppSelector((state) => state.timeReportsTab);

  function hoursStringToDecimal(hoursString: string) {
    const [hoursPart, minutesPart] = (hoursString.length ? hoursString : '0:00').split(':');
    return Number(hoursPart) + Number(minutesPart) / 60;
  }

  function decimalHoursToString(hoursDecimal: number) {
    const numHours = Math.floor(hoursDecimal);
    const numMinutes = Math.round((hoursDecimal - numHours) * 60);
    return `${numHours}:${numMinutes < 10 ? '0' : ''}${numMinutes}`;
  }

  function groupByDayData() {
    const timeReports = data.timeReports;
    if (!timeReports.length) return {};

    const groupedData: GroupedByDays = {};

    timeReports.forEach((record) => {
      const date = record.date;
      if (!groupedData[date]) {
        groupedData[date] = [];
      }
      groupedData[date].push(record);
    });

    return groupedData;
  }

  const renderGroupByDayData = () => {
    const groupedData = groupByDayData();

    return Object.keys(groupedData).map((date, index) => {
      const section = groupedData[date];

      return (
        <tr className="timeTable-string" key={index}>
          <td className="timeTable-project timeTable">
            {Array.from(new Set(section.map((item) => item.project.name))).join(', ')}
          </td>
          <td className="timeTable-date timeTable">{date}</td>
          <td className="timeTable-created timeTable">
            {Array.from(new Set(section.map((item) => item.created))).join(', ')}
          </td>
          <td className="timeTable-user timeTable">
            {Array.from(new Set(section.map((item) => item.author.name))).join(', ')}
          </td>
          <td className="timeTable-type timeTable">
            {Array.from(
              new Set(
                section.map((item) => item.task.type.charAt(0).toUpperCase() + '-' + item.task.id)
              )
            ).join(', ')}
          </td>
          <td className="timeTable-item timeTable">
            {Array.from(new Set(section.map((item) => item.task.name))).join(', ')}
          </td>
          <td className="timeTable-status timeTable">
            {Array.from(new Set(section.map((item) => t(statusNames[item.task.status])))).join(
              ', '
            )}
          </td>
          <td className="timeTable-comment timeTable">
            {Array.from(
              new Set(
                section.map((item) =>
                  item.comments !== null ? item.comments : t('comments.noComment')
                )
              )
            ).join(', ')}
          </td>
          <td className="timeTable-hours timeTable">
            {section.reduce(
              (res, item) =>
                decimalHoursToString(hoursStringToDecimal(res) + hoursStringToDecimal(item.hours)),
              '0:00'
            )}
          </td>
        </tr>
      );
    });
  };

  const renderData = () => {
    return data.timeReports.map((item, id) => {
      return (
        <tr className="timeTable-string" key={id}>
          <td className="timeTable-project timeTable">{item.project.name}</td>
          <td className="timeTable-date timeTable">{item.date}</td>
          <td className="timeTable-created timeTable">{item.created}</td>
          <td className="timeTable-user timeTable">{item.author.name}</td>
          <td className="timeTable-type timeTable">
            {item.task.type.charAt(0).toUpperCase() + '-' + item.task.id}
          </td>
          <td className="timeTable-item timeTable">
            <Link to={`/tasks/${item.task.id}`} target="_blank">
              {item.task.name}
            </Link>
          </td>
          <td className="timeTable-status timeTable">{t(statusNames[item.task.status])}</td>
          <td className="timeTable-comment timeTable">
            {item.comments !== null ? item.comments : t('comments.noComment')}
          </td>
          <td className="timeTable-hours timeTable">{item.hours}</td>
        </tr>
      );
    });
  };

  return (
    <table className="table-component">{groupByDay ? renderGroupByDayData() : renderData()}</table>
  );
};
