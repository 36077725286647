import { TimeReportTask } from '../../services/api/endpoints/type';
import { useAppSelector } from '../../hooks';
import dayjs from 'dayjs';
import { ITask, ITaskModel } from '../../store/slices/tasks/types/index';
import api from '../../services/api';
import { IErrorResponse } from '../../types';
import { AxiosError } from 'axios';

// export const findElementById = <T>(items: T[], itemId: number): T | null =>
//   items.reduce((a: any, item: any) => {
//     if (a) return a;
//     if (item.id === itemId) return item;
//     if (item.hasOwnProperty('subitems') && item.subitems.length)
//       return findElementById(item.subitems, itemId);
//   }, null);

export const addExtraParams = (data: ITaskModel[], minimisedTasksList: number[]): ITask[] => {
  return data.map((item: any) => {
    item.isChecked = false;

    if (minimisedTasksList.some((obj) => Number(obj) === Number(item.id))) {
      item.isMinimised = false;
    } else {
      item.isMinimised = true;
    }

    if (item.has_subitems) {
      item.childrenAreChecked = false;
    }
    return item;
  });
};

export const checkTimeFormat = (e: React.KeyboardEvent) => {
  if (!/[\d:]/.test(e.key)) {
    e.preventDefault();
  }
};

export const checkPhoneFormat = (e: React.KeyboardEvent) => {
  if (!/[ \d()+-]/.test(e.key)) {
    e.preventDefault();
  }
};

export const checkOnlyNumbers = (e: React.KeyboardEvent) => {
  if (!/[\d.]/.test(e.key)) {
    e.preventDefault();
  }
};

export const declOfNum = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const getCheckedTasksIds = (tasks: ITask[]) => {
  const checkedObjects = tasks.filter((task) => task.isChecked === true);
  return checkedObjects.map((obj) => obj.id);
};

export const getSumTime = (time: string, hours: number, mins: number) => {
  const timeArray = (time.trim() === '' ? '0:00' : time).split(':');
  const zeroMins = ((+timeArray[1] + mins) % 60).toString().length <= 1 ? '0' : ''; // for '05' instead of '5' mins
  const resHours = +timeArray[0] + hours + Math.floor((+timeArray[1] + mins) / 60);
  const resMins = zeroMins + ((+timeArray[1] + mins) % 60);

  return resHours + ':' + resMins;
};

export const useGetCheckedIds = () => {
  const data = useAppSelector((state) => state.taskTime.data.timeReports);

  return (
    (data?.length &&
      data
        .filter((el: TimeReportTask) => el.checked === true)
        .map((el: TimeReportTask) => el.id)) ||
    null
  );
};

export const useGetDate = () => {
  const { modalParams } = useAppSelector((state) => state.taskTime);

  if (modalParams.startDate) {
    return (
      modalParams.startDate.getFullYear() +
      '-' +
      ('0' + (modalParams.startDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + modalParams.startDate.getDate()).slice(-2)
    );
  }
};

export const replaceLineBreaksToBr = (string: string | null | undefined) => {
  if (string === null || string === undefined) return '';

  return string.replace(/(\r\n|\n|\r)/gm, '<br />');
};

export const formattedSprintDates = (startDate?: string, endDate?: string) => {
  if (startDate && endDate) {
    return `(${dayjs(startDate).format('YYYY-MM-DD')} — ${dayjs(endDate).format('YYYY-MM-DD')})`;
  } else if (startDate) {
    return `(с ${dayjs(startDate).format('YYYY-MM-DD')})`;
  } else if (endDate) {
    return `(до ${dayjs(endDate).format('YYYY-MM-DD')})`;
  } else return '';
};

export const onDownloadFile = async (fileId: number, filename: string) => {
  try {
    const response = await api.files.get(fileId);

    if (response && response.data) {
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    }
  } catch (err: any) {
    let error: AxiosError<IErrorResponse> = err;
    if (error.response && error.response.data.error_message) {
      alert(error.response.data.error_message);
    }
  }
};
