import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../hooks';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { BooleanNumber, ISelectOptions, ISelectOptionsWithId } from '../../../types';
import { userRoles } from '../../../utils/constants';
import { getValidSelectOption, getValidSelectOptions } from '../../../utils/helpers/selects';
import Select from 'react-select';
import { checkPhoneFormat } from '../../../utils/helpers';
import { IUsersCreateRequest } from '../../../services/api/endpoints/user';
import { createUserAsync, updateUserAsync } from '../../../store/slices/admin/companiesAndUsers';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IUserFormFields, UserFormFields } from './types';
import api from '../../../services/api';
import { AxiosError } from 'axios';
import Preloader from '../../preloader';

interface UserFormProps {
  userId?: number | null;
  closeModal: () => void;
}

const UserForm: React.FC<UserFormProps> = ({ closeModal, userId = null }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserFormFields>({
    defaultValues: {
      [UserFormFields.login]: '',
      [UserFormFields.name]: '',
      [UserFormFields.password]: '',
      [UserFormFields.password_confirm]: '',
      [UserFormFields.title]: '',
      [UserFormFields.mobile_phone]: '',
      [UserFormFields.email]: '',
      [UserFormFields.backup_email]: '',
      [UserFormFields.vpn_access]: false,
      [UserFormFields.role]: getValidSelectOption(userRoles[2]),
      [UserFormFields.projects]: [],
    },
  });

  const userRoleList: ISelectOptionsWithId<number>[] = [...getValidSelectOptions(userRoles)];

  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [allProjects, setAllProjects] = useState<ISelectOptions<number>[]>([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      await api.project
        .allProjects()
        .then((response) => {
          setAllProjects(getValidSelectOptions(response.data));
        })
        .catch((error: AxiosError) => {
          alert(error.response?.data.error_message || 'Не удалось получить список проектов');
        });

      if (userId) {
        await api.user
          .getInfoUser(userId)
          .then((response) => {
            const role = userRoles.find((item) => item.id === response.data.role);
            reset({
              [UserFormFields.login]: response.data.login,
              [UserFormFields.name]: response.data.name,
              [UserFormFields.title]: response.data.title,
              [UserFormFields.mobile_phone]: response.data.mobile,
              [UserFormFields.email]: response.data.email_work,
              [UserFormFields.backup_email]: response.data.email_home,
              [UserFormFields.vpn_access]: response.data.vpn_access,
              [UserFormFields.role]: getValidSelectOption(role ?? userRoles[2]),
              [UserFormFields.projects]: getValidSelectOptions(response.data.projects),
            });
          })
          .catch((error: AxiosError) => {
            alert(error.response?.data.error_message || 'Не удалось получить данные пользователя');
          });
      }

      setLoading(false);
    };

    getData();
  }, []);

  const onSubmit: SubmitHandler<IUserFormFields> = async (data) => {
    setPending(true);

    const params: IUsersCreateRequest = {
      login: data.login,
      name: data.name,
      password: data.password,
      password_confirm: data.password_confirm,
      title: data.title,
      mobile_phone: data.mobile_phone,
      email: data.email,
      backup_email: data.backup_email,
      vpn_access: Number(data.vpn_access) as BooleanNumber,
      role: data.role.value,
    };

    if (data.projects && data.projects.length) {
      params.projects = data.projects.map((tag) => tag.value);
    }

    if (userId) {
      await dispatch(updateUserAsync({ id: userId, params: params }));
    } else {
      await dispatch(createUserAsync(params));
    }

    setPending(false);
    closeModal();
  };

  return loading ? (
    <Preloader />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <Row className="mb-3">
        <Col className="b-input-light">
          <Form.Label>{t('users.login')}</Form.Label>
          <Form.Control
            type="text"
            {...register(UserFormFields.login, { required: true })}
            isInvalid={!!errors.login}
          />
          <Form.Control.Feedback type="invalid">{t('formErrors.required')}</Form.Control.Feedback>
        </Col>
        <Col className="b-input-light">
          <Form.Label>{t('users.name')}</Form.Label>
          <Form.Control
            type="text"
            {...register(UserFormFields.name, { required: true })}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">{t('formErrors.required')}</Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="b-input-light">
          <Form.Label>{userId ? t('users.newPassword') : t('users.password')}</Form.Label>
          <Form.Control
            type="password"
            autoComplete="new-password"
            {...register(UserFormFields.password, {
              required: userId
                ? false
                : {
                    message: t('formErrors.required'),
                    value: true,
                  },
              minLength: {
                message: t('formErrors.minPasswordLength'),
                value: 6,
              },
            })}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
        </Col>
        <Col className="b-input-light">
          <Form.Label>{t('users.passwordConfirm')}</Form.Label>
          <Form.Control
            type="password"
            autoComplete="new-password"
            {...register(UserFormFields.password_confirm, {
              required: userId
                ? false
                : {
                    message: t('formErrors.required'),
                    value: true,
                  },
              minLength: {
                message: t('formErrors.minPasswordLength'),
                value: 6,
              },
              validate: (val: string) => {
                if (watch(UserFormFields.password) !== val) {
                  return t('formErrors.passwordsDontMatch').toString();
                }
              },
            })}
            isInvalid={!!errors.password_confirm}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password_confirm?.message}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="b-input-light">
          <Form.Label>{t('users.titleField')}</Form.Label>
          <Form.Control type="text" {...register(UserFormFields.title)} />
        </Col>
        <Col className="b-input-light">
          <Form.Label>{t('users.mobilePhone')}</Form.Label>
          <Form.Control
            type="text"
            onKeyPress={checkPhoneFormat}
            {...register(UserFormFields.mobile_phone)}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="b-input-light">
          <Form.Label>Email</Form.Label>
          <Form.Control type="text" {...register(UserFormFields.email)} />
        </Col>
        <Col className="b-input-light">
          <Form.Label>{t('users.backupEmail')}</Form.Label>
          <Form.Control type="text" {...register(UserFormFields.backup_email)} />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="b-select-light">
            <label>{t('users.role')}</label>
            <Controller
              name={UserFormFields.role}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={userRoleList}
                  getOptionLabel={(option) => t(option.name)}
                  isSearchable={false}
                  classNamePrefix="custom-select"
                />
              )}
            />
          </div>
        </Col>
        <Col>
          <Form.Check
            className={styles.vpnCheckbox}
            type="checkbox"
            id="vpnAccess"
            label={t('users.vpnAccess')}
            {...register(UserFormFields.vpn_access)}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="b-select-light">
            <label>{t('users.userProjects')}</label>
            <Controller
              name={UserFormFields.projects}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={allProjects}
                  getOptionLabel={(option: ISelectOptions) => option.name}
                  isMulti
                  classNamePrefix="custom-select"
                  placeholder={t('users.selectProjects')}
                />
              )}
            />
          </div>
        </Col>
      </Row>

      <div className={styles.buttons}>
        <Button variant="outline-secondary" onClick={closeModal}>
          {t('common.cancel')}
        </Button>
        <Button type="submit" disabled={pending}>
          {t('common.save')}
        </Button>
      </div>
    </Form>
  );
};

export default UserForm;
