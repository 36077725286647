import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFilterParam } from '../../../store/slices/tasks';
import { useTranslation } from 'react-i18next';
import { ISelectOptions } from '../../../types';
import Select from 'react-select';
import styles from './styles.module.scss';
import { getValidSelectOption, getValidSelectOptions } from '../../../utils/helpers/selects';
import { IUser } from '../../../store/slices/users/types';

const ANY_ASSIGNEE_VALUE = -1;

interface FilterProps {}

const AssigneeFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { users, loading } = useAppSelector((state) => state.users);
  const { assigned_to } = useAppSelector((state) => state.tasks.filter);
  const { t } = useTranslation('common');
  const anyAssignee: IUser = {
    id: ANY_ASSIGNEE_VALUE,
    name: 'filter.anyAssignee',
  };
  const validSelectOptionAssignee = getValidSelectOption(anyAssignee);

  const [currentAssignee, setCurrentAssignee] = useState<ISelectOptions | null>(
    validSelectOptionAssignee
  );

  const assigneeList: ISelectOptions[] = [
    validSelectOptionAssignee,
    ...getValidSelectOptions(users),
  ];

  useEffect(() => {
    if (assigned_to) {
      const assigneeItem = assigneeList.find((item) => +item.value === +assigned_to);
      if (assigneeItem) {
        setCurrentAssignee(assigneeItem);
      }
    }
  }, []);

  const onChange = (option: ISelectOptions | null) => {
    if (option) {
      dispatch(
        changeFilterParam({ assigned_to: option.value === ANY_ASSIGNEE_VALUE ? '' : option.value })
      );
    }
  };

  return (
    <div className="b-select-light">
      <Select
        className={styles.select}
        options={assigneeList}
        value={currentAssignee}
        getOptionLabel={(option) =>
          option.value === ANY_ASSIGNEE_VALUE ? t(option.name) : option.name
        }
        onChange={(option) => {
          setCurrentAssignee(option);
          onChange(option);
        }}
        classNamePrefix="custom-select"
        isLoading={loading}
      />
    </div>
  );
};

export default AssigneeFilter;
