import { ISelectOptions, IStatus, IPriority, IEntity } from '../../types';

export enum TaskStatus {
  NOT_STARTED = 2,
  QUOTETOBECONFIRMED = 6,
  OPEN = 3,
  READY_FOR_TESTING = 0,
  TESTER_VERIFIED = 7,
  CUSTMER_VERIFIED = 8,
  CLOSED = 1,
  ARCHEVED = 4,
}

export const statusNames = {
  [TaskStatus.NOT_STARTED]: 'statuses.notStarted',
  [TaskStatus.QUOTETOBECONFIRMED]: 'statuses.quoteToBeConfirmed',
  [TaskStatus.OPEN]: 'statuses.open',
  [TaskStatus.READY_FOR_TESTING]: 'statuses.readyForTesting',
  [TaskStatus.TESTER_VERIFIED]: 'statuses.testerVerified',
  [TaskStatus.CUSTMER_VERIFIED]: 'statuses.customerVerified',
  [TaskStatus.CLOSED]: 'statuses.closed',
  [TaskStatus.ARCHEVED]: 'statuses.archived',
};

export interface IStatusSeparated extends IStatus<string> {
  type: string;
}

export const projectsStatuses: IStatusSeparated[] = [
  {
    name: 'statuses.opened',
    borderColor: '#8EECC4',
    color: '#2BB984',
    value: '3',
    type: 'active',
  },
  {
    name: 'statuses.closed',
    borderColor: '#C6C6C6',
    color: '#858585',
    value: '1',
    type: 'inActive',
  },
];

export const statuses: IStatusSeparated[] = [
  {
    name: 'statuses.notStarted',
    borderColor: '#FFE499',
    color: '#D9A000',
    value: '2',
    type: 'active',
  },
  {
    name: 'statuses.quoteToBeConfirmed',
    borderColor: '#B562B7',
    color: '#B562B7',
    value: '6',
    type: 'active',
  },
  {
    name: 'statuses.open',
    borderColor: '#8EECC4',
    color: '#2BB984',
    value: '3',
    type: 'active',
  },
  {
    name: 'statuses.readyForTesting',
    borderColor: '#FFE499',
    color: '#D9A000',
    value: '0',
    type: 'active',
  },
  {
    name: 'statuses.testerVerified',
    borderColor: '#FFE499',
    color: '#D9A000',
    value: '7',
    type: 'active',
  },
  {
    name: 'statuses.customerVerified',
    borderColor: '#FFE499',
    color: '#D9A000',
    value: '8',
    type: 'active',
  },
  {
    name: 'statuses.closed',
    borderColor: '#C6C6C6',
    color: '#858585',
    value: '1',
    type: 'inActive',
  },
  {
    name: 'statuses.archived',
    borderColor: '#C6C6C6',
    color: '#858585',
    value: '4',
    type: 'inActive',
  },
];

export const activityTabHeaders = [
  { name: 'activity.author' },
  { name: 'activity.date' },
  { name: 'activity.description' },
];

export const projectTabHeaders = [
  { name: 'project.id' },
  { name: 'project.name' },
  { name: 'project.description' },
  { name: 'project.priority' },
  { name: 'project.status' },
  { name: 'project.organization' },
];

export enum DetailTabsEnum {
  commentTeam = 'commentTeam',
  commentCustomer = 'commentCustomer',
  files = 'files',
  time = 'time',
  activity = 'activity',
}

export const projectsSortableColumns = ['id', 'name', 'priority', 'status'];

export const priorities: IPriority<string>[] = [
  {
    name: 'priority.veryLow',
    color: '#82E237',
    value: '1',
  },
  {
    name: 'priority.low',
    color: '#C1D83D',
    value: '2',
  },
  {
    name: 'priority.medium',
    color: '#FFCE43',
    value: '3',
  },
  {
    name: 'priority.high',
    color: '#EC6923',
    value: '4',
  },
  {
    name: 'priority.veryHigh',
    color: '#D80303',
    value: '5',
  },
];

export const taskTypes: ISelectOptions<string>[] = [
  {
    name: 'taskTypes.task',
    value: 'task',
  },
  {
    name: 'taskTypes.bug',
    value: 'bug',
  },
  {
    name: 'taskTypes.request',
    value: 'request',
  },
  {
    name: 'taskTypes.ui_issue',
    value: 'ui_issue',
  },
];

export const estimatedTimeValues: ISelectOptions<string>[] = [
  {
    name: 'estimatedTime.manualEntry',
    value: 'time',
  },
  {
    name: 'estimatedTime.sumOfSubitems',
    value: 'sum_of_sub',
  },
];

export const billableTimeValues: ISelectOptions<string>[] = [
  {
    name: 'billableTime.manualEntry',
    value: 'time',
  },
  {
    name: 'billableTime.worked',
    value: 'worked',
  },
  {
    name: 'billableTime.estimated',
    value: 'estimated',
  },
  {
    name: 'billableTime.sumOfSubitems',
    value: 'sum_of_sub',
  },
];

export const billings: ISelectOptions<string>[] = [
  {
    name: 'billings.no',
    value: '0',
  },
  {
    name: 'billings.billed',
    value: '1',
  },
  {
    name: 'billings.partiallyPaid',
    value: '2',
  },
  {
    name: 'billings.paidInFull',
    value: '3',
  },
];

export const customerVisibleValues: ISelectOptions<string>[] = [
  {
    name: 'common.yes',
    value: '0', // выглядит нелогично, но в БД у задач, которые видны клиенту значение = 0
  },
  {
    name: 'common.no',
    value: '1',
  },
];

export const due: ISelectOptions<string>[] = [
  {
    name: 'due.datePicker',
    value: '0',
  },
  {
    name: 'due.overdue',
    value: '1',
  },
  {
    name: 'due.today',
    value: '2',
  },
  {
    name: 'due.tomorrow',
    value: '3',
  },
  {
    name: 'due.thisWeek',
    value: '4',
  },
  {
    name: 'due.noDeadline',
    value: '5',
  },
];
export const due_time_report: ISelectOptions<string>[] = [
  {
    name: 'due.datePicker',
    value: '0',
  },
  {
    name: 'due.today',
    value: '1',
  },
  {
    name: 'due.thisWeek',
    value: '2',
  },
  {
    name: 'due.lastWeek',
    value: '3',
  },
  {
    name: 'due.thisMonth',
    value: '4',
  },
  {
    name: 'due.lastMonth',
    value: '5',
  },
];

export const created: ISelectOptions<string>[] = [
  {
    name: 'created.today',
    value: '1',
  },
  {
    name: 'created.thisWeek',
    value: '2',
  },
  {
    name: 'created.lastWeek',
    value: '3',
  },
  {
    name: 'created.datePicker',
    value: '0',
  },
];

export const projectLanguages: ISelectOptions<string>[] = [
  {
    name: 'RUS',
    value: 'RUS',
  },
  {
    name: 'ENG',
    value: 'ENG',
  },
];

export const projectStatuses: ISelectOptions<number>[] = [
  {
    name: 'project.opened',
    value: 3,
  },
  {
    name: 'project.closed',
    value: 1,
  },
];

export const userRoles: IEntity[] = [
  {
    id: 0,
    name: 'roles.admin',
  },
  {
    id: 1,
    name: 'roles.pm',
  },
  {
    id: 2,
    name: 'roles.user',
  },
  {
    id: 3,
    name: 'roles.customer',
  },
  {
    id: 4,
    name: 'roles.disabled',
  },
];

export enum DetailTabsProjectEnum {}
// TODO: Добавить табы
