import { ISelectOptions } from '../../../types';

export enum SprintFormFields {
  name = 'name',
  project = 'project',
  startDate = 'startDate',
  dueDate = 'dueDate',
}

export type ISprintFormFields = {
  [SprintFormFields.name]: string;
  [SprintFormFields.project]: ISelectOptions<number> | null;
  [SprintFormFields.startDate]: Date | null;
  [SprintFormFields.dueDate]: Date | null;
};
