import React from 'react';
import { ITaskDetail } from '../../store/slices/task/types';
import './style.css';
import wastebasketImage from '../../assets/images/icons/wastebasket.svg';
import editImage from '../../assets/images/icons/edit.svg';
import linkImage from '../../assets/images/icons/link.svg';
import quoteImage from '../../assets/images/icons/quote.svg';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { billings, statuses } from '../../utils/constants';
import parse from 'html-react-parser';
import timeImage from '../../assets/images/icons/plus.svg';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { TimeEntry } from '../tasksTable/timeEntry/timeEntry';
import { QuotePopover } from '../popovers/quotePopover';
import { LinkPopover } from '../popovers/linkPopover';
import ChangePriority from '../changePriority';
import { addWorkedTime } from '../../store/slices/task/timeReports';
import FileList from '../files/fileList';
import { Link } from 'react-router-dom';
import { changeActualTime, changeTaskDetailPriorityAsync } from '../../store/slices/task';
import { Permissions } from '../../types/permissions';
import PermissionsGate from '../permissionsGate';
import { TimeReport } from '../../services/api/endpoints/type';
import { getSumTime } from '../../utils/helpers';

interface TaskCardProps {
  onEdit: () => void;
  onDelete: (task: ITaskDetail) => void;
}

export const TaskCard: React.FC<TaskCardProps> = ({ onEdit, onDelete }) => {
  const { task } = useAppSelector((state) => state.task);
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const onSaveTimeReport = (newTime: TimeReport) => {
    if (task) {
      const [hours, minutes] = newTime.hours.split(':');
      const actualTime = getSumTime(task.actual_time, +hours, +minutes);
      dispatch(changeActualTime(actualTime));
    }
    dispatch(addWorkedTime(newTime));
  };

  const changePriorityHandler = (priority: number) => {
    if (task !== null) {
      dispatch(
        changeTaskDetailPriorityAsync({
          id: task.id,
          priority,
        })
      );
    }
  };

  if (task === null) return null;
  else
    return (
      <div className="b-task-card">
        <div className="b-card-header">
          <div className="b-header-name">{t('detailPage.general')}</div>
          <div className="b-header-icons">
            <PermissionsGate permissions={[Permissions.delete_tasks]}>
              <img
                className="b-header-icon b-header-icon-wastebasket"
                src={wastebasketImage}
                alt=""
                onClick={() => onDelete(task)}
              />
            </PermissionsGate>

            <PermissionsGate permissions={[Permissions.update_tasks]}>
              <img className="b-header-icon" src={editImage} onClick={onEdit} alt="" />
            </PermissionsGate>

            <LinkPopover>
              <img
                className="b-header-icon"
                src={linkImage}
                title={t('detailPage.copyTaskPath')}
                alt=""
              />
            </LinkPopover>

            <QuotePopover>
              <img className="b-header-icon" src={quoteImage} alt="" />
            </QuotePopover>
          </div>
        </div>
        <div className="b-card-body">
          <div className="b-body-left">
            <div className="b-body-param">{t('addEntity.task.name')}</div>
            <div className="b-body-value">{task.name}</div>
            <Row>
              <Col>
                <div className="b-body-param">{t('filter.status')}</div>
                <div className="b-body-value">
                  {t(statuses.find((status) => Number(status.value) === task.status)?.name ?? '')}
                </div>
              </Col>
              <Col>
                <div className="b-body-param">{t('filter.priority')}</div>
                <div className="b-body-value">
                  {
                    <ChangePriority
                      onChangePriority={changePriorityHandler}
                      priority={task.priority}
                      permission={Permissions.update_tasks}
                    />
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="b-body-param">{t('common.project')}</div>
                <div className="b-body-value">{task.project.name}</div>
              </Col>
              <Col>
                <div className="b-body-param">
                  {t('filter.type') + ' + ' + String(t('detailPage.taskId'))}
                </div>
                <div className="b-body-value">{task.type + '-' + task.id}</div>
              </Col>
            </Row>
            <div className="b-body-param">{t('addEntity.task.parentItem')}</div>
            <div className="b-body-value">
              {task.parent_task_path && task.parent_task_path.length ? (
                <Link to={`/tasks/${task.parent_task_path[task.parent_task_path.length - 1].id}`}>
                  {task.parent_task_path[task.parent_task_path.length - 1].name}
                </Link>
              ) : (
                t('common.no')
              )}
            </div>
            <div className="b-body-param">{t('filter.assignee')}</div>
            <div className="b-body-value">{task.assigned_to?.name}</div>
            <Row>
              <Col>
                <div className="b-body-param">{t('filter.due')}</div>
                <div className="b-body-value">{task.due_date}</div>
              </Col>
              <Col>
                <div className="b-body-param">
                  {t('addEntity.task.estimatedTime') + ', ' + t('common.hours')}
                </div>
                <div className="b-body-value">
                  {task.estimated_time.time === '0:00' ? '--' : task.estimated_time.time}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="b-body-param">{t('filter.billing')}</div>
                <div className="b-body-value">
                  {t(
                    billings.find((billing) => Number(billing.value) === task.billing)?.name ?? ''
                  )}
                </div>
              </Col>
              <Col>
                <div className="b-body-param">
                  {t('detailPage.timeWorked') + ', ' + t('common.hours')}
                </div>
                <div className="b-body-value time">
                  <p> {task.actual_time}</p>
                  <PermissionsGate permissions={[Permissions.add_time]}>
                    <div className="actual-time-image detail">
                      <TimeEntry taskId={task.id} onSave={onSaveTimeReport}>
                        <img className="actual-time-image-size" src={timeImage} alt="" />
                      </TimeEntry>
                    </div>
                  </PermissionsGate>
                </div>
              </Col>
            </Row>
            <div className="b-body-param">{t('addEntity.task.customerVisible')}</div>
            <div className="b-body-value">{task.published ? t('common.no') : t('common.yes')}</div>
          </div>
          <div className="b-body-right">
            <div className="b-body-param">{t('textEditor.label')}</div>
            <div className="b-body-value b-body-description">
              {task.description === null ? '' : parse(task.description)}
            </div>
            <div className="b-body-param">{t('fileUpload.label')}</div>
            <FileList files={task.taskFiles} />
          </div>
        </div>
      </div>
    );
};
