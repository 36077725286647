import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { Button, Form } from 'react-bootstrap';
import {
  clearSearch,
  fetchCompanies,
  fetchUsers,
  setSearchQuery,
} from '../../../../store/slices/admin/companiesAndUsers';
import styles from './style.module.scss';

const SearchByCompaniesAndUsers = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const { loading, groupByCompany, searchQuery } = useAppSelector(
    (state) => state.companiesAndUsers
  );

  return (
    <div className={styles.container}>
      <Form.Control
        className={styles.search}
        type="text"
        placeholder={
          groupByCompany ? t('companies.searchPlaceholder') : t('users.searchPlaceholder')
        }
        disabled={loading}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(setSearchQuery(e.target.value));
        }}
        value={searchQuery}
      />
      <Button
        disabled={loading || !searchQuery}
        onClick={() => {
          if (groupByCompany) {
            dispatch(fetchCompanies(false));
          } else {
            dispatch(fetchUsers(false));
          }
        }}
      >
        {t('common.search')}
      </Button>
      <Button
        disabled={loading}
        variant="outline-secondary"
        onClick={() => {
          dispatch(clearSearch());
          if (groupByCompany) {
            dispatch(fetchCompanies(false));
          } else {
            dispatch(fetchUsers(false));
          }
        }}
      >
        {t('common.reset')}
      </Button>
    </div>
  );
};

export default SearchByCompaniesAndUsers;
