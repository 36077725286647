import React from 'react';
import './style.css';
import remove_subscriber from '../../../../../assets/images/icons/remove_subscriber.svg';
import circle from '../../../../../assets/images/icons/circle.svg';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { IChangeSubscriber } from '../../../../../services/api/endpoints/task';
import { changeSubscriberAsync } from '../../../../../store/slices/task/comments';

interface SubscriberCardProps {
  status: number;
  userId: number;
  name: string;
  published: string;
}

export const SubScriberCard: React.FC<SubscriberCardProps> = ({
  status,
  name,
  userId,
  published,
}) => {
  const dispatch = useAppDispatch();
  const { task } = useAppSelector((state) => state.task);

  const onChangeStatusSubscriber = (userId: number) => {
    if (!task) return;

    const params: IChangeSubscriber = {
      task_id: task.id,
      published: published,
      status: status === 1 ? 0 : 1,
      user_id: userId,
    };

    dispatch(changeSubscriberAsync(params));
  };

  return (
    <div
      className={`subscriber-card ${
        status !== undefined && status === 0 ? 'subscriber-card_topic' : ''
      }`}
    >
      <div className="subscriber-card-row">
        <p
          className={`subscriber-card-name ${
            status !== undefined && status === 0 ? 'subscriber-card-name_topic' : ''
          }`}
        >
          {name}
        </p>
      </div>
      <img
        src={remove_subscriber}
        className="subscriber-card-delete"
        onClick={() => onChangeStatusSubscriber(userId)}
        alt=""
      />
      <img
        alt=""
        src={status === 1 ? remove_subscriber : circle}
        onClick={() => onChangeStatusSubscriber(userId)}
        className={`subscriber-card-icon ${
          status !== undefined && status === 0 ? 'subscriber-card-icon_topic' : ''
        }`}
      />
      <p className="subscriber-card-post"></p>
    </div>
  );
};
