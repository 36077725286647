import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFilterParam } from '../../../store/slices/tasks';
import { taskTypes } from '../../../utils/constants';
import { ISelectOptions } from '../../../types';
import Select from 'react-select';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface FilterProps {}

const TypeFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { type } = useAppSelector((state) => state.tasks.filter);

  const anyType: ISelectOptions<string> = {
    name: 'taskTypes.anyType',
    value: '',
  };
  const typesList: ISelectOptions<string>[] = [anyType, ...taskTypes];

  const [currentType, setCurrentType] = useState<ISelectOptions<string> | null>(anyType);

  useEffect(() => {
    if (type) {
      const typeCheck = typesList.find((item) => item.value === type);
      if (typeCheck) {
        setCurrentType(typeCheck);
      }
    }
  }, []);

  const onChange = (option: ISelectOptions<string> | null) => {
    if (option) {
      dispatch(changeFilterParam({ type: option.value }));
    }
  };

  return (
    <div className="b-select-light">
      <Select
        className={styles.selectType}
        options={typesList}
        value={currentType}
        getOptionLabel={(option) => t(option.name)}
        onChange={(option) => {
          setCurrentType(option);
          onChange(option);
        }}
        isSearchable={false}
        classNamePrefix="custom-select"
      />
    </div>
  );
};

export default TypeFilter;
