import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import deleteImage from '../../../assets/images/icons/wastebasket.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeCurrentTab } from '../../../store/slices/task';
import downloadDark from '../../../assets/images/icons/download-dark.svg';
import './style.scss';
import Preloader from '../../preloader';
import { DetailTabsEnum } from '../../../utils/constants';
import PreviewFile from '../../previewFile';
import { onDownloadFile } from '../../../utils/helpers';
import {
  deleteFilesAsync,
  fetchFilesAsync,
  toggleCheckedAllFiles,
  toggleFileChecked,
} from '../../../store/slices/task/files';

export const FilesTab: React.FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { files, loading: loadingFiles } = useAppSelector((state) => state.taskFiles);

  const [allChecked, setAllChecked] = useState(false);

  const hasCheckedFiles = () => {
    return files.some((item) => item.checked);
  };

  useEffect(() => {
    dispatch(fetchFilesAsync());
  }, []);

  useEffect(() => {
    setAllChecked(files.every((item) => item.checked));
  }, [files]);

  const onDeleteFiles = async () => {
    const filenames = files.filter((file) => file.checked).map((file) => `${file.name}`);

    if (window.confirm(`${t('filesTable.confirm')}\n\n${filenames.join('\n')}`)) {
      dispatch(deleteFilesAsync());
    }
  };

  const goToPost = (postId: number | null, published: string | null) => {
    if (postId === null || published === null) return;

    if (Number(published) === 1) {
      dispatch(changeCurrentTab(DetailTabsEnum.commentTeam));
    } else {
      dispatch(changeCurrentTab(DetailTabsEnum.commentCustomer));
    }

    setTimeout(() => {
      const card = document.querySelector(`.comment-card[data-id="${postId}"]`);
      if (card) {
        card.classList.add('highlight');
        card.scrollIntoView({ block: 'center' });

        setTimeout(() => {
          card.classList.remove('highlight');
        }, 1500);
      }
    }, 50);
  };

  return (
    <div className="files-tab">
      <div className="files-header">
        {loadingFiles ? (
          <Preloader />
        ) : (
          <img
            className={`delete-button ${hasCheckedFiles() ? 'active' : ''}`}
            src={deleteImage}
            alt="Удалить"
            onClick={onDeleteFiles}
          />
        )}
      </div>

      {!loadingFiles ? (
        <div className="table-wrapper">
          <table className="table-custom">
            <tr>
              <th className="checkbox">
                <div className="title">
                  <input
                    type="checkbox"
                    checked={allChecked}
                    onChange={() => {
                      setAllChecked(!allChecked);
                      dispatch(toggleCheckedAllFiles(!allChecked));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="title">{t('filesTable.type')}</div>
              </th>
              <th>
                <div className="title">{t('filesTable.name')}</div>
              </th>
              <th>
                <div className="title">{t('filesTable.attachedTo')}</div>
              </th>
              <th className="created">
                <div className="title">{t('filesTable.created')}</div>
              </th>
              <th className="visible">
                <div className="title">{t('filesTable.visible')}</div>
              </th>
              <th className="download"></th>
            </tr>
            {files.map((file) => (
              <tr key={file.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={file.checked}
                    onChange={() => {
                      dispatch(toggleFileChecked(file.id));
                    }}
                  />
                </td>
                <td>{file.extension}</td>
                <td>
                  <PreviewFile file={file} />
                </td>
                <td>
                  {file.post ? (
                    <p
                      className="link-to-post"
                      title={t('filesTable.goToPost')}
                      onClick={() =>
                        goToPost(
                          file.post ? file.post.id : null,
                          file.post ? file.post.published : null
                        )
                      }
                    >
                      {`${file.post.owner.name} | ${file.post.date}`}
                    </p>
                  ) : (
                    ''
                  )}
                </td>
                <td>{file.created}</td>
                <td>{Number(file.visible) === 0 ? t('common.yes') : t('common.no')}</td>
                <td>
                  <img
                    className="file-download"
                    src={downloadDark}
                    alt="Скачать"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDownloadFile(file.id, file.original_name);
                    }}
                  />
                </td>
              </tr>
            ))}
          </table>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
