import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFilterParam } from '../../../store/slices/tasks';
import { billings } from '../../../utils/constants';
import { ISelectOptions } from '../../../types';
import Select from 'react-select';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const ANY_BILLINGS_VALUE = '';

interface FilterProps {}

const BillingFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { billing } = useAppSelector((state) => state.tasks.filter);

  const anyBilling: ISelectOptions = {
    name: 'billings.anyBillingStatus',
    value: ANY_BILLINGS_VALUE,
  };

  const billingsList: ISelectOptions[] = [anyBilling, ...billings];
  const [currentBilling, setCurrentBilling] = useState<ISelectOptions | null>(anyBilling);

  const onChange = (billing: ISelectOptions | null) => {
    if (billing) {
      dispatch(changeFilterParam({ billing: billing.value }));
    }
  };

  useEffect(() => {
    if (billing) {
      const billingCheck = billingsList.find((item) => item.value === billing);
      if (billingCheck) {
        setCurrentBilling(billingCheck);
      }
    }
  }, []);

  return (
    <div className="b-select-light">
      <Select
        className={styles.select}
        options={billingsList}
        value={currentBilling}
        getOptionLabel={(option) => t(option.name)}
        onChange={(option) => {
          setCurrentBilling(option);
          onChange(option);
        }}
        classNamePrefix="custom-select"
        isSearchable={false}
      />
    </div>
  );
};

export default BillingFilter;
