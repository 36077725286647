import { SortDirection } from '../../../types';
import { axios } from '../axios';

interface IActivityRequest {
  pagination?: {
    page?: number;
    count?: number;
  };
  sort?: {
    [key: string]: SortDirection;
  };
  filter?: {};
}

const endpoints = {
  getActivity: (data: IActivityRequest) =>
    axios.get('/activity', {
      params: data,
    }),
};

export default endpoints;
