import { Navigate, Route, Routes } from 'react-router';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Layout from './pages/layout/layout';
import Main from './pages/main/main';
import Login from './pages/login/login';
import PrivateRoute from './pages/privateRoute/privateRoute';
import Task from './pages/task/task';
import NotFound from './pages/notFound/notFound';
import TimeReport from './pages/timeReport/timeReport';
import Projects from './pages/projects/projects';
import Project from './pages/project';
import Admin from './pages/admin';
import PermissionsGate from './components/permissionsGate';
import { Permissions } from './types/permissions';

function App() {
  const { t } = useTranslation('common');

  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/tasks/" />} />
        <Route
          path="tasks"
          element={
            <PrivateRoute>
              <Main />
            </PrivateRoute>
          }
        />
        <Route
          path="tasks/:id"
          element={
            <PrivateRoute>
              <Task />
            </PrivateRoute>
          }
        />
        <Route
          path="projects"
          element={
            <PrivateRoute>
              <Projects />
            </PrivateRoute>
          }
        />
        <Route
          path="projects/:id"
          element={
            <PrivateRoute>
              <Project />
            </PrivateRoute>
          }
        />
        <Route
          path="time"
          element={
            <PrivateRoute>
              <PermissionsGate
                permissions={[Permissions.view_time_report]}
                errorContent={<Alert variant={'danger'}>{t('common.accessDenied')}</Alert>}
              >
                <TimeReport />
              </PermissionsGate>
            </PrivateRoute>
          }
        />
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <PermissionsGate
                permissions={[
                  Permissions.view_permissions,
                  Permissions.view_companies,
                  Permissions.view_users,
                ]}
                checkingAllPermissions={false}
                errorContent={<Alert variant={'danger'}>{t('common.accessDenied')}</Alert>}
              >
                <Admin />
              </PermissionsGate>
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
