export enum CompanyFormFields {
  name = 'name',
  address = 'address',
  phone = 'phone',
  url = 'url',
  email = 'email',
  hourly_rate = 'hourly_rate',
}

export type ICompanyFormFields = {
  [CompanyFormFields.name]: string;
  [CompanyFormFields.address]: string;
  [CompanyFormFields.phone]: string;
  [CompanyFormFields.url]: string;
  [CompanyFormFields.email]: string;
  [CompanyFormFields.hourly_rate]: string;
};
