import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Card from './card';
import List from './list';
import './style.css';
import { statuses } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { fetchKanbanTasksAsync } from '../../store/slices/tasks';
import Preloader from '../preloader';

interface KanbanProps {}

const Kanban: React.FC<KanbanProps> = () => {
  const { t } = useTranslation('common');
  const { kanban, filter } = useAppSelector((state) => state.tasks);
  const dispatch = useAppDispatch();
  const kanbanRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (filter.project) {
      dispatch(fetchKanbanTasksAsync());
    }
  }, [filter]);

  useEffect(() => {
    if (kanban.filter) {
      dispatch(fetchKanbanTasksAsync());
    }
  }, [kanban.filter.milestone]);

  return (
    <>
      {kanban.loading ? (
        <Preloader></Preloader>
      ) : (
        <div className="b-kanban-wrapper">
          {/*    <ArrowScroll kanbanRef={kanbanRef} direction={Direction.Left}></ArrowScroll>*/}
          <div className="b-kanban" ref={kanbanRef}>
            <div className="b-kanban-headers">
              {statuses.map((status) => {
                return (
                  <div
                    className="header-status"
                    style={{ color: status.color, borderColor: status.borderColor }}
                  >
                    {t(status.name)} (
                    {
                      kanban.tasks.filter(
                        (item) =>
                          item.status === +status.value ||
                          (item.status === 5 && +status.value === 2) ||
                          (item.status === 10 && +status.value === 2)
                      ).length
                    }
                    )
                  </div>
                );
              })}
            </div>
            <div className="b-kanban-main">
              {statuses.map((status) => {
                return (
                  <List
                    status={status}
                    tasks={kanban.tasks
                      .filter(
                        (item) =>
                          item.status === +status.value ||
                          (item.status === 5 && +status.value === 2) ||
                          (item.status === 10 && +status.value === 2)
                      )
                      .map((item) => (
                        <Card key={item.id} task={item} />
                      ))}
                  />
                );
              })}
            </div>
            {/*    <div className="b-kanban-footer">
              {statuses.map((status) => {
                return (
                  <div
                    className="b-kanban-footer-elem"
                    style={{ backgroundColor: status.color }}
                  ></div>
                );
              })}
            </div> */}
            {/* <ArrowScroll kanbanRef={kanbanRef} direction={Direction.Right}></ArrowScroll>*/}
          </div>
        </div>
      )}
    </>
  );
};

export default Kanban;
