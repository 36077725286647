import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import api from '../../../../services/api';
import './style.css';
import {
  changeModalEdit,
  changeModalParams,
  changeMultiplyWorkedTime,
  changeWorkedTime,
  getEditIdWorkedTime,
  getTasks,
} from '../../../../store/slices/task/timeReports';
import { useGetCheckedIds, useGetDate } from '../../../../utils/helpers';
import {
  IEditWorkedTime,
  IMultyplyEditWorkedTime,
} from '../../../../store/slices/task/timeReports/types';
import { IProjectTask } from '../../../../store/slices/task/types';
import TaskMultiplyEditionTransfer from './TaskMultiplyEditionTransfer';
import TaskSingleEditionTransfer from './TaskSingleEditionTransfer';
import { useDefaultTasks } from '../../../../utils/helpers/defaultTasks';
import { ITasksResponse } from '../../../../store/slices/tasks/types';

interface TaskTimeEditionProps {}

const TaskTimeEdition: React.FC<TaskTimeEditionProps> = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { modalParams, checkedId } = useAppSelector((state) => state.taskTime);
  const { task } = useAppSelector((state) => state.task);

  const defaultTasks = useDefaultTasks();

  const [firstLevelTask, setFirstLevelTask] = useState<IProjectTask | null>(defaultTasks[0]);
  const [secondLevelTask, setSecondLevelTask] = useState<IProjectTask | null>(defaultTasks[1]);
  const [thirdLevelTask, setThirdLevelTask] = useState<IProjectTask | null>(defaultTasks[2]);

  const checkedIds = useGetCheckedIds();
  const date = useGetDate();

  const getTaskTransferLocalation = () => {
    if (task?.id) {
      return thirdLevelTask
        ? thirdLevelTask
        : secondLevelTask
        ? secondLevelTask
        : firstLevelTask
        ? firstLevelTask
        : task;
    }
  };

  const formHandler = async () => {
    if (task?.id) {
      let id;
      const taskTransferLocalation = getTaskTransferLocalation();
      const getChild = () => {
        return (
          task.id === firstLevelTask?.id ||
          task.id === secondLevelTask?.id ||
          task.id === thirdLevelTask?.id
        );
      };
      if (checkedIds?.length === 1) {
        id = checkedIds[0];
      } else if (checkedId) {
        id = checkedId;
      }

      if (modalParams.time === '0:0' || modalParams.comment.trim() === '') {
        alert(t('timeTask.error'));
        return;
      }

      if (id && modalParams.startDate && date && taskTransferLocalation) {
        await api.timeReports.editWorkedTime(id, {
          task_id: taskTransferLocalation.id,
          date: date,
          time: modalParams.time,
          comment: modalParams.comment,
          hour_type: '0',
        });
        const editTime: IEditWorkedTime = {
          idWorkedTime: id,
          isChild: getChild(),
          time: modalParams.time,
          comment: modalParams.comment,
          startDate: modalParams.startDate,
        };
        dispatch(changeWorkedTime(editTime));
      }

      if (
        checkedIds &&
        checkedIds?.length > 1 &&
        modalParams.startDate &&
        date &&
        taskTransferLocalation
      ) {
        await api.timeReports.editMultiplyWorkedTime({
          ids: checkedIds,
          task_id: taskTransferLocalation.id,
          date: date,
        });
        const editMultyplyTime: IMultyplyEditWorkedTime = {
          ids: checkedIds,
          isChild: getChild(),
          startDate: modalParams.startDate,
        };
        dispatch(changeMultiplyWorkedTime(editMultyplyTime));
      }
    }
  };

  const closeHandler = () => {
    dispatch(changeModalEdit(false));
    dispatch(
      changeModalParams({
        startDate: undefined,
        data: [],
        hrs: '0',
        mins: '0',
        time: '0:0',
        comment: '',
      })
    );
    dispatch(getEditIdWorkedTime(null));
  };

  const doneHandler = () => {
    formHandler();
    dispatch(changeModalEdit(false));
    dispatch(getEditIdWorkedTime(null));
    dispatch(
      changeModalParams({
        startDate: undefined,
        data: [],
        hrs: '0',
        mins: '0',
        time: '0:0',
        comment: '',
      })
    );
  };

  const getProjectTasks = async (project_id: number) => {
    const response = await api.task.list({
      filter: {
        project: String(project_id),
        status: '',
      },
    });
    const data: ITasksResponse = response.data;
    const newLocationTask = data.tasks
      .filter((item) => item.project_id === Number(task?.project.id))
      .map((item) => {
        return {
          id: item.id,
          name: item.name,
          value: item.id,
          parent_task: item.parent_task,
        };
      });
    if (newLocationTask.length) {
      dispatch(getTasks(newLocationTask));
    }
  };

  useEffect(() => {
    let currWorkedTime = modalParams.data.find((el) => el.checked === true);
    if (currWorkedTime) {
      dispatch(changeModalParams({ ...modalParams, startDate: new Date(currWorkedTime.date) }));
      dispatch(changeModalParams({ ...modalParams, hrs: currWorkedTime.hours.split(':')[0] }));
      dispatch(changeModalParams({ ...modalParams, mins: currWorkedTime.hours.split(':')[1] }));
      dispatch(changeModalParams({ ...modalParams, comment: currWorkedTime.comments }));
    } else if (checkedId) {
      currWorkedTime = modalParams.data.find((el) => checkedId === el.id);
      if (currWorkedTime) {
        dispatch(changeModalParams({ ...modalParams, startDate: new Date(currWorkedTime.date) }));
        dispatch(changeModalParams({ ...modalParams, hrs: currWorkedTime.hours.split(':')[0] }));
        dispatch(changeModalParams({ ...modalParams, mins: currWorkedTime.hours.split(':')[1] }));
        dispatch(changeModalParams({ ...modalParams, comment: currWorkedTime.comments }));
      }
    }
  }, [checkedId, modalParams?.data]);

  useEffect(() => {
    dispatch(changeModalParams({ ...modalParams, time: modalParams.hrs + ':' + modalParams.mins }));
  }, [modalParams?.hrs, modalParams?.mins]);

  useEffect(() => {
    if (task?.project.id) {
      getProjectTasks(task.project.id);
    }
  }, []);

  return (
    <form onSubmit={formHandler}>
      {checkedIds?.length === 1 || checkedId ? (
        <TaskSingleEditionTransfer
          firstLevelTask={firstLevelTask}
          secondLevelTask={secondLevelTask}
          thirdLevelTask={thirdLevelTask}
          setFirstLevelTask={setFirstLevelTask}
          setSecondLevelTask={setSecondLevelTask}
          setThirdLevelTask={setThirdLevelTask}
        />
      ) : (
        <TaskMultiplyEditionTransfer
          firstLevelTask={firstLevelTask}
          secondLevelTask={secondLevelTask}
          thirdLevelTask={thirdLevelTask}
          setFirstLevelTask={setFirstLevelTask}
          setSecondLevelTask={setSecondLevelTask}
          setThirdLevelTask={setThirdLevelTask}
        />
      )}
      <div>
        <Button type="submit" className="save" onClick={() => doneHandler()}>
          {t('timeTask.btnSave')}
        </Button>
        <Button className="cancel" onClick={() => closeHandler()}>
          {t('timeTask.btnCancel')}
        </Button>
      </div>
    </form>
  );
};

export default TaskTimeEdition;
