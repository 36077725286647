import { Tab, Tabs } from 'react-bootstrap';
import TabContent from '../../tabContent';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { DetailTabsProjectEnum } from '../../../utils/constants';

interface DetailProjectTabsProps {}

export const DetailProjectTabs: React.FC<DetailProjectTabsProps> = () => {
  const { t } = useTranslation('common');
  const { currentTab } = useAppSelector((state) => state.project);
  const dispatch = useAppDispatch();

  return (
    <div className="detailTaskTabs">
      {/* <Tabs
        onSelect={(tab) => dispatch(changeCurrentTab(tab as DetailTabsProjectEnum))}
        activeKey={currentTab}
      >
        <Tab
          eventKey={DetailTabsProjectEnum.commentTeam}
          title={
            <TabContent
              text={`${t('detailTabs.CommTeam')}  (` + teamComments.comments.length + `)`}
              loading={teamComments.loading}
            />
          }
        >
          <CommentsSection published="1" />
        </Tab>
        <Tab
          eventKey={DetailTabsEnum.commentCustomer}
          title={
            <TabContent
              text={`${t('detailTabs.Comments')} (` + customerComments.comments.length + `)`}
              loading={customerComments.loading}
            />
          }
        >
          <CommentsSection published="0" />
        </Tab>
        <Tab
          eventKey={DetailTabsEnum.files}
          title={
            <TabContent
              text={`${t('detailTabs.Files')} (${files.length})`}
              loading={loadingFiles}
            />
          }
        >
          <FilesTab />
        </Tab>
        <Tab
          eventKey={DetailTabsEnum.time}
          title={
            <TabContent
              text={`${t('detailTabs.Time')} (${paginationTime ? paginationTime.total : 0})`}
              loading={loadingTimeReports}
            />
          }
        >
          <TimeTab />
        </Tab>
        <Tab
          eventKey={DetailTabsEnum.activity}
          title={
            <TabContent
              text={`${t('detailTabs.Activity')} (${
                paginationActivity ? paginationActivity.total : 0
              })`}
              loading={loadingActivities}
            />
          }
        >
          <ActivityTab />
        </Tab>
      </Tabs> */}
      Табы
    </div>
  );
};
