import React, { useState } from 'react';
import '../style.css';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { ISelectOptions } from '../../../../types';
import Select from 'react-select';
import { changeFilterParams } from '../../../../store/slices/timeReportsTab';
import { IUser } from '../../../../store/slices/users/types';
import { getValidSelectOption, getValidSelectOptions } from '../../../../utils/helpers/selects';

const ANY_USER = -1;

interface FilterProps {}

export const TimeReportsUserFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { users, loading } = useAppSelector((state) => state.users);
  const { t } = useTranslation('common');
  const anyUser: IUser = {
    id: ANY_USER,
    name: 'filter.anyAssignee',
  };
  const getValidSelectOptionUser = getValidSelectOption(anyUser);
  const userList = [getValidSelectOptionUser, ...getValidSelectOptions(users)];
  const [currentUser, setCurrentUser] = useState<ISelectOptions | null>(getValidSelectOptionUser);

  const handleUserFilterChange = (option: ISelectOptions | null) => {
    if (option) {
      dispatch(changeFilterParams({ user: option.value === -1 ? '' : option.value }));
    }
  };

  return (
    <div className="b-select-light">
      <Select
        className="b-timereport-select"
        options={userList}
        value={currentUser}
        getOptionLabel={(option) => (option.value === ANY_USER ? t(option.name) : option.name)}
        onChange={(option) => {
          setCurrentUser(option);
          handleUserFilterChange(option);
        }}
        classNamePrefix="custom-select"
        isLoading={loading}
      />
    </div>
  );
};
