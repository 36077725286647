import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  toggleSprintOpen,
  toggleSprintChecked,
  toggleProjectChecked,
  toggleCheckedAllSprintTasks,
  deleteSprintAsync,
} from '../../store/slices/tasks';
import { ISprint } from '../../store/slices/tasks/types';
import './style.css';
import arrowDownImage from '../../assets/images/icons/arrow-down.svg';
import arrowUpImage from '../../assets/images/icons/arrow-up.svg';
import { formattedSprintDates } from '../../utils/helpers';
import PermissionsGate from '../permissionsGate';
import { Permissions } from '../../types/permissions';
import wastebasketImage from '../../assets/images/icons/wastebasket.svg';
import editImage from '../../assets/images/icons/edit.svg';
import SprintForm from '../forms/sprintForm';
import { useTranslation } from 'react-i18next';

interface SprintsProps {
  sprint: ISprint;
}

const SprintRow: React.FC<SprintsProps> = ({ sprint }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.tasks);
  const { sprints, projects } = data;

  const [modalSprintShow, setModalSprintShow] = useState(false);
  const [pending, setPending] = useState(false);

  const deleteSprint = async () => {
    if (window.confirm(`Вы действительно хотите удалить спринт "${sprint.name}"?`)) {
      setPending(true);
      await dispatch(deleteSprintAsync(sprint));
      setPending(false);
    }
  };

  const closeModal = () => setModalSprintShow(false);
  const showModal = () => setModalSprintShow(true);

  const modal = (
    <Modal size="lg" centered show={modalSprintShow} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t('addEntity.sprint.edit')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SprintForm closeModal={closeModal} sprint={sprint} />
      </Modal.Body>
    </Modal>
  );

  return (
    <div className={`tr sprint ${sprint.isChecked ? 'checked' : ''}`}>
      <div className="td" style={{ flexGrow: 0, flexBasis: '90px' }}>
        <Form.Check
          className="cell-checkbox"
          aria-label={`checkbox-${sprint.id}`}
          checked={sprint.isChecked}
          onChange={(e) => {
            const parentProject = projects.find((p) => p.id === sprint.project_id);
            // if (sprint.id === -1) { // TODO: доделать
            //   dispatch(toggleCheckedAllTasks({ value: e.target.checked }));
            // } else {

            // }
            dispatch(toggleSprintChecked({ id: sprint.id, value: e.target.checked }));
            dispatch(toggleCheckedAllSprintTasks({ id: sprint.id }));
            if (parentProject && !e.target.checked) {
              dispatch(toggleProjectChecked({ id: parentProject.id, value: false }));
            }

            if (e.target.checked) {
              let allSprintsChecked = true;
              sprints
                .filter((s) => s.project_id === sprint.project_id)
                .forEach((s) => {
                  if (s.id !== sprint.id) {
                    if (!s.isChecked) {
                      //BUG: багает, если есть спринты без задач
                      allSprintsChecked = false;
                    }
                  }
                });
              if (allSprintsChecked) {
                dispatch(toggleProjectChecked({ id: sprint.project_id, value: true }));
              }
            }
          }}
        />
      </div>
      <div className="td">
        <div
          className="btn-open btn-row"
          onClick={() => {
            dispatch(toggleSprintOpen({ id: sprint.id }));
          }}
        >
          <img src={sprint.isMinimised ? arrowDownImage : arrowUpImage} alt="" />
        </div>
        {`${sprint.name} ${formattedSprintDates(sprint.start_date, sprint.due_date)}`}

        {!pending && !sprint.isUnsorted ? (
          <>
            <PermissionsGate permissions={[Permissions.update_milestones]}>
              <div className="sprint-btn-edit" onClick={showModal}>
                <img className="sprint" src={editImage} alt="" />
              </div>
            </PermissionsGate>
            <PermissionsGate permissions={[Permissions.delete_milestones]}>
              <div className="sprint-btn-delete" onClick={deleteSprint}>
                <img src={wastebasketImage} alt="" />
              </div>
            </PermissionsGate>
          </>
        ) : null}

        {modal}
      </div>
    </div>
  );
};

export default SprintRow;
