import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeKanbanFilterParam } from '../../../store/slices/tasks';
import { ISelectOptions } from '../../../types';
import Select from 'react-select';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const ANY_SPRINT = '-1';
const NO_SPRINT = '-2';

interface FilterProps {}

const SprintFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { data, loading, filter } = useAppSelector((state) => state.tasks);
  const { sprints } = data;
  const anySprint: ISelectOptions = {
    name: 'filter.anySprint',
    value: ANY_SPRINT,
  };
  const noSprint: ISelectOptions = {
    name: 'filter.noSprint',
    value: NO_SPRINT,
  };
  const [selectedSprint, setSelectedSprint] = useState<ISelectOptions | null>(anySprint);

  const handleSprintFilterChange = (sprint: ISelectOptions | null) => {
    if (sprint) {
      if (String(sprint.value) === '-1') {
        dispatch(changeKanbanFilterParam({ milestone: '' }));
      } else if (String(sprint.value) === '-2') {
        dispatch(changeKanbanFilterParam({ milestone: 'null' }));
      } else {
        dispatch(changeKanbanFilterParam({ milestone: String(sprint.value) }));
      }
    }
  };

  const getSelectOptionList = () => {
    return sprints
      .filter((item) => item.project_id === Number(filter.project) && !item.isUnsorted)
      .map((item) => {
        return {
          ...item,
          value: String(item.id),
        };
      });
  };

  const sprintsList: ISelectOptions[] = [anySprint, noSprint, ...getSelectOptionList()];

  return (
    <div className="b-select-light">
      <Select
        className={styles.select}
        options={sprintsList}
        value={selectedSprint}
        getOptionLabel={(option) =>
          option.value === anySprint.value || option.value === noSprint.value
            ? t(option.name)
            : option.name
        }
        onChange={(option) => {
          setSelectedSprint(option);
          handleSprintFilterChange(option);
        }}
        classNamePrefix="custom-select"
        isLoading={loading.projects}
      />
    </div>
  );
};

export default SprintFilter;
