import axiosPackage, { AxiosError, AxiosRequestConfig } from 'axios';

import Qs from 'qs';
import { resetTokenAndReattemptRequest } from './helpers/resetTokenAndReattemptRequest';

interface AxiosRequestConfigWithRetry extends AxiosRequestConfig {
  _isRetry?: boolean;
}

export const axios = axiosPackage.create({
  baseURL: process.env.REACT_APP_URL_API,
  withCredentials: true,
});

axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    // Qs is not included in the Axios package
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false,
    });
  };

  return config;
});

axios.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (config.headers === undefined) {
      config.headers = {};
    }
    const token = localStorage.getItem('token');
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error: AxiosError) => {
    const originalRequest: AxiosRequestConfigWithRetry = error.config;
    if (error.response?.status === 401 && error.config && !originalRequest._isRetry) {
      originalRequest._isRetry = true;
      try {
        return await resetTokenAndReattemptRequest(error);
      } catch (e) {
        localStorage.removeItem('token');
        window.location.reload(); // не лучший, но единственный вариант, поскольку здесь нельзя использовать useNavigate
      }
    }
    return Promise.reject(error);
  }
);
