import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFilterParam } from '../../../store/slices/tasks';
import { useTranslation } from 'react-i18next';
import { ISelectOptions } from '../../../types';
import Select from 'react-select';
import styles from './styles.module.scss';
import { getValidSelectOption, getValidSelectOptions } from '../../../utils/helpers/selects';
import { IUser } from '../../../store/slices/users/types';

const ANY_AUTHOR_VALUE = -1;

interface FilterProps {}

const AuthorFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { users, loading } = useAppSelector((state) => state.users);
  const { owner } = useAppSelector((state) => state.tasks.filter);
  const { t } = useTranslation('common');
  const anyAuthor: IUser = {
    id: ANY_AUTHOR_VALUE,
    name: 'filter.anyAuthors',
  };
  const validSelectOptionUser = getValidSelectOption(anyAuthor);
  const [currentAuthor, setCurrentAuthor] = useState<ISelectOptions | null>(validSelectOptionUser);

  const authorList: ISelectOptions[] = [validSelectOptionUser, ...getValidSelectOptions(users)];

  useEffect(() => {
    if (owner) {
      const authorItem = authorList.find((item) => +item.value === +owner);
      if (authorItem) {
        setCurrentAuthor(authorItem);
      }
    }
  }, []);

  const onChange = (option: ISelectOptions | null) => {
    if (option) {
      dispatch(changeFilterParam({ owner: option.value === ANY_AUTHOR_VALUE ? '' : option.value }));
    }
  };

  return (
    <div className="b-select-light">
      <Select
        className={styles.select}
        options={authorList}
        value={currentAuthor}
        getOptionLabel={(option) =>
          option.value === ANY_AUTHOR_VALUE ? t(option.name) : option.name
        }
        onChange={(option) => {
          setCurrentAuthor(option);
          onChange(option);
        }}
        classNamePrefix="custom-select"
        isLoading={loading}
      />
    </div>
  );
};

export default AuthorFilter;
