import i18n from 'i18next';
import common_ru from './locales/ru.json';
import common_en from './locales/en.json';

i18n.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('lang') || 'ru',
  resources: {
    en: {
      common: common_en,
    },
    ru: {
      common: common_ru,
    },
  },
});

export default i18n;
