import React, { useState } from 'react';

export const useAuth = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const saveToken = (token: string) => {
    localStorage.setItem('token', token);
    setToken(token);
  };
  const removeToken = () => {
    localStorage.removeItem('token');
    setToken(null);
  };
  return {
    token,
    setToken: saveToken,
    removeToken,
  };
};
