import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  changePage,
  changePaginationCount,
  clearSearch,
  fetchCompanies,
  fetchUsers,
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPrevPage,
  switchGroup,
} from '../../../store/slices/admin/companiesAndUsers';
import Preloader from '../../preloader';
import Pagination from '../../pagination';
import CountPerPage from '../../countPerPage';
import { Button, Form, Modal } from 'react-bootstrap';
import CompaniesTable from './companiesTable';
import UsersTable from './usersTable';
import SearchByCompaniesAndUsers from './searchByCompaniesAndUsers';
import CompanyForm from '../../forms/companyForm';
import UserForm from '../../forms/userForm';
import { useTranslation } from 'react-i18next';

interface CompaniesProps {}

const Companies: React.FC<CompaniesProps> = () => {
  const { t } = useTranslation('common');
  const { companies, users, loading, pagination, countPerPage, groupByCompany } = useAppSelector(
    (state) => state.companiesAndUsers
  );
  const dispatch = useAppDispatch();

  const [modalCompanyShow, setModalCompanyShow] = useState(false);
  const [modalUserShow, setModalUserShow] = useState(false);
  const [editableProjectID, setEditableProjectID] = useState<number | null>(null);
  const [editableUserID, setEditableUserID] = useState<number | null>(null);

  useEffect(() => {
    dispatch(fetchCompanies(false));
  }, []);

  const fetchData = (isPagination: boolean) => {
    if (groupByCompany) {
      dispatch(fetchCompanies(isPagination));
    } else {
      dispatch(fetchUsers(isPagination));
    }
  };

  const closeModalCompany = () => {
    setModalCompanyShow(false);
    setEditableProjectID(null);
  };
  const showModalCompany = () => setModalCompanyShow(true);

  const closeModalUser = () => {
    setModalUserShow(false);
    setEditableUserID(null);
  };
  const showModalUser = () => setModalUserShow(true);

  useEffect(() => {
    if (editableProjectID) {
      showModalCompany();
    }
  }, [editableProjectID]);

  useEffect(() => {
    if (editableUserID) {
      showModalUser();
    }
  }, [editableUserID]);

  const modalCompany = (
    <Modal size="lg" centered show={modalCompanyShow} onHide={closeModalCompany}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editableProjectID ? t('companies.title.edit') : t('companies.title.create')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CompanyForm projectId={editableProjectID} closeModal={closeModalCompany} />
      </Modal.Body>
    </Modal>
  );

  const modalUser = (
    <Modal size="lg" centered show={modalUserShow} onHide={closeModalUser}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editableUserID ? t('users.title.edit') : t('users.title.create')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserForm userId={editableUserID} closeModal={closeModalUser} />
      </Modal.Body>
    </Modal>
  );

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <Button onClick={showModalCompany}>{t('companies.title.create')}</Button>
        <Button onClick={showModalUser}>{t('users.title.create')}</Button>
        {modalCompany}
        {modalUser}
      </div>

      <div className={styles.filters}>
        <Form.Check
          className={styles.groupByCompany}
          checked={groupByCompany}
          type="checkbox"
          id="groupByCompany"
          label={t('companies.groupByCompany')}
          onChange={() => {
            dispatch(clearSearch());
            if (!groupByCompany) {
              dispatch(fetchCompanies(false));
            } else {
              dispatch(fetchUsers(false));
            }
            dispatch(switchGroup());
          }}
          disabled={loading}
        />

        <SearchByCompaniesAndUsers />
      </div>

      {loading ? (
        <Preloader></Preloader>
      ) : (
        <>
          {groupByCompany ? (
            <CompaniesTable
              editCompany={(projectId: number) => {
                setEditableProjectID(projectId);
              }}
              editUser={(userId: number) => {
                setEditableUserID(userId);
              }}
              companies={companies}
            />
          ) : (
            <UsersTable
              editUser={(userId: number) => {
                setEditableUserID(userId);
              }}
              users={users}
            />
          )}

          <div className={styles.pagination}>
            {pagination ? (
              <Pagination
                currentPage={pagination.page}
                pagesTotal={pagination.pages_total}
                onChangePage={(i: number) => {
                  dispatch(changePage(i));
                  fetchData(true);
                }}
                onGoToFirstPage={() => {
                  dispatch(goToFirstPage());
                  fetchData(true);
                }}
                onGoToPrevPage={() => {
                  dispatch(goToPrevPage());
                  fetchData(true);
                }}
                onGoToNextPage={() => {
                  dispatch(goToNextPage());
                  fetchData(true);
                }}
                onGoToLastPage={() => {
                  dispatch(goToLastPage());
                  fetchData(true);
                }}
              ></Pagination>
            ) : (
              <div></div>
            )}

            <CountPerPage
              countPerPage={countPerPage}
              onChange={(count: string) => {
                dispatch(changePaginationCount(count));
                fetchData(false);
              }}
            ></CountPerPage>
          </div>
        </>
      )}
    </div>
  );
};

export default Companies;
