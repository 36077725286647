import { axios } from '../axios';
import { ITaskTime } from '../../../store/slices/task/types';
import { IPagination, TimeReport } from './type';
export interface TypePagination {
  page: number;
  count: number;
}

export type TimeParamsFilterType = {
  filter: {
    project: number;
    date_enum: number;
    date: string;
    date_until: string;
    task_id: number;
    user: number;
  };
};

export interface TypeFilter {
  date?: string;
  date_until?: string;
  taskid?: string;
  user?: string;
  project?: number;
}

export type SetTimeEntryType = {
  id: number;
  date: string;
  time: string;
  comment: string;
};

export type TypeEditWorkedTime = {
  task_id?: number;
  date: string;
  time: string;
  comment: string;
  hour_type?: string;
};

export type TypeMultiplyEdit = {
  ids: number[];
  task_id: number | null;
  date?: string;
};

export interface TypeResponseFilter {
  pagination: TypePagination;
  data: ITaskTime[];
}

export interface ITimeReportParams {
  filter: {
    project?: number;
    date?: string;
    date_until?: string;
    taskid?: string;
    user?: string;
  };
  pagination: {
    page?: number;
    count?: number;
  };
}

export interface ITimeReportTaskParams {
  filter: {
    date?: string;
    date_enum?: number;
    date_until?: string;
    user?: number;
  };
  pagination: {
    page?: number;
    count?: number;
  } | null;
  // sort: {
  //   date: 'desc' | 'asc';
  // };
}

const endpoints = {
  getList: async (data: ITimeReportParams) => {
    const response = await axios.get<IPagination<TimeReport>>('time-report/list', {
      params: {
        filter: data.filter,
        pagination: data.pagination,
        sort: {
          date: 'desc',
        },
      },
    });
    return response.data;
  },
  getTaskTime: async (id: number, data: ITimeReportTaskParams) => {
    const response = await axios.get<IPagination<TimeReport>>('time-report/task/' + id, {
      params: {
        filter: data.filter,
        pagination: data.pagination,
        // sort: data.sort,
      },
    });
    return response.data;
  },
  setTimeEntry: (data: SetTimeEntryType) =>
    axios.post<TimeReport>('/time-report/task/' + data.id, data),
  deleteWorkedTime: (ids: number[]) =>
    axios.delete('/time-report/delete', {
      params: {
        ids: ids,
      },
    }),
  editWorkedTime: (id: number, data: TypeEditWorkedTime) =>
    axios.post('/time-report/update/' + id, data),
  editMultiplyWorkedTime: (data: TypeMultiplyEdit) =>
    axios.post('/time-report/update-multiple', data),
};

export default endpoints;
