import React, { useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import OutsideAlerter from '../outsideAlerter';
import cancelImage from '../../assets/images/icons/cancel.svg';
import searchImage from '../../assets/images/icons/search.svg';
import Preloader from '../preloader';
import { IEntity } from '../../types';

const UserChangePopover = (props: any) => {
  const [isAssigneeSearchActive, setIsAssigneeSearchActive] = useState(false);
  const assigneeSearchRef = useRef(document.createElement('input'));
  const [assigneeSearchValue, setAssigneeSearchValue] = useState('');
  const userSearchFilter = (data: IEntity[], searchData: string) => {
    if (searchData !== '') {
      return data.filter((v, i, a) => {
        return v.name.toLowerCase().indexOf(searchData.toLowerCase()) >= 0;
      });
    } else {
      return data;
    }
  };
  const radioListOfUsers = (data: IEntity[]) => {
    return data.map((userData: IEntity) => (
      <div className="form-check assignee-list-item" key={userData.id}>
        <div className="assignee-radio-button-container">
          <input
            className="assignee-radio-button form-check-input"
            type="radio"
            name="assigneeRadioButton"
            id={'assigneeRadioButton' + userData.id}
            defaultChecked={props.task.assigned_to?.id === userData.id}
            onClick={() => {
              props.handler(userData);
              setAssigneeSearchValue('');
            }}
          />
          <label
            className="form-check-label assignee-text"
            htmlFor={'assigneeRadioButton' + userData.id}
          >
            {userData.name}
          </label>
        </div>
      </div>
    ));
  };

  const assigneePopover = (
    <Popover
      className={
        props.arrowVisible ? 'assignee-popover-hidden assignee-popover' : 'assignee-popover'
      }
    >
      <OutsideAlerter
        handler={() => {
          props.setOpen(false);
          setAssigneeSearchValue('');
        }}
      >
        <Popover.Body className="assignee-popover-body">
          <div className="input-group assignee-search-box">
            <input
              ref={assigneeSearchRef}
              className="assignee-search"
              value={assigneeSearchValue}
              onFocus={() => {
                setIsAssigneeSearchActive(true);
              }}
              onBlur={() => {
                setIsAssigneeSearchActive(false);
              }}
              onChange={(e) => {
                setAssigneeSearchValue(e.target.value);
              }}
            />
            {
              <div className="right-search-field">
                {assigneeSearchValue !== '' && (
                  <div className="btn-cancel">
                    <img
                      src={cancelImage}
                      alt=""
                      onClick={() => {
                        assigneeSearchRef.current.focus();
                        setAssigneeSearchValue('');
                      }}
                    />
                  </div>
                )}
                {!isAssigneeSearchActive && assigneeSearchValue === '' && (
                  <div className="btn-search">
                    <img
                      src={searchImage}
                      alt=""
                      onClick={() => {
                        assigneeSearchRef.current.focus();
                      }}
                    />
                  </div>
                )}
              </div>
            }
          </div>
          <div>{props.preloaderVisible && <Preloader />}</div>
          <div className="assignee-list">
            {!props.preloaderVisible &&
              radioListOfUsers(userSearchFilter(props.userList, assigneeSearchValue))}
          </div>
        </Popover.Body>
      </OutsideAlerter>
    </Popover>
  );
  return (
    <OverlayTrigger show={props.isOpen} overlay={assigneePopover} placement="bottom">
      {props.children}
    </OverlayTrigger>
  );
};
export default UserChangePopover;
