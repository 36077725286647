import { changeModalParams } from '../../../../../store/slices/task/timeReports';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import './style.css';
import { IProjectTask } from '../../../../../store/slices/task/types';
import { createHrs, createMins } from '../../../../../utils/helpers/time';

interface TaskSingleEditionTransferProps {
  firstLevelTask: IProjectTask | null;
  secondLevelTask: IProjectTask | null;
  thirdLevelTask: IProjectTask | null;
  setFirstLevelTask: (firstLevelTask: IProjectTask | null) => void;
  setSecondLevelTask: (secondLevelTask: IProjectTask | null) => void;
  setThirdLevelTask: (thirdLevelTask: IProjectTask | null) => void;
}

const TaskSingleEditionTransfer: React.FC<TaskSingleEditionTransferProps> = ({
  firstLevelTask,
  secondLevelTask,
  thirdLevelTask,
  setFirstLevelTask,
  setSecondLevelTask,
  setThirdLevelTask,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { modalParams, projectTasks } = useAppSelector((state) => state.taskTime);
  const { task } = useAppSelector((state) => state.task);
  const [isTimeBorderRed, setTimeBorderRed] = useState(false);
  const [isCommentBorderedRed, setCommentBorderedRed] = useState(false);

  const commentChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(changeModalParams({ ...modalParams, comment: e.currentTarget.value }));
  };

  const changeSelectMins = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(changeModalParams({ ...modalParams, mins: e.currentTarget.value }));
  };

  const changeSelectHrs = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(changeModalParams({ ...modalParams, hrs: e.currentTarget.value }));
  };

  useEffect(() => {
    if (modalParams.comment.trim() === '') {
      setCommentBorderedRed(true);
    } else {
      setCommentBorderedRed(false);
    }
  }, [modalParams.comment]);

  useEffect(() => {
    if (modalParams.time === '0:0') {
      setTimeBorderRed(true);
    } else {
      setTimeBorderRed(false);
    }
  }, [modalParams.time]);

  return (
    <div className="edit-time">
      <div className="edit-time__top">
        <div className="edit-time__wrapper-date">
          <label className="form-check-label">{t('timeEntry.date')}</label>
          <DatePicker
            className="edit-time__date"
            selected={modalParams?.startDate}
            onChange={(date: Date) =>
              dispatch(changeModalParams({ ...modalParams, startDate: date }))
            }
            calendarStartDay={1}
            dateFormat="dd.MM.yyyy"
          />
        </div>
        <div className="wrapper-time">
          <div className="wrapper-time__hrs">
            <label className={'form-check-label'}>{t('common.hoursShort')}</label>
            <Form.Select
              style={isTimeBorderRed ? { borderColor: 'red' } : {}}
              className="wrapper-time__select"
              value={modalParams?.hrs}
              size="sm"
              onChange={(e) => {
                changeSelectHrs(e);
              }}
            >
              {createHrs().map((hour) => (
                <option value={hour} key={hour}>
                  {hour}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="wrapper-time__mins">
            <label className={'form-check-label'}>{t('common.minutesShort')}</label>
            <Form.Select
              style={isTimeBorderRed ? { borderColor: 'red' } : {}}
              className="wrapper-time__select"
              value={modalParams?.mins === '05' ? modalParams?.mins.slice(1) : modalParams?.mins}
              size="sm"
              onChange={(e) => changeSelectMins(e)}
            >
              {createMins().map((min) => (
                <option value={min} key={min}>
                  {min}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="edit-time__task">
        {task?.id ? (
          <>
            <div className="edit-time__task__item b-select-light">
              <label className="form-check-label">{t('timeEntry.task')}</label>
              <Select
                options={projectTasks.filter(
                  (item) => item.parent_task === null || item.parent_task === 0
                )}
                value={firstLevelTask}
                getOptionLabel={(option: any) =>
                  option.value === Number(option.value) ? t(option.name) : option.name
                }
                isSearchable={true}
                onChange={(option) => {
                  setFirstLevelTask(option);
                  setSecondLevelTask(null);
                  setThirdLevelTask(null);
                }}
                classNamePrefix="custom-select"
              />
            </div>

            {firstLevelTask && (
              <div className="edit-time__task__item b-select-light">
                <label className="form-check-label">{t('timeEntry.secondTask')}</label>
                <Select
                  options={projectTasks.filter((item) => item.parent_task === firstLevelTask.id)}
                  value={secondLevelTask}
                  getOptionLabel={(option: any) =>
                    option.value === Number(option.value) ? t(option.name) : option.name
                  }
                  onChange={(option) => {
                    setSecondLevelTask(option);
                    setThirdLevelTask(null);
                  }}
                  isSearchable={true}
                  isClearable={true}
                  classNamePrefix="custom-select"
                />
              </div>
            )}

            {secondLevelTask && (
              <div className="edit-time__task__item b-select-light">
                <label className="form-check-label">{t('timeEntry.thirdTask')}</label>
                <Select
                  options={projectTasks.filter((item) => item.parent_task === secondLevelTask.id)}
                  value={thirdLevelTask}
                  getOptionLabel={(option: any) =>
                    option.value === Number(option.value) ? t(option.name) : option.name
                  }
                  onChange={(option) => {
                    setThirdLevelTask(option);
                  }}
                  isSearchable={true}
                  isClearable={true}
                  classNamePrefix="custom-select"
                />
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
      <div className="edit-time__comment">
        <label className="form-check-label">{t('timeEntry.comment')}</label>
        <TextareaAutosize
          style={isCommentBorderedRed ? { borderColor: 'red' } : {}}
          className="time-entry-comment"
          value={modalParams?.comment}
          minRows={3}
          onChange={(e) => commentChangeHandler(e)}
        />
      </div>
    </div>
  );
};
export default TaskSingleEditionTransfer;
