import React, { useState } from 'react';
import cl from './style.module.scss';
import { useAppDispatch } from '../../../hooks';
import { changeProjectDescriptionAsync } from '../../../store/slices/projects';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import btnEdit from '../../../assets/images/icons/edit.svg';
import { Permissions } from '../../../types/permissions';
import PermissionsGate from '../../permissionsGate';

interface ChangeProjectDescriptionProps {
  id: number;
  description: string;
  permission: Permissions;
}

export const ChangeProjectDescription: React.FC<ChangeProjectDescriptionProps> = ({
  id,
  description,
  permission,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const [editDescription, setEditDescription] = useState(false);
  const [textEdit, setTextEdit] = useState('');

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (textEdit === description) {
      alert(t('project.descriptionDidntChange'));
      return;
    }
    dispatch(changeProjectDescriptionAsync({ id, description: textEdit }));
    closeEdit();
  };

  const openEdit = () => {
    setEditDescription(true);
    setTextEdit(description);
  };

  const closeEdit = () => setEditDescription(false);

  return (
    <div className={cl.changeProjectDescription}>
      <Form onSubmit={onSubmit}>
        <div className={editDescription ? cl.editing : cl.text}>
          {editDescription ? (
            <>
              <Form.Control
                className={cl.input}
                type="text"
                value={textEdit}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextEdit(e.target.value)}
              />
              <div className={cl.buttons}>
                <Button variant="outline-secondary" onClick={closeEdit}>
                  {t('common.cancel')}
                </Button>
                <Button type="submit">{t('common.save')}</Button>
              </div>
            </>
          ) : (
            <>
              <div className={!description ? cl.textEmpty : ''}>{description}</div>

              <PermissionsGate permissions={[permission]}>
                <div onClick={openEdit} className={cl.btnEdit}>
                  <img src={btnEdit} alt="" />
                </div>
              </PermissionsGate>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};
