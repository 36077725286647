import { axios } from '../axios';

const endpoints = {
  getComments: (id: number, published: string) =>
    axios.get('/post/list', {
      params: {
        id: id,
        published: published,
      },
    }),
  deleteComment: (id: number) => axios.delete('/post/' + id),
  addComment: (data: FormData, id: number, published: string) =>
    axios.post(`/post/${id}?published=${published}`, data),
  editComment: (data: FormData, id: number) => axios.put(`/post/${id}`, data),
  restoreComment: (id: number, published: string) =>
    axios.put(`/post/restore/${id}`, {
      params: {
        id: id,
        published: published,
      },
    }),
};
export default endpoints;
