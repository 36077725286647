import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { BooleanNumber, ISelectOptions, TaskType } from '../../../types';
import { priorities, projectLanguages, projectStatuses } from '../../../utils/constants';
import { Toggle } from '../../toggle';
import styles from './style.module.scss';
import { addProjectAsync } from '../../../store/slices/projects';
import { INewProjectRequest } from '../../../services/api/endpoints/project';
import api from '../../../services/api';
import { AxiosError } from 'axios';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IProjectFormFields, ProjectFormFields } from './types';

interface ProjectFormProps {
  closeModal: () => void;
}

const ProjectForm: React.FC<ProjectFormProps> = ({ closeModal }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const billingList = ['enabled', 'disabled'];

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IProjectFormFields>({
    defaultValues: {
      [ProjectFormFields.name]: '',
      [ProjectFormFields.organization]: null,
      [ProjectFormFields.priority]: priorities[2],
      [ProjectFormFields.status]: projectStatuses[0],
      [ProjectFormFields.description]: '',
      [ProjectFormFields.hourly_rate]: '',
      [ProjectFormFields.repository_name]: '',
      [ProjectFormFields.hours_show_customer]: false,
      [ProjectFormFields.auto_actions_for_clients]: false,
      [ProjectFormFields.show_worked_time_to_customer]: false,
      [ProjectFormFields.allow_client_actions]: false,
      [ProjectFormFields.ms_dates_vary]: true,
      [ProjectFormFields.billing]: billingList[0],
      [ProjectFormFields.language]: projectLanguages[0],
      [ProjectFormFields.tags]: [],
      [TaskType.Bug]: true,
      [TaskType.Milestone]: true,
      [TaskType.Request]: true,
      [TaskType.Task]: true,
      [TaskType.Topic]: true,
      [TaskType.UIIssue]: true,
    },
  });

  const availableItemTypes = [
    TaskType.Bug,
    TaskType.Milestone,
    TaskType.Request,
    TaskType.Task,
    TaskType.Topic,
    TaskType.UIIssue,
  ];

  const [allTags, setAllTags] = useState<ISelectOptions<string>[]>([]);
  const [organizations, setOrganizations] = useState<ISelectOptions<number>[]>([]);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const getTags = async () => {
      await api.tag
        .allTags()
        .then((response) => {
          setAllTags(
            response.data.map((tag) => {
              return {
                name: tag,
                value: tag,
              };
            })
          );
        })
        .catch((error: AxiosError) => {
          alert(error.response?.data.error_message || 'Не удалось получить теги');
        });
    };
    const getOrganigations = async () => {
      await api.company
        .list({
          pagination: null,
        })
        .then((response) => {
          setOrganizations(
            response.data.data.map((company) => {
              return {
                id: company.id,
                name: company.name,
                value: company.id,
              };
            })
          );
        })
        .catch((error: AxiosError) => {
          alert(error.response?.data.error_message || 'Не удалось получить организации');
        });
    };

    getTags();
    getOrganigations();
  }, []);

  const onSubmit: SubmitHandler<IProjectFormFields> = async (data) => {
    setPending(true);

    const params: INewProjectRequest = {
      name: data.name,
      status: data.status.value,
      priority: data.priority.value,
      description: data.description,
      hourly_rate: Number(data.hourly_rate),
      repository_name: data.repository_name,
      billing: data.billing === 'enabled' ? 1 : 0,
      language: data.language.value,
      hours_show_customer: Number(data.hours_show_customer) as BooleanNumber,
      auto_actions_for_clients: Number(data.auto_actions_for_clients) as BooleanNumber,
      show_worked_time_to_customer: Number(data.show_worked_time_to_customer) as BooleanNumber,
      allow_client_actions: Number(data.allow_client_actions) as BooleanNumber,
      ms_dates_vary: Number(data.ms_dates_vary) as BooleanNumber,
    };

    if (data.organization) {
      params.organization = data.organization.value;
    }

    const disabledItems: string[] = [];
    availableItemTypes.forEach((type) => {
      if (data[type] === false) {
        disabledItems.push(type);
      }
    });

    if (disabledItems.length) {
      params.disabled_items = disabledItems.join(',');
    }

    if (data.tags && data.tags.length) {
      params.tags = data.tags.map((tag) => tag.name);
    }

    await dispatch(addProjectAsync(params));

    setPending(false);
    closeModal();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <Form.Group className="mb-3 b-input-light">
        <Form.Label>{t('project.name')}</Form.Label>
        <Form.Control
          type="text"
          {...register(ProjectFormFields.name, { required: true })}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">{t('formErrors.required')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3 b-textarea-light">
        <Form.Label>{t('project.description')}</Form.Label>
        <Form.Control as="textarea" rows={3} {...register(ProjectFormFields.description)} />
      </Form.Group>
      <Row className="mb-3">
        <Col>
          <Form.Group className="mb-3 b-input-light">
            <Form.Label>{t('project.repositoryName')}</Form.Label>
            <Form.Control type="text" {...register(ProjectFormFields.repository_name)} />
          </Form.Group>
        </Col>
        <Col>
          <div className="b-select-light">
            <label>{t('project.organization')}</label>
            <Controller
              name={ProjectFormFields.organization}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={organizations}
                  getOptionLabel={(option) => option.name}
                  isClearable={true}
                  classNamePrefix="custom-select"
                />
              )}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="b-select-light">
            <label>{t('filter.priority')}</label>
            <Controller
              name={ProjectFormFields.priority}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={priorities}
                  getOptionLabel={(option) => t(option.name)}
                  isSearchable={false}
                  classNamePrefix="custom-select"
                />
              )}
            />
          </div>
        </Col>
        <Col>
          <div className="b-select-light">
            <label>{t('filter.status')}</label>
            <Controller
              name={ProjectFormFields.status}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={projectStatuses}
                  getOptionLabel={(option) => t(option.name)}
                  isSearchable={false}
                  classNamePrefix="custom-select"
                />
              )}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={3}>
          <div className="b-select-light">
            <label>{t('project.language')}</label>
            <Controller
              name={ProjectFormFields.language}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={projectLanguages}
                  getOptionLabel={(option) => t(option.name)}
                  isSearchable={false}
                  classNamePrefix="custom-select"
                />
              )}
            />
          </div>
        </Col>
        <Col md={3}>
          <Form.Group className="b-input-light">
            <Form.Label>{t('project.hourlyRate')}</Form.Label>
            <Form.Control
              type="number"
              {...register(ProjectFormFields.hourly_rate, { min: 0 })}
              isInvalid={!!errors.hourly_rate}
            />
            <Form.Control.Feedback type="invalid">
              {t('formErrors.greaterThanZero')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <div className={styles.billing}>{t('project.billing')}</div>
          <Controller
            name={ProjectFormFields.billing}
            control={control}
            render={({ field }) => (
              <Toggle
                translationPrefix="common."
                items={billingList}
                activeItem={field.value}
                handler={(activeField: string) => setValue(ProjectFormFields.billing, activeField)}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Check
            type="checkbox"
            id="hoursShowCustomer"
            label={t('project.checkboxes.hoursShowCustomer')}
            {...register(ProjectFormFields.hours_show_customer)}
          />
        </Col>
        <Col>
          <Form.Check
            type="checkbox"
            id="autoActionsForClients"
            label={t('project.checkboxes.autoActionsForClients')}
            {...register(ProjectFormFields.auto_actions_for_clients)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Check
            type="checkbox"
            id="showWorkedTimeToCustomer"
            label={t('project.checkboxes.showWorkedTimeToCustomer')}
            {...register(ProjectFormFields.show_worked_time_to_customer)}
          />
        </Col>
        <Col>
          <Form.Check
            type="checkbox"
            id="allowClientActions"
            label={t('project.checkboxes.allowClientActions')}
            {...register(ProjectFormFields.allow_client_actions)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Check
            type="checkbox"
            id="msDatesVary"
            label={t('project.checkboxes.msDatesVary')}
            {...register(ProjectFormFields.ms_dates_vary)}
            disabled
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <span className={styles.availableItemTypes}>{t('project.availableItemTypes')}:</span>
          {availableItemTypes.map((type) => (
            <Form.Check
              inline
              type="checkbox"
              id={`availableItemTypes-${type}`}
              label={t(`taskTypes.${type}`)}
              {...register(type)}
            />
          ))}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="b-select-light">
            <label>{t('project.listTags')}</label>
            <Controller
              name={ProjectFormFields.tags}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={allTags}
                  getOptionLabel={(option: ISelectOptions) => option.name}
                  isMulti
                  classNamePrefix="custom-select"
                  placeholder={t('project.tagPlaceholder')}
                />
              )}
            />
          </div>
        </Col>
      </Row>

      <div className={styles.buttons}>
        <Button variant="outline-secondary" onClick={closeModal}>
          {t('common.cancel')}
        </Button>
        <Button type="submit" disabled={pending}>
          {t('common.save')}
        </Button>
      </div>
    </Form>
  );
};

export default ProjectForm;
