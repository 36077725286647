import React, { useEffect } from 'react';
import cl from './style.module.scss';
import TableActivity from './TableActivity';
import {
  changeFilterParam,
  changePage,
  fetchActivityAsync,
  goToFirstPage,
  goToNextPage,
  goToPrevPage,
  goToLastPage,
  changePaginationCount,
} from '../../../store/slices/task/activity';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Preloader from '../../preloader';
import Pagination from '../../pagination';
import CountPerPage from '../../countPerPage';

export const ActivityTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const { task } = useAppSelector((state) => state.task);
  const { pagination, count, loading, sort, filter } = useAppSelector(
    (state) => state.taskActivity
  );

  useEffect(() => {
    if (task?.id) {
      dispatch(changeFilterParam({ ...filter, task_id: task.id }));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchActivityAsync(false));
  }, [sort]);

  return (
    <div className={cl.activityTab}>
      <div className={cl.activityHeader}></div>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <TableActivity />
          <div className={cl.container}>
            {pagination ? (
              <Pagination
                currentPage={pagination.page}
                pagesTotal={pagination.pages_total}
                onChangePage={(i: number) => {
                  dispatch(changePage(i));
                  dispatch(fetchActivityAsync(true));
                }}
                onGoToFirstPage={() => {
                  dispatch(goToFirstPage());
                  dispatch(fetchActivityAsync(true));
                }}
                onGoToPrevPage={() => {
                  dispatch(goToPrevPage());
                  dispatch(fetchActivityAsync(true));
                }}
                onGoToNextPage={() => {
                  dispatch(goToNextPage());
                  dispatch(fetchActivityAsync(true));
                }}
                onGoToLastPage={() => {
                  dispatch(goToLastPage());
                  dispatch(fetchActivityAsync(true));
                }}
              />
            ) : (
              <div />
            )}
            <CountPerPage
              countPerPage={count}
              onChange={(count: string) => {
                dispatch(changePaginationCount(count));
                dispatch(fetchActivityAsync(false));
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
