export const createHrs = () => {
  let mass = [];
  for (let i: number = 0; i <= 20; i++) {
    mass.push(i.toString());
  }
  return mass;
};

export const createMins = () => {
  let mass = [];
  for (let i: number = 0; i < 60; i += 5) {
    mass.push(i.toString());
  }
  return mass;
};
