import React, { useEffect } from 'react';
import ProjectTab from '../../components/projectsTable';
import { useTranslation } from 'react-i18next';

interface ProjectsProps {}

export const Projects: React.FC<ProjectsProps> = () => {
  const { t } = useTranslation('common');

  useEffect(() => {
    document.title = t('header.menu.projects');
  }, []);

  return <ProjectTab />;
};

export default Projects;
