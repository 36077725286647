import React, { useEffect } from 'react';
import styles from './style.module.scss';
import { Permissions as PermissionsEnum } from '../../../types/permissions';
import Preloader from '../../preloader';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Table } from 'react-bootstrap';
import { UserRoles } from '../../../types';
import switchImage from '../../../assets/images/icons/switch-horizontal.svg';
import PermissionsGate from '../../permissionsGate';
import { changePermissionAsync, fetchPermissions } from '../../../store/slices/admin/permissions';

interface PermissionsProps {}

const Permissions: React.FC<PermissionsProps> = () => {
  const { permissions, loading } = useAppSelector((state) => state.permissions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (permissions === null) {
      dispatch(fetchPermissions());
    }
  }, []);

  const changePermission = async (
    permission: PermissionsEnum,
    role: UserRoles,
    currentValue: boolean
  ) => {
    const newValue = !currentValue;

    if (
      window.confirm(
        `Вы действительно хотите изменить разрешение "${permission}" для роли "${role}" на значение ${newValue}?`
      )
    ) {
      dispatch(
        changePermissionAsync({
          permission,
          role,
          newValue,
        })
      );
    }
  };

  const renderPermissions = () => {
    if (!permissions) return null;

    let output = [];
    for (let permission in permissions) {
      output.push(
        <tr key={permission}>
          <td>{permission}</td>
          <td>{permissions[permission].admin ? '+' : '-'}</td>
          <td>
            <div>{permissions[permission].pm ? '+' : '-'}</div>
            <PermissionsGate permissions={[PermissionsEnum.update_permissions]}>
              <img
                src={switchImage}
                alt=""
                title="Сменить разрешение"
                onClick={() =>
                  changePermission(
                    permission as PermissionsEnum,
                    UserRoles.PM,
                    permissions[permission].pm
                  )
                }
              />
            </PermissionsGate>
          </td>
          <td>
            <div>{permissions[permission].user ? '+' : '-'}</div>
            <PermissionsGate permissions={[PermissionsEnum.update_permissions]}>
              <img
                src={switchImage}
                alt=""
                title="Сменить разрешение"
                onClick={() =>
                  changePermission(
                    permission as PermissionsEnum,
                    UserRoles.User,
                    permissions[permission].user
                  )
                }
              />
            </PermissionsGate>
          </td>
          <td>
            <div>{permissions[permission].customer ? '+' : '-'}</div>
            <PermissionsGate permissions={[PermissionsEnum.update_permissions]}>
              <img
                src={switchImage}
                alt=""
                title="Сменить разрешение"
                onClick={() =>
                  changePermission(
                    permission as PermissionsEnum,
                    UserRoles.Customer,
                    permissions[permission].customer
                  )
                }
              />
            </PermissionsGate>
          </td>
          <td>{permissions[permission].disabled ? '+' : '-'}</td>
        </tr>
      );
    }
    return output;
  };

  if (permissions === null && !loading) return null;

  return (
    <div className={styles.container}>
      {loading ? (
        <Preloader />
      ) : (
        <Table className={styles.table} bordered hover>
          <thead>
            <tr>
              <th></th>
              <th>{UserRoles.Admin}</th>
              <th>{UserRoles.PM}</th>
              <th>{UserRoles.User}</th>
              <th>{UserRoles.Customer}</th>
              <th>{UserRoles.Disabled}</th>
            </tr>
          </thead>
          <tbody>{renderPermissions()}</tbody>
        </Table>
      )}
    </div>
  );
};

export default Permissions;
