import React from 'react';
import styles from './style.module.scss';
import { useAppDispatch } from '../../hooks';
import { changeModalEdit, getEditIdWorkedTime } from '../../store/slices/task/timeReports';
import closeImage from '../../assets/images/icons/close.svg';

interface ModalProps {
  title: string;
}

export const Modal: React.FC<ModalProps> = ({ title, children }) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    //TODO: убрать, сделать компонент универсальным
    dispatch(getEditIdWorkedTime(null));
    dispatch(changeModalEdit(false));
  };

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.window} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <span>{title}</span>
          <img src={closeImage} className={styles.closeButton} onClick={onClose} alt="" />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

// import { useState } from 'react';
// import { useAppDispatch, useAppSelector } from '../../hooks';
// import { changeModalEdit, getEditIdWorkedTime } from '../../store/slices/task/timeReports';
// import Button from 'react-bootstrap/Button';
// import ModalBootstrap from 'react-bootstrap/Modal';

// interface ModalProps {
//   title: string;
// }

// export const Modal: React.FC<ModalProps> = ({ title, children }) => {
//   const dispatch = useAppDispatch();
//   const [modalShow, setModalShow] = useState(false);

//   const handleClose = () => {
//     // dispatch(getEditIdWorkedTime(null));
//     // dispatch(changeModalEdit(false));
//   };

//   return (
//     <ModalBootstrap size="lg" centered show={modalShow} onHide={() => setModalShow(false)}>
//       <ModalBootstrap.Header closeButton>
//         <ModalBootstrap.Title>{title}</ModalBootstrap.Title>
//       </ModalBootstrap.Header>
//       <ModalBootstrap.Body>{children}</ModalBootstrap.Body>
//     </ModalBootstrap>
//     //    <div className={styles.overlay} onClick={onClose}>
//     //    <div className={styles.window} onClick={(e) => e.stopPropagation()}>
//     //      <div className={styles.header}>
//     //        <span>{title}</span>
//     //        <img src={closeImage} className={styles.closeButton} onClick={onClose} alt="" />
//     //      </div>
//     //      <div className={styles.content}>{children}</div>
//     //    </div>
//     //  </div>
//   );
// };
