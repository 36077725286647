import { store } from '../../store';
import { Permissions } from '../../types/permissions';

// Проверка одного разрешения
export const checkUserAccess = (permission: Permissions) => {
  const state = store.getState();
  if (state.currentUser.id === null) return false;

  return state.currentUser.permissions.includes(permission);
};

// Проверка наличия всех разрешений
export const checkAllUserAccess = (permissions: Permissions[]) => {
  const state = store.getState();
  if (state.currentUser.id === null) return false;

  return permissions.every((permission) => state.currentUser.permissions.includes(permission));
};

// Проверка наличия хотя бы одного разрешения
export const checkLeastOneUserAccess = (permissions: Permissions[]) => {
  const state = store.getState();
  if (state.currentUser.id === null) return false;

  return permissions.some((permission) => {
    return state.currentUser.permissions.includes(permission);
  });
};
