import React, { useState } from 'react';
import editImage from '../../assets/images/icons/edit.svg';
import cl from './style.module.scss';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import RadioButtonList from '../tasksTable/radioButtonList';
import { IStatusSeparated } from '../../utils/constants';
import { Permissions } from '../../types/permissions';
import PermissionsGate from '../permissionsGate';

interface ChangeStatusProps {
  status: number;
  statuses: IStatusSeparated[];
  onChangeStatus: (status: number) => void;
  permission: Permissions;
}

const ChangeStatus: React.FC<ChangeStatusProps> = ({
  status,
  statuses,
  onChangeStatus,
  permission,
}) => {
  const { t } = useTranslation('common');
  const [isStatusPopoverOpen, setStatusPopoverOpen] = useState(false);
  const [isStatusGreen, setStatusGreen] = useState(false);

  const statusChangeHandler = (newStatus: number) => {
    if (status === Number(newStatus)) {
      return;
    }
    let done = false;
    let doneWaiting = false;
    setTimeout(() => {
      doneWaiting = true;
      if (done) {
        setStatusGreen(false);
      }
    }, 2000);
    setStatusGreen(true);
    onChangeStatus(newStatus);
    done = true;
    if (doneWaiting) {
      setStatusGreen(false);
    }
  };

  return (
    <div
      className={clsx(cl.changeStatus, 'b-status')}
      style={{
        borderColor: isStatusGreen
          ? '#8EECC4'
          : statuses.find((st) => Number(st.value) === status)?.color ?? '',
      }}
    >
      <div style={{ color: isStatusGreen ? '#8EECC4' : '' }} className="b-status-text">
        {t(statuses.find((st) => Number(st.value) === status)?.name ?? '')}
      </div>
      <PermissionsGate permissions={[permission]}>
        <div className={cl.btnEdit}>
          <RadioButtonList
            list={statuses}
            callback={statusChangeHandler}
            checked={status}
            isStatusShow={isStatusPopoverOpen}
            setStatusShow={setStatusPopoverOpen}
          >
            <img className="status-image-size" src={editImage} alt="" />
          </RadioButtonList>
        </div>
      </PermissionsGate>
    </div>
  );
};

export default ChangeStatus;
