import { changeModalParams } from '../../../../../store/slices/task/timeReports';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import Select from 'react-select';
import './style.css';
import { IProjectTask } from '../../../../../store/slices/task/types';

interface TaskMultiplyEditionTransferProps {
  firstLevelTask: IProjectTask | null;
  secondLevelTask: IProjectTask | null;
  thirdLevelTask: IProjectTask | null;
  setFirstLevelTask: (firstLevelTask: IProjectTask | null) => void;
  setSecondLevelTask: (secondLevelTask: IProjectTask | null) => void;
  setThirdLevelTask: (thirdLevelTask: IProjectTask | null) => void;
}

const TaskMultiplyEditionTransfer: React.FC<TaskMultiplyEditionTransferProps> = ({
  firstLevelTask,
  secondLevelTask,
  thirdLevelTask,
  setFirstLevelTask,
  setSecondLevelTask,
  setThirdLevelTask,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { modalParams, projectTasks } = useAppSelector((state) => state.taskTime);
  const { task } = useAppSelector((state) => state.task);

  return (
    <div className="edit-time-multiply">
      <div className="edit-time__date">
        <label className="form-check-label">{t('timeEntry.date')}</label>
        <div className="">
          <DatePicker
            className="time-entry-input-date"
            selected={modalParams.startDate}
            onChange={(date: Date) =>
              dispatch(changeModalParams({ ...modalParams, startDate: date }))
            }
            calendarStartDay={1}
            dateFormat="dd.MM.yyyy"
          />
        </div>
      </div>
      <div className="edit-time__task">
        {task ? (
          <>
            <div className="edit-time__task__item b-select-light">
              <label className="form-check-label">{t('timeEntry.task')}</label>
              <Select
                options={projectTasks.filter(
                  (item) => item.parent_task === null || item.parent_task === 0
                )}
                value={firstLevelTask}
                getOptionLabel={(option: any) =>
                  option.value === Number(option.value) ? t(option.name) : option.name
                }
                isSearchable={true}
                onChange={(option) => {
                  setFirstLevelTask(option);
                  setSecondLevelTask(null);
                  setThirdLevelTask(null);
                }}
                classNamePrefix="custom-select"
              />
            </div>
            {firstLevelTask && (
              <div className="edit-time__task__item b-select-light">
                <label className="form-check-label">{t('timeEntry.secondTask')}</label>
                <Select
                  options={projectTasks.filter((item) => item.parent_task === firstLevelTask.id)}
                  value={secondLevelTask}
                  getOptionLabel={(option: any) =>
                    option.value === Number(option.value) ? t(option.name) : option.name
                  }
                  onChange={(option) => {
                    setSecondLevelTask(option);
                    setThirdLevelTask(null);
                  }}
                  isSearchable={true}
                  isClearable={true}
                  classNamePrefix="custom-select"
                />
              </div>
            )}
            {secondLevelTask && (
              <div className="edit-time__task__item b-select-light">
                <label className="form-check-label">{t('timeEntry.thirdTask')}</label>
                <Select
                  options={projectTasks.filter((item) => item.parent_task === secondLevelTask.id)}
                  value={thirdLevelTask}
                  getOptionLabel={(option: any) =>
                    option.value === Number(option.value) ? t(option.name) : option.name
                  }
                  onChange={(option) => {
                    setThirdLevelTask(option);
                  }}
                  isSearchable={true}
                  isClearable={true}
                  classNamePrefix="custom-select"
                />
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default TaskMultiplyEditionTransfer;
