import { useTable, Column, useRowSelect, Row } from 'react-table';
import cl from './style.module.scss';
import sortIcon from '../../../assets/images/icons/sort.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Preloader from '../../preloader';
import { IndeterminateCheckbox } from './interminateCheckbox';
import { IProjectModel } from '../../../store/slices/projects/types';
import arrowUpImage from '../../../assets/images/icons/arrow-up.svg';
import arrowDownImage from '../../../assets/images/icons/arrow-down.svg';
import { useMemo } from 'react';
import { COLUMNS } from './columns';
import { changeSortColumn, resetSort } from '../../../store/slices/projects';
import { projectsSortableColumns } from '../../../utils/constants';

const ProjectTable = () => {
  const { loading, data, sort } = useAppSelector((state) => state.projects);
  const columns: Column[] = useMemo(() => COLUMNS, []);
  const dispatch = useAppDispatch();

  const onSortTable = (column: string) => {
    if (!projectsSortableColumns.find((el) => el === column)) {
      return;
    }
    if (!sort) {
      dispatch(changeSortColumn({ field: column, direction: 'desc' }));
      return;
    }
    if (sort && Object.keys(sort)[0] !== column) {
      dispatch(changeSortColumn({ field: column, direction: 'desc' }));
      return;
    }
    if (sort) {
      if (Object.values(sort)[0] === 'asc') {
        dispatch(resetSort());
        dispatch(changeSortColumn({ field: column, direction: 'desc' }));
      } else {
        dispatch(resetSort());
        dispatch(changeSortColumn({ field: column, direction: 'asc' }));
      }
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    ({ visibleColumns }) => {
      visibleColumns.push((cols) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: { row: Row<IProjectModel> }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...cols,
      ]);
    }
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className={cl.projectTable}>
      {loading ? (
        <Preloader />
      ) : (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className={cl.projectTable__table__header} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    onClick={() => onSortTable(column.id)}
                    className={
                      !projectsSortableColumns.find((el) => el === column.id)
                        ? ''
                        : cl.sortableColumn
                    }
                  >
                    <div>
                      {column.render('Header')}
                      {projectsSortableColumns.find((el) => el === column.id) && (
                        <span>
                          {sort && Object.keys(sort)[0] === column.id ? (
                            <img
                              src={
                                Object.values(sort)[0] === 'desc' ? arrowDownImage : arrowUpImage
                              }
                              alt=""
                            />
                          ) : (
                            <img src={sortIcon} alt="" />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default ProjectTable;
