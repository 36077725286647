import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import styles from './style.module.scss';

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  const { t } = useTranslation('common');
  const { token } = useAuth();

  useEffect(() => {
    document.title = t('notFoundPage.title');
  }, []);

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('notFoundPage.title')}</p>
      {token ? (
        <ul className={styles.nav}>
          <li>
            <Link to="/tasks">{t('header.menu.tasks')}</Link>
          </li>
          <li>
            <Link to="/projects">{t('header.menu.projects')}</Link>
          </li>
          <li>
            <Link to="/time">{t('header.menu.timeReport')}</Link>
          </li>
        </ul>
      ) : (
        <div className={styles.login}>
          {t('notFoundPage.warning')} <Link to="/login">{t('notFoundPage.login')}</Link>
        </div>
      )}
    </div>
  );
};

export default NotFound;
