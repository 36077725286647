import { Column } from 'react-table';
import Translation from '../../../../translation';
import cl from './style.module.scss';
import { activityTabHeaders } from '../../../../../utils/constants';
import { IActivityChanges } from '../../../../../store/slices/task/activity/types';

const renderValue = (changes: IActivityChanges, old_value: boolean) => {
  const value = Object.values(changes)[0][0][old_value ? 'old_value' : 'new_value'];
  if (value === null || value === '') return <Translation text="select.none" />;

  return typeof value === 'object' ? value.name : value;
};

export const COLUMNS: Column[] = [
  {
    Header: <Translation text={activityTabHeaders[0].name} />,
    accessor: 'author',
    Cell: (props: any) => {
      return (
        <a className={cl.author} href="#">
          {props.row.original.author.name}
        </a>
      );
    },
  },
  {
    Header: <Translation text={activityTabHeaders[1].name} />,
    accessor: 'date',
    Cell: (props: any) => {
      return <div className={cl.date}>{props.row.original.date}</div>;
    },
  },
  {
    Header: <Translation text={activityTabHeaders[2].name} />,
    accessor: 'description',
    Cell: (props: any) => {
      return (
        <div className={cl.description}>
          <span className={cl.key}>{Object.keys(props.row.original.changes)[0]}:&nbsp;</span>
          <span>{renderValue(props.row.original.changes, true)}</span>
          <span>&nbsp;{'→'}&nbsp;</span>
          <span>{renderValue(props.row.original.changes, false)}</span>
        </div>
      );
    },
  },
];
