import { ISprintModel } from '../../../store/slices/tasks/types';
import { IOKResult } from '../../../types';
import { axios } from '../axios';
import { Milestone } from '../endpoints/type';

type MyMilestonesRequestType = {
  project_id?: number;
};

export interface IMilestonesCreateRequest {
  name: string;
  project_id: number;
  start_date?: string;
  due_date?: string;
}

const endpoints = {
  myMilestones: (data: MyMilestonesRequestType = {}) =>
    axios.get<ISprintModel[]>('/milestone/my-milestones', {
      params: data,
    }),
  getList: async (id: number) => {
    const response = await axios.get<Milestone[]>('milestone/list', {
      params: {
        sort: {},
        filter: {
          project_id: id,
        },
      },
    });
    return response.data;
  },
  add: (data: IMilestonesCreateRequest) => axios.post<IOKResult<Milestone>>('/milestone/add', data),
  update: (id: number, data: IMilestonesCreateRequest) =>
    axios.put<IOKResult<Milestone>>(`/milestone/${id}`, data),
  delete: (id: number) =>
    axios.delete('/milestone/delete', {
      params: { id },
    }),
};

export default endpoints;
