import React from 'react';
import styles from './style.module.scss';
import logo from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';

interface LogoProps {
  width?: number;
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ width = 40, height = 40 }) => {
  return (
    <Link to="/tasks" className={styles.logo}>
      <img style={{ width, height }} src={logo} alt="logo" />
    </Link>
  );
};

export default Logo;
