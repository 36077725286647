import React, { useState } from 'react';
import '../style.css';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { changeFilterParams } from '../../../../store/slices/timeReportsTab';
import { ISelectOptions } from '../../../../types';
import { getValidSelectOptions } from '../../../../utils/helpers/selects';

const ANY_PROJECT = '';

export const ProjectFilterTimeReports: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { data, filter, loading } = useAppSelector((state) => state.timeReportsTab);
  const anyProject = { name: 'filter.anyProject', value: ANY_PROJECT };
  const projectList = [anyProject, ...getValidSelectOptions(data.projects)];
  const [selectedProject, setSelectedProject] = useState<ISelectOptions | null>(anyProject);

  const handleProjectFilterChange = (project: ISelectOptions | null) => {
    if (project) {
      dispatch(changeFilterParams({ project: project.value }));
    } else {
      dispatch(changeFilterParams({ project: undefined }));
    }
  };

  return (
    <div className="b-select-light">
      <Select
        className="b-timereport-select"
        options={projectList}
        value={selectedProject}
        getOptionLabel={(option) => (option.value === ANY_PROJECT ? t(option.name) : option.name)}
        onChange={(option) => {
          setSelectedProject(option);
          handleProjectFilterChange(option);
        }}
        classNamePrefix="custom-select"
        isLoading={loading.projects}
      />
    </div>
  );
};
