import { RootState } from '../../index';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { IUserModel, UsersState } from './types';

const initialState: UsersState = {
  users: [],
  loading: false,
};

export const fetchUsersAsync = createAsyncThunk<any, void, { state: RootState }>(
  'users/fetchUsers',
  async function (_) {
    const filterValue = {
      exclude_customers: true,
      exclude_inactive: true,
    };
    const users = await api.user.list({ filter: filterValue });
    const usersData: IUserModel[] = users.data;
    return usersData;
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default usersSlice.reducer;
