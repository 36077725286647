import { useTranslation } from 'react-i18next';

interface TranslationProps {
  text: string;
}

const Translation: React.FC<TranslationProps> = ({ text }) => {
  const { t } = useTranslation('common');
  return <span>{t(text)}</span>;
};
export default Translation;
