import React from 'react';
import Preloader from '../preloader';
import './style.css';

interface TabContentProps {
  text: string;
  loading?: boolean;
}

const TabContent: React.FC<TabContentProps> = ({ text, loading = false }) => {
  return (
    <div className="tabContent-container">
      <div>{text}</div>
      {loading ? (
        <div className="tabContent-preloader">
          <Preloader size={26} />
        </div>
      ) : null}
    </div>
  );
};

export default TabContent;
