import React, { useEffect, useState } from 'react';
import '../styles.css';
import './styles.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import { IProjectModel, ISprintModel, ITasksResponse } from '../../../store/slices/tasks/types';
import { useAppDispatch } from '../../../hooks';
import plusImage from '../../../assets/images/icons/plus.svg';
import api from '../../../services/api';
import DatePicker from 'react-datepicker';
import arrowUpImage from '../../../assets/images/icons/arrow-up.svg';
import arrowDownImage from '../../../assets/images/icons/arrow-down.svg';
import FileInput from '../../files/fileInput';
import { IErrorResponse, ITagModel, IEntity } from '../../../types';
import { checkTimeFormat, replaceLineBreaksToBr } from '../../../utils/helpers';
import { NewTaskType } from '../../../services/api/endpoints/task';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { addTask } from '../../../store/slices/tasks';
import {
  billableTimeValues,
  billings,
  customerVisibleValues,
  estimatedTimeValues,
  priorities,
  statuses,
  taskTypes,
} from '../../../utils/constants';
import axios, { AxiosError } from 'axios';
import { IProjectTask, ITaskDetail } from '../../../store/slices/task/types';
import Preloader from '../../preloader';
import { fetchTaskAsync } from '../../../store/slices/task';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getValidSelectOptions } from '../../../utils/helpers/selects';

interface TaskPopoverProps {
  defaultProject: IProjectModel | undefined;
  task?: ITaskDetail | null;
  onClose: () => void;
  fromDetail: boolean;
}

const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={plusImage} className="tags-indicator" alt="" />
    </components.DropdownIndicator>
  );
};

// TODO: Временное решение, пока на бэке не актуализируют статусы
const getActualStatus = (status: string) => {
  if (Number(status) === 5 || Number(status) === 10) status = '2';
  return statuses.find((item) => status === item.value);
};

const TaskPopover: React.FC<TaskPopoverProps> = ({ defaultProject, task, onClose, fromDetail }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(task ? task.name : '');
  const [tags, setTags] = useState<number[]>(
    task
      ? task.tags.map((item) => {
          return item.id;
        })
      : []
  );
  const [status, setStatus] = useState<string>(task ? String(task.status) : statuses[2].value);
  const [priority, setPriority] = useState<string>(
    task ? String(task.priority) : priorities[2].value
  );
  const [project, setProject] = useState<number | null | undefined>(
    defaultProject ? defaultProject.id : null
  );
  const [type, setType] = useState<string>(task ? task.type : taskTypes[0].value);
  const [sprint, setSprint] = useState<number | null>(
    task ? (typeof task.milestone_id === 'number' ? task.milestone_id : task.milestone_id.id) : null
  );
  const [rootTask, setRootTask] = useState<number | null>(
    task && task.parent_task_path && task.parent_task_path.length
      ? task.parent_task_path[0].id
      : null
  );
  const [parentTask, setParentTask] = useState<number | null>(
    task && task.parent_task_path && task.parent_task_path.length > 1
      ? task.parent_task_path[1].id
      : null
  );
  const [assignee, setAssignee] = useState<number | null>(
    task ? (task.assigned_to ? task.assigned_to.id : null) : null
  );
  const [due, setDue] = useState<Date | null>(
    task ? (task.due_date !== '--' ? new Date(task.due_date) : null) : null
  );
  const [estimatedTime, setEstimatedTime] = useState<string>(
    task ? task.estimated_time.type : estimatedTimeValues[0].value
  );
  const [estimatedTimeHours, setEstimatedTimeHours] = useState<string>(
    task ? task.estimated_time.time : ''
  );
  const [billing, setBilling] = useState<string>(task ? String(task.billing) : billings[0].value);
  const [billableTime, setBillableTime] = useState<string>(
    task ? task.billable_time.type : billableTimeValues[0].value
  );
  const [billableTimeHours, setBillableTimeHours] = useState<string>(
    task ? task.billable_time.time : ''
  );
  const [customerVisible, setCustomerVisible] = useState<string>(
    task ? task.published : customerVisibleValues[0].value
  );
  const [description, setDescription] = useState<string>(
    task ? replaceLineBreaksToBr(task.description) : ''
  );
  const [files, setFiles] = useState<File[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<IEntity[]>(task ? task.taskFiles : []);
  const [filesDeleted, setFilesDeleted] = useState<number[]>([]);

  const [optionsLoading, setOptionsLoading] = useState(false);
  const [showExtraFields, setShowExtraFields] = useState(false);

  const [projects, setProjects] = useState<IProjectModel[]>([]);
  const [projectTasks, setProjectTasks] = useState<IProjectTask[]>([]);
  const [projectTags, setProjectTags] = useState<Array<any>>([]);
  const [projectSprints, setProjectSprints] = useState<ISprintModel[]>([]);
  const [projectAssignees, setProjectAssignees] = useState<IEntity[]>([]);

  const noProject = { value: 0, name: 'select.none' };
  const noSprint = { value: 0, name: 'select.none' };
  const noParentTask = { value: 0, name: 'select.none' };
  const noAssignee = { value: 0, name: 'select.none' };

  const getProjectTasks = async (project_id: number) => {
    const response = await api.task.list({
      filter: {
        project: String(project_id),
        status: '',
      },
    });
    const data: ITasksResponse = response.data;
    setProjectTasks(
      data.tasks
        .filter((item) => item.project_id === Number(project))
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            value: item.id,
            parent_task: item.parent_task,
          };
        })
    );
  };

  const getProjectTags = async (project_id: number) => {
    const response = await api.project.tag({ project_id });
    const data: ITagModel[] = response.data;
    setProjectTags(
      data.map((item) => {
        return {
          name: item.text,
          value: item.id,
          project_id: item.project_id,
        };
      })
    );
  };

  const getProjects = async () => {
    await api.project.myProjects().then((response) => {
      setProjects(response.data);
    });
    // setOptionsLoading(false);
  };

  const getProjectSprints = async (project_id: number) => {
    const response = await api.milestone.myMilestones({ project_id });
    const data: ISprintModel[] = response.data;
    setProjectSprints(data);
  };

  const getProjectAssignees = async (project_id: number) => {
    const response = await api.project.users({ id: project_id });
    const data: IEntity[] = response.data;
    setProjectAssignees(data);
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (project) {
      getProjectTasks(project);
      getProjectTags(project);
      getProjectSprints(project);
      getProjectAssignees(project);
    }
  }, [project]);

  const {
    formState: { errors },
    handleSubmit,
  } = useForm<NewTaskType>();

  const onSubmit: SubmitHandler<NewTaskType> = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('priority', String(priority));
    formData.append('project', project ? String(project) : '');
    formData.append('milestone_id', sprint ? String(sprint) : '');
    formData.append('type', type);
    formData.append('description', description);
    formData.append('status', String(status));
    formData.append('billing', String(billing));
    formData.append(
      'estimated_time',
      estimatedTime === 'time' ? estimatedTimeHours : estimatedTime
    );
    formData.append('billable_time', billableTime === 'time' ? billableTimeHours : billableTime);
    formData.append('published', String(customerVisible));
    if (parentTask || rootTask) {
      formData.append('parent_task', String(parentTask ? parentTask : rootTask));
    }
    if (assignee !== null && assignee !== 0) formData.append('assigned_to', String(assignee));
    if (due !== null) formData.append('due_date', dayjs(due).format('YYYY-MM-DD'));
    if (tags.length) {
      tags.forEach((tag) => {
        formData.append('tags[]', String(tag));
      });
    }
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }
    }

    if (task) {
      if (filesDeleted.length) {
        filesDeleted.forEach((fileId) => {
          formData.append('delete_files[]', String(fileId));
        });
      }
      await api.task
        .edit(task.id, formData)
        .then((response) => {
          if (response.data.result === 'OK') {
            dispatch(fetchTaskAsync(task.id));
            alert('Задача обновлена');
            onClose();
          }
        })
        .catch((error: Error | AxiosError<IErrorResponse>) => {
          alert(axios.isAxiosError(error) ? error.response?.data.error_message : error);
        });
    } else {
      await api.task
        .add(formData)
        .then((response) => {
          if (response.data.result === 'OK') {
            dispatch(addTask({ newTask: response.data.model }));
            onClose();
          }
        })
        .catch((error: Error | AxiosError<IErrorResponse>) => {
          alert(axios.isAxiosError(error) ? error.response?.data.error_message : error);
        });
    }
  };

  return optionsLoading ? (
    <Preloader />
  ) : (
    <Form className={'b-popover' + (!task ? ' create' : '')} onSubmit={handleSubmit(onSubmit)}>
      <div className="b-popover-top">
        <h3>{task ? t('addEntity.task.title.edit') : t('addEntity.task.title.add')}</h3>
        <div className="b-popover-controls">
          <>
            <Button className="btn-cancel" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button type="submit">{t('common.save')}</Button>
          </>
        </div>
      </div>
      <div className="b-popover-content">
        <Row className={fromDetail ? 'b-popover-Row' : ''}>
          <Col>
            <Row className="mb-3">
              <Col>
                <div className="b-select-light">
                  <label>{t('common.project')}</label>
                  <Select
                    options={getValidSelectOptions(projects)}
                    defaultValue={
                      defaultProject ? { ...defaultProject, value: defaultProject.id } : noProject
                    }
                    getOptionLabel={(option: any) => t(option.name)}
                    onChange={(option: any) => {
                      setProject(option.id);
                      setRootTask(0);
                      setParentTask(0);
                      setProjectTasks([]);
                      setProjectTags([]);
                      setProjectSprints([]);
                      setProjectAssignees([]);
                    }}
                    classNamePrefix="custom-select"
                  />
                </div>
              </Col>
              <Col>
                <div className="b-select-light">
                  <label>{t('common.sprint')}</label>
                  <Select
                    options={[noSprint, ...getValidSelectOptions(projectSprints)]}
                    defaultValue={
                      sprint && task && task.milestone_id
                        ? { ...task.milestone_id, value: sprint }
                        : noSprint
                    }
                    getOptionLabel={(option: any) => t(option.name)}
                    onChange={(option: any) => {
                      setSprint(option.id);
                    }}
                    isSearchable={false}
                    classNamePrefix="custom-select"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="b-select-light">
                  <label>{t('filter.priority')}</label>
                  <Select
                    options={priorities}
                    defaultValue={priorities.find((item) => priority === item.value)}
                    getOptionLabel={(option: any) => t(option.name)}
                    onChange={(option: any) => {
                      setPriority(option.value);
                    }}
                    isSearchable={false}
                    classNamePrefix="custom-select"
                  />
                </div>
              </Col>
              <Col>
                <div className="b-select-light">
                  <label>{t('filter.status')}</label>
                  <Select
                    options={statuses}
                    defaultValue={getActualStatus(status)}
                    getOptionLabel={(option: any) => t(option.name)}
                    onChange={(option: any) => {
                      setStatus(option.value);
                    }}
                    isSearchable={false}
                    classNamePrefix="custom-select"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="b-select-light">
                  <label>{t('addEntity.task.parentItem')}</label>
                  <Select
                    options={[
                      noParentTask,
                      ...projectTasks.filter(
                        (item) => item.parent_task === null || item.parent_task === 0
                      ),
                    ]}
                    defaultValue={
                      task && task.parent_task_path && task.parent_task_path.length
                        ? { ...task.parent_task_path[0], value: sprint }
                        : noParentTask
                    }
                    getOptionLabel={(option: any) => t(option.name)}
                    onChange={(option: any) => {
                      setRootTask(Number(option.value));
                    }}
                    classNamePrefix="custom-select"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              {rootTask && rootTask !== -1 ? (
                <Col>
                  <div className="b-select-light">
                    <Select
                      options={[
                        noParentTask,
                        ...projectTasks.filter((item) => item.parent_task === rootTask),
                      ]}
                      defaultValue={
                        task && task.parent_task_path && task.parent_task_path.length > 1
                          ? { ...task.parent_task_path[1], value: sprint }
                          : noParentTask
                      }
                      getOptionLabel={(option: any) => t(option.name)}
                      onChange={(option: any) => {
                        setParentTask(option.value);
                      }}
                      classNamePrefix="custom-select"
                    />
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <Row className="mb-2">
              <Col>
                <div className="b-select-light">
                  <label>{t('headerTable.ID')}</label>
                  <Select
                    options={taskTypes}
                    defaultValue={taskTypes.find((item) => type === item.value)}
                    getOptionLabel={(option: any) => t(option.name)}
                    onChange={(option: any) => {
                      setType(option.value);
                    }}
                    isSearchable={false}
                    classNamePrefix="custom-select"
                  />
                </div>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Label>{t('addEntity.task.name')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div className="b-select-light">
                  <label>{t('headerTable.tag')}</label>
                  <Select
                    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                    isMulti
                    placeholder=""
                    options={projectTags}
                    defaultValue={
                      tags && task
                        ? task.tags.map((tag) => {
                            return { ...tag, value: tag.id };
                          })
                        : []
                    }
                    getOptionLabel={(option: any) => option.name}
                    onChange={(option: any) => {
                      setTags(option.map((tag: any) => tag.value));
                    }}
                    closeMenuOnSelect={false}
                    classNamePrefix="custom-select"
                  />
                </div>
              </Col>
            </Row>
            {project ? (
              <>
                <Row className="mb-2">
                  <Col>
                    <div className="b-select-light">
                      <label>{t('filter.assignee')}</label>
                      <Select
                        options={[noAssignee, ...getValidSelectOptions(projectAssignees)]}
                        defaultValue={
                          assignee && task ? { ...task.assigned_to, value: assignee } : noAssignee
                        }
                        getOptionLabel={(option: any) => t(option.name)}
                        onChange={(option: any) => {
                          setAssignee(option.value);
                        }}
                        classNamePrefix="custom-select"
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ''
            )}

            <div className={!showExtraFields ? 'hidden-extra-fields' : ''}>
              <Row className="mb-2">
                <Col>
                  <div className="b-input-light">
                    <label>{t('filter.due')}</label>
                    <DatePicker
                      selected={due}
                      onChange={(date: Date) => setDue(date)}
                      calendarStartDay={1}
                      dateFormat="dd.MM.yyyy"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="b-select-light">
                    <label>{t('addEntity.task.estimatedTime')}</label>
                    <Select
                      options={estimatedTimeValues}
                      defaultValue={estimatedTimeValues.find(
                        (item) => estimatedTime === item.value
                      )}
                      getOptionLabel={(option: any) => t(option.name)}
                      onChange={(option: any) => {
                        setEstimatedTime(option.value);
                        if (option.value !== 'time') {
                          setEstimatedTimeHours('');
                        }
                      }}
                      isSearchable={false}
                      classNamePrefix="custom-select"
                    />
                  </div>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('common.hours')}</Form.Label>
                    <Form.Control
                      type="text"
                      onKeyPress={checkTimeFormat}
                      disabled={estimatedTime !== 'time'}
                      value={estimatedTimeHours}
                      onChange={(e) => {
                        setEstimatedTimeHours(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <div className="b-select-light">
                    <label>{t('filter.billing')}</label>
                    <Select
                      options={billings}
                      defaultValue={billings.find((item) => billing === item.value)}
                      getOptionLabel={(option: any) => t(option.name)}
                      onChange={(option: any) => {
                        setBilling(option.value);
                      }}
                      isSearchable={false}
                      classNamePrefix="custom-select"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="b-select-light">
                    <label>{t('addEntity.task.billableTime')}</label>
                    <Select
                      options={billableTimeValues}
                      defaultValue={billableTimeValues.find((item) => billableTime === item.value)}
                      getOptionLabel={(option: any) => t(option.name)}
                      onChange={(option: any) => {
                        setBillableTime(option.value);
                        if (option.value !== 'time') {
                          setBillableTimeHours('');
                        }
                      }}
                      isSearchable={false}
                      classNamePrefix="custom-select"
                    />
                  </div>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('common.hours')}</Form.Label>
                    <Form.Control
                      type="text"
                      onKeyPress={checkTimeFormat}
                      disabled={billableTime !== 'time'}
                      value={billableTimeHours}
                      onChange={(e) => {
                        setBillableTimeHours(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <div className="b-select-light">
                    <label>{t('addEntity.task.customerVisible')}</label>
                    <Select
                      options={customerVisibleValues}
                      defaultValue={customerVisibleValues.find(
                        (item) => customerVisible === item.value
                      )}
                      getOptionLabel={(option: any) => t(option.name)}
                      onChange={(option: any) => {
                        setCustomerVisible(option.value);
                      }}
                      isSearchable={false}
                      classNamePrefix="custom-select"
                    />
                  </div>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </div>
            <Row>
              <Col>
                <div className="more-fields" onClick={() => setShowExtraFields(!showExtraFields)}>
                  <img src={showExtraFields ? arrowUpImage : arrowDownImage} alt="" />
                  <p>
                    {showExtraFields
                      ? t('addEntity.task.lessOptions')
                      : t('addEntity.task.moreOptions')}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <div className="b-text-editor">
              <label>{t('textEditor.label')}</label>
              <CKEditor
                editor={Editor}
                data={description}
                onChange={(event, editor) => {
                  setDescription(editor.getData());
                }}
              />
            </div>
            <FileInput
              files={files}
              setFiles={setFiles}
              filesUploaded={filesUploaded}
              setFilesUploaded={setFilesUploaded}
              filesDeleted={filesDeleted}
              setFilesDeleted={setFilesDeleted}
            ></FileInput>
          </Col>
        </Row>
        {/*  {task ? (
            <Button type="submit" variant="primary">{t('common.save')}</Button>
          ) : ''} */}
      </div>
    </Form>
  );
};

export default TaskPopover;
