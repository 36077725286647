import React from 'react';
import './style.scss';
import { ICompany } from '../../../../store/slices/admin/companiesAndUsers/types';
import { useTranslation } from 'react-i18next';
import arrowUpImage from '../../../../assets/images/icons/arrow-up.svg';
import arrowDownImage from '../../../../assets/images/icons/arrow-down.svg';
import editImage from '../../../../assets/images/icons/edit.svg';
import { useAppDispatch } from '../../../../hooks';
import { toggleCompanyOpen } from '../../../../store/slices/admin/companiesAndUsers';
import { userRoles } from '../../../../utils/constants';

interface CompaniesTableProps {
  companies: ICompany[];
  editCompany: (id: number) => void;
  editUser: (id: number) => void;
}

const CompaniesTable: React.FC<CompaniesTableProps> = ({ companies, editCompany, editUser }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const getUserRole = (roleID: number) => {
    const userRole = userRoles.find((role) => role.id === roleID);
    return userRole ? t(userRole.name) : '?';
  };

  return (
    <div className="b-table-companies">
      <div className="b-table b-table-header">
        <div className="tr">
          <div className="td">{t('headerCompaniesTable.name')}</div>
          <div className="td">ID</div>
          <div className="td">{t('headerCompaniesTable.title')}</div>
          <div className="td">{t('headerCompaniesTable.role')}</div>
        </div>
      </div>
      <table className="b-table">
        {companies.map((company) => (
          <>
            <tr key={company.id} className="tr">
              <td className="td">
                <div
                  className="b-table-companies-expand"
                  onClick={() => {
                    dispatch(toggleCompanyOpen({ id: company.id }));
                  }}
                >
                  <img src={company.isOpen ? arrowUpImage : arrowDownImage} alt="" />
                </div>
                <div>{company.name}</div>
                <div className="b-table-companies-edit" onClick={() => editCompany(company.id)}>
                  <img src={editImage} alt="" />
                </div>
              </td>
            </tr>
            {company.isOpen &&
              company.users.map((user) => (
                <tr key={user.id} className="tr">
                  <td className="td">
                    <span className="b-table-companies-user-padding">{user.name}</span>
                    <div className="b-table-companies-edit" onClick={() => editUser(user.id)}>
                      <img src={editImage} alt="" />
                    </div>
                  </td>
                  <td className="td">{user.id}</td>
                  <td className="td">{user.title}</td>
                  <td className="td">{getUserRole(+user.role)}</td>
                </tr>
              ))}
          </>
        ))}
      </table>
    </div>
  );
};

export default CompaniesTable;
