import React, { useState } from 'react';
import { IComment } from '../../store/slices/task/types';
import editImage from '../../assets/images/icons/edit.svg';
import deleteImage from '../../assets/images/icons/wastebasket.svg';
import quoteImage from '../../assets/images/icons/link.svg';
import './style.css';
import parse from 'html-react-parser';
import { CommentEditor } from '../detailTabs/CommentsTab/CommentEditor';
import Avatar from '../avatar';
import FileList from '../files/fileList';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks';
import api from '../../services/api';
import { restoreCustomerComment, restoreTeamComment } from '../../store/slices/task/comments';
import { AxiosError } from 'axios';
import { IErrorResponse } from '../../types';
import { Permissions } from '../../types/permissions';
import PermissionsGate from '../permissionsGate';

interface ICommentProps {
  comment: IComment;
  pinnedStatus: boolean;
  onDelete: (commentId: number) => void;
  published: string;
}

export const CommentCard: React.FC<ICommentProps> = ({
  comment,
  pinnedStatus,
  onDelete,
  published,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { id: userId } = useAppSelector((state) => state.currentUser);
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const onEdit = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  const handleRestoreComment = async () => {
    try {
      await api.comments.restoreComment(comment.id, published);
      if (published === '1') {
        dispatch(restoreTeamComment(comment.id));
      } else {
        dispatch(restoreCustomerComment(comment.id));
      }
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (error.response && error.response.data.error_message) {
        alert(error.response.data.error_message);
      }
    }
  };

  if (comment.deleted) {
    return (
      <div className="comment-card comment-card_deleted">
        <div className="comment-card__message">{t('comments.commentDeleted')}</div>
        <Button onClick={handleRestoreComment} className="comment-card__restore">
          {t('comments.restore')}
        </Button>
      </div>
    );
  }

  return isEditorOpen ? (
    <CommentEditor published={published} comment={comment} closeEditorHandler={onEdit} />
  ) : (
    <div className={`comment-card ${pinnedStatus ? 'pinned' : ''}`} data-id={comment.id}>
      <div className="card-person">
        <Avatar />
        <div>
          <p className="person-name">{comment.owner.name}</p>
        </div>
        <p className="person-created">{comment.created} </p>
        <div className="action-icons">
          <PermissionsGate
            permissions={
              comment.owner.id === userId
                ? [Permissions.create_posts]
                : [Permissions.delete_other_users_posts]
            }
          >
            <img
              src={deleteImage}
              alt=""
              className="action-image "
              onClick={() => onDelete(comment.id)}
            />
          </PermissionsGate>

          <PermissionsGate
            permissions={
              comment.owner.id === userId
                ? [Permissions.create_posts]
                : [Permissions.update_other_users_posts]
            }
          >
            <img src={editImage} className=" action-image" alt="" onClick={() => onEdit()} />
          </PermissionsGate>

          <img src={quoteImage} alt="" className="action-image" />
        </div>
      </div>
      <div className="comment-content">
        <div className="comment-text">{parse(comment.message)}</div>
        {comment.files.length ? (
          <div className="comment-files">
            <hr />
            <FileList files={comment.files} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
