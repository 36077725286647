import styles from './style.module.scss';
import { Sidebar } from 'react-pro-sidebar';
import MenuSidebar from '../menuSidebar';

interface NavbarProps {
  lang: string;
  languages: string[];
  collapsed: boolean;
  setLang: (lang: string) => void;
  setCollapsed: (collabsed: boolean) => void;
}

export const Navbar: React.FC<NavbarProps> = ({
  languages,
  collapsed,
  lang,
  setLang,
  setCollapsed,
}) => {
  return (
    <div className={styles.navBarContainer}>
      <Sidebar collapsed={collapsed} collapsedWidth="68px" width="300px" backgroundColor="#e8eef4">
        <MenuSidebar
          setCollapsed={setCollapsed}
          collapsed={collapsed}
          languages={languages}
          setLang={setLang}
          lang={lang}
        />
      </Sidebar>
    </div>
  );
};
