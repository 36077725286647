import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { ICompanyCreateRequest } from '../../../services/api/endpoints/company';
import {
  createCompanyAsync,
  updateCompanyAsync,
} from '../../../store/slices/admin/companiesAndUsers';
import { checkPhoneFormat } from '../../../utils/helpers';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CompanyFormFields, ICompanyFormFields } from './types';
import { AxiosError } from 'axios';
import api from '../../../services/api';
import Preloader from '../../preloader';

interface CompanyFormProps {
  projectId?: number | null;
  closeModal: () => void;
}

const CompanyForm: React.FC<CompanyFormProps> = ({ closeModal, projectId = null }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICompanyFormFields>({
    defaultValues: {
      [CompanyFormFields.name]: '',
      [CompanyFormFields.address]: '',
      [CompanyFormFields.phone]: '',
      [CompanyFormFields.url]: '',
      [CompanyFormFields.email]: '',
      [CompanyFormFields.hourly_rate]: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    // если пришёл пропс projectId, то получаем данные для редактирования
    if (projectId) {
      const getProjectInfo = async () => {
        setLoading(true);

        await api.company
          .getInfoCompany(projectId)
          .then((response) => {
            reset({
              [CompanyFormFields.name]: response.data.name,
              [CompanyFormFields.address]: response.data.address1,
              [CompanyFormFields.phone]: response.data.phone,
              [CompanyFormFields.url]: response.data.url,
              [CompanyFormFields.email]: response.data.email,
              [CompanyFormFields.hourly_rate]: response.data.hourly_rate.toString(),
            });
          })
          .catch((error: AxiosError) => {
            alert(error.response?.data.error_message || 'Не удалось получить данные проекта');
          });

        setLoading(false);
      };

      getProjectInfo();
    }
  }, []);

  const onSubmit: SubmitHandler<ICompanyFormFields> = async (data) => {
    setPending(true);

    const params: ICompanyCreateRequest = {
      name: data.name,
      address: data.address,
      phone: data.phone,
      url: data.url,
      email: data.email,
      hourly_rate: Number(data.hourly_rate),
    };

    if (projectId) {
      await dispatch(updateCompanyAsync({ id: projectId, params: params }));
    } else {
      await dispatch(createCompanyAsync(params));
    }

    setPending(false);
    closeModal();
  };

  return loading ? (
    <Preloader />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <Row className="mb-3">
        <Col className="b-input-light">
          <Form.Label>{t('common.name')}</Form.Label>
          <Form.Control
            type="text"
            {...register(CompanyFormFields.name, { required: true })}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">{t('formErrors.required')}</Form.Control.Feedback>
        </Col>
        <Col className="b-input-light">
          <Form.Label>{t('companies.address')}</Form.Label>
          <Form.Control type="text" {...register(CompanyFormFields.address)} />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="b-input-light">
          <Form.Label>{t('common.phone')}</Form.Label>
          <Form.Control
            type="text"
            onKeyPress={checkPhoneFormat}
            {...register(CompanyFormFields.phone)}
          />
        </Col>
        <Col className="b-input-light">
          <Form.Label>Url</Form.Label>
          <Form.Control type="text" {...register(CompanyFormFields.url)} />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="b-input-light">
          <Form.Label>Email</Form.Label>
          <Form.Control type="text" {...register(CompanyFormFields.email)} />
        </Col>
        <Col className="b-input-light">
          <Form.Label>{t('companies.hourlyRate')}</Form.Label>
          <Form.Control
            type="number"
            {...register(CompanyFormFields.hourly_rate, { min: 0 })}
            isInvalid={!!errors.hourly_rate}
          />
          <Form.Control.Feedback type="invalid">
            {t('formErrors.greaterThanZero')}
          </Form.Control.Feedback>
        </Col>
      </Row>

      <div className={styles.buttons}>
        <Button variant="outline-secondary" onClick={closeModal}>
          {t('common.cancel')}
        </Button>
        <Button type="submit" disabled={pending}>
          {t('common.save')}
        </Button>
      </div>
    </Form>
  );
};

export default CompanyForm;
