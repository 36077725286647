import { axios } from '../axios';

const endpoints = {
  get: (id: number) =>
    axios.get('/file/' + id, {
      responseType: 'arraybuffer',
    }),
  deleteFiles: (ids: number[]) => axios.delete('/file', { params: { ids: ids } }),
};

export default endpoints;
