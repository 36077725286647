import { RootState } from '../../../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import {
  CompaniesState,
  CompanyDataUpdate,
  ICompany,
  ICompanyEditParams,
  ICompanyModel,
  ICompanyUser,
  IUserData,
  IUserEditParams,
} from './types';
import { AxiosError } from 'axios';
import { IErrorResponse } from '../../../../types';
import { IPagination } from '../../../../services/api/endpoints/type';
import {
  ICompanyCreateRequest,
  ICompanyInfoModel,
  ICompanyListRequest,
} from '../../../../services/api/endpoints/company';
import {
  IUsersCreateRequest,
  IUsersInfoModel,
  IUsersTableRequest,
} from '../../../../services/api/endpoints/user';

export const DEFAULT_COUNT = 50;

const initialState: CompaniesState = {
  companies: [],
  users: [],
  groupByCompany: true,
  loading: false,
  pagination: null,
  countPerPage: DEFAULT_COUNT,
  searchQuery: '',
};

export const fetchCompanies = createAsyncThunk<any, boolean, { state: RootState }>(
  'companies/fetchCompanies',
  async function (isPagination: boolean, { getState, dispatch, rejectWithValue }) {
    try {
      if (!isPagination) {
        dispatch(resetPagination());
      }
      const state = getState();

      const params: ICompanyListRequest = {
        pagination: {
          page: state.companiesAndUsers.pagination
            ? state.companiesAndUsers.pagination.page
            : undefined,
          count: state.companiesAndUsers.countPerPage || undefined,
        },
      };

      if (state.companiesAndUsers.searchQuery) {
        params.filter = { name: state.companiesAndUsers.searchQuery.trim() };
      }

      const response = await api.company.list(params);
      return response.data;
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const createCompanyAsync = createAsyncThunk<
  any,
  ICompanyCreateRequest,
  { state: RootState }
>(
  'companies/createCompany',
  async function (params: ICompanyCreateRequest, { rejectWithValue, dispatch }) {
    try {
      const response = await api.company.create(params);
      const newCompany: ICompanyInfoModel = response.data.model;

      dispatch(
        addCompany({
          id: newCompany.id,
          name: newCompany.name,
          email: newCompany.email,
          url: newCompany.url,
          users: [],
          isOpen: false,
        })
      );
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const updateCompanyAsync = createAsyncThunk<any, ICompanyEditParams, { state: RootState }>(
  'companies/updateCompany',
  async function (data: ICompanyEditParams, { rejectWithValue, dispatch }) {
    try {
      const response = await api.company.update(data.id, data.params);
      const company: ICompanyInfoModel = response.data.model;

      dispatch(
        updateCompany({
          id: company.id,
          name: company.name,
          email: company.email,
          url: company.url,
        })
      );
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const fetchUsers = createAsyncThunk<any, boolean, { state: RootState }>(
  'companies/fetchUsers',
  async function (isPagination: boolean, { getState, dispatch, rejectWithValue }) {
    try {
      if (!isPagination) {
        dispatch(resetPagination());
      }
      const state = getState();

      const params: IUsersTableRequest = {
        pagination: {
          page: state.companiesAndUsers.pagination
            ? state.companiesAndUsers.pagination.page
            : undefined,
          count: state.companiesAndUsers.countPerPage || undefined,
        },
      };

      if (state.companiesAndUsers.searchQuery) {
        params.filter = { name: state.companiesAndUsers.searchQuery.trim() };
      }

      const response = await api.user.table(params);
      return response.data;
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const createUserAsync = createAsyncThunk<any, IUsersCreateRequest, { state: RootState }>(
  'companies/createUser',
  async function (params: IUsersCreateRequest, { rejectWithValue, dispatch }) {
    try {
      const response = await api.user.create(params);
      const newUser: IUsersInfoModel = response.data.user;

      dispatch(
        addUser({
          id: newUser.id,
          name: newUser.name,
          title: newUser.title,
          role: String(newUser.role),
          organization: newUser.organization,
        })
      );
    } catch (err: any) {
      console.log(err);
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const updateUserAsync = createAsyncThunk<any, IUserEditParams, { state: RootState }>(
  'companies/updateUser',
  async function (data: IUserEditParams, { rejectWithValue, dispatch }) {
    try {
      const response = await api.user.update(data.id, data.params);
      const company: IUsersInfoModel = response.data.user;

      dispatch(
        updateUser({
          id: company.id,
          name: company.name,
          title: company.title,
          role: String(company.role),
        })
      );
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    resetPagination(state) {
      state.pagination = null;
    },
    changePage(state, action: PayloadAction<number>) {
      if (!state.pagination) return;

      if (action.payload <= state.pagination.pages_total && action.payload > 0) {
        state.pagination.page = action.payload;
      }
    },
    goToPrevPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page - 1 > 0) {
        state.pagination.page = state.pagination.page - 1;
      }
    },
    goToNextPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page + 1 <= state.pagination.pages_total) {
        state.pagination.page = state.pagination.page + 1;
      }
    },
    goToFirstPage(state) {
      if (!state.pagination) return;

      state.pagination.page = 1;
    },
    goToLastPage(state) {
      if (!state.pagination) return;

      state.pagination.page = state.pagination.pages_total;
    },
    changePaginationCount(state, action: PayloadAction<string>) {
      if (action.payload === '' || action.payload === null) {
        state.countPerPage = null;
      } else {
        state.countPerPage = Number(action.payload);
      }
    },
    switchGroup(state) {
      state.groupByCompany = !state.groupByCompany;
    },
    toggleCompanyOpen(state, action: PayloadAction<{ id: number }>) {
      const company = state.companies.find((company) => company.id === action.payload.id);
      if (company) {
        company.isOpen = !company.isOpen;
      }
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    clearSearch(state) {
      state.searchQuery = '';
    },
    addCompany(state, action: PayloadAction<ICompany>) {
      state.companies.unshift(action.payload);
    },
    updateCompany(state, action: PayloadAction<CompanyDataUpdate>) {
      const company = state.companies.find((company) => company.id === action.payload.id);
      if (company) {
        company.id = action.payload.id;
        company.name = action.payload.name;
        company.email = action.payload.email;
        company.url = action.payload.url;
      }
    },
    addUser(state, action: PayloadAction<IUserData>) {
      state.users.unshift(action.payload);
    },
    updateUser(state, action: PayloadAction<ICompanyUser>) {
      const user = state.users.find((item) => item.id === action.payload.id);
      if (user) {
        user.id = action.payload.id;
        user.name = action.payload.name;
        user.title = action.payload.title;
        user.role = action.payload.role;
      }

      state.companies.forEach((company) => {
        const userInCompany = company.users.find((user) => user.id === action.payload.id);
        if (userInCompany) {
          userInCompany.id = action.payload.id;
          userInCompany.name = action.payload.name;
          userInCompany.title = action.payload.title;
          userInCompany.role = action.payload.role;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        fetchCompanies.fulfilled,
        (state, action: PayloadAction<IPagination<ICompanyModel>>) => {
          state.pagination = action.payload.pagination;
          state.companies = action.payload.data.map((company) => ({
            ...company,
            isOpen: false,
          }));
          state.loading = false;
        }
      )
      .addCase(fetchCompanies.rejected, (state, action) => {
        alert(action.payload || 'Не удалось загрузить компании');
        state.loading = false;
      })
      .addCase(createCompanyAsync.rejected, (state, action) => {
        alert(action.payload || 'Не удалось создать компанию');
      })
      .addCase(fetchUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<IPagination<IUserData>>) => {
        state.pagination = action.payload.pagination;
        state.users = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        alert(action.payload || 'Не удалось загрузить пользователей');
        state.loading = false;
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        alert(action.payload || 'Не удалось создать пользователя');
      });
  },
});

export const {
  resetPagination,
  changePage,
  goToPrevPage,
  goToNextPage,
  goToFirstPage,
  goToLastPage,
  changePaginationCount,
  switchGroup,
  toggleCompanyOpen,
  setSearchQuery,
  clearSearch,
  addCompany,
  updateCompany,
  addUser,
  updateUser,
} = companiesSlice.actions;

export default companiesSlice.reducer;
