import React, { useState } from 'react';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import OutsideAlerter from '../../outsideAlerter';
import DatePicker from 'react-datepicker';
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { createHrs, createMins } from '../../../utils/helpers/time';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ITimereportFormFields, TimereportFormFields } from './types';
import api from '../../../services/api';
import axios, { AxiosError } from 'axios';
import { IErrorResponse } from '../../../types';
import Preloader from '../../preloader';
import { TimeReport } from '../../../services/api/endpoints/type';
import cl from './style.module.scss';
import clsx from 'clsx';

interface TimeEntryProps {
  taskId: number;
  onSave: (newTime: TimeReport) => void;
}

export const TimeEntry: React.FC<TimeEntryProps> = ({ taskId, onSave, children }) => {
  const { t } = useTranslation('common');

  const [isTimeEntryShow, setTimeEntryShow] = useState(false);
  const [pending, setPending] = useState(false);

  const {
    register,
    control,
    reset,
    trigger,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<ITimereportFormFields>({
    defaultValues: {
      [TimereportFormFields.date]: new Date(),
      [TimereportFormFields.hrs]: '0',
      [TimereportFormFields.mins]: '0',
      [TimereportFormFields.comment]: '',
    },
  });

  const closePopover = () => {
    setTimeEntryShow(false);
    reset();
  };

  const onSubmit: SubmitHandler<ITimereportFormFields> = async (data) => {
    setPending(true);

    await api.timeReports
      .setTimeEntry({
        id: taskId,
        date: dayjs(data.date).format('YYYY-MM-DD'),
        time: data.hrs + ':' + (data.mins.length <= 1 ? '0' : '') + data.mins,
        comment: data.comment,
      })
      .then((response) => {
        onSave(response.data);
        closePopover();
      })
      .catch((error: Error | AxiosError<IErrorResponse>) => {
        alert(axios.isAxiosError(error) ? error.response?.data.error_message : error);
      });

    setPending(false);
  };

  const popoverStatus = (
    <Popover className={cl.popover}>
      <OutsideAlerter handler={closePopover}>
        <Popover.Body className={cl.popoverBody}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className={cl.header}>
              <p>{t('timeEntry.timeEntry')}</p>
              <div className={cl.buttons}>
                <Button className={cl.button} variant="outline-secondary" onClick={closePopover}>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" className={cl.button} disabled={pending}>
                  {t('common.save')}
                </Button>
              </div>
            </div>

            <div className={cl.form}>
              <div className={cl.formTop}>
                <div className={cl.field}>
                  <label>{t('timeEntry.date')}</label>
                  <div className={cl.dateWrapper}>
                    <Controller
                      name={TimereportFormFields.date}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          className={clsx(cl.date, !!errors.date && cl.error)}
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          calendarStartDay={1}
                          dateFormat="dd.MM.yyyy"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className={cl.field}>
                  <label>{t('common.hoursShort')}</label>
                  <Controller
                    name={TimereportFormFields.hrs}
                    control={control}
                    rules={{
                      validate: (val: string) => {
                        return (
                          Number(getValues()[TimereportFormFields.mins]) > 0 || Number(val) > 0
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Form.Select
                        {...field}
                        className={clsx(cl.select, (!!errors.mins || !!errors.hrs) && cl.error)}
                        size="sm"
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger(TimereportFormFields.mins);
                        }}
                      >
                        {createHrs().map((hour) => (
                          <option value={hour} key={hour}>
                            {hour}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  />
                </div>

                <div className={cl.field}>
                  <label>{t('common.minutesShort')}</label>
                  <Controller
                    name={TimereportFormFields.mins}
                    control={control}
                    rules={{
                      validate: (val: string) => {
                        return Number(getValues()[TimereportFormFields.hrs]) > 0 || Number(val) > 0;
                      },
                    }}
                    render={({ field }) => (
                      <Form.Select
                        {...field}
                        className={clsx(cl.select, (!!errors.mins || !!errors.hrs) && cl.error)}
                        size="sm"
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger(TimereportFormFields.hrs);
                        }}
                      >
                        {createMins().map((min) => (
                          <option value={min} key={min}>
                            {min}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  />
                </div>
              </div>
              <div className={cl.field}>
                <label>{t('timeEntry.comment')}</label>
                <TextareaAutosize
                  {...register(TimereportFormFields.comment, { required: true })}
                  className={clsx(cl.comment, !!errors.comment && cl.error)}
                  minRows={3}
                />
              </div>
            </div>
          </Form>
        </Popover.Body>
      </OutsideAlerter>
    </Popover>
  );

  return (
    <OverlayTrigger show={isTimeEntryShow} placement="bottom" overlay={popoverStatus}>
      {pending ? (
        <Preloader size={22} />
      ) : (
        <div onClick={() => setTimeEntryShow(true)}>{children}</div>
      )}
    </OverlayTrigger>
  );
};
