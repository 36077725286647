import React, { useState } from 'react';
import { useAppSelector } from '../../hooks';
import searchImage from '../../assets/images/icons/search.svg';
import cancelImage from '../../assets/images/icons/cancel.svg';
import { useTranslation } from 'react-i18next';
import './globalSearch.css';
import SearchItemRow from './searchItemRow';
import { v4 as uuidv4 } from 'uuid';
import OutsideAlerter from '../outsideAlerter';

export enum SearchItemType {
  Task,
  Project,
  Sprint,
}

const GlobalSearch: React.FC = () => {
  const { t } = useTranslation('common');
  const { data } = useAppSelector((state) => state.tasks);
  const { projects, sprints, tasks } = data;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getUniqueKey = (item: any, type: SearchItemType) => {
    switch (type) {
      case SearchItemType.Task:
        return item.id + '-' + item.sprint_id + '-' + item.project_id;
      case SearchItemType.Sprint:
        return item.id + '-' + item.project_id;
      case SearchItemType.Project:
        return item.id;
      default:
        return uuidv4();
    }
  };

  const filterItems = (term: string, list: any, type: SearchItemType) => {
    let tempList;
    if (term === '') {
      tempList = list.filter((v: any) => !v.isUnsorted);
    } else {
      const lowerTerm = term.toLowerCase();
      tempList = list.filter(
        (v: any) =>
          (v.name.toLowerCase().indexOf(lowerTerm) >= 0 ||
            ('' + v.id).toLowerCase().indexOf(lowerTerm) >= 0) &&
          !v.isUnsorted
      );
    }
    return tempList.map((v: any) => (
      <SearchItemRow
        key={getUniqueKey(v, type)}
        id={v.id}
        name={v.name}
        type={type}
        closeSearch={() => setIsPopoverOpen(false)}
      />
    ));
  };

  const renderList = () => {
    const tasksToRender = filterItems(searchValue, tasks, SearchItemType.Task);
    const projectsToRender = filterItems(searchValue, projects, SearchItemType.Project);
    const sprintsToRender = filterItems(searchValue, sprints, SearchItemType.Sprint);
    return (
      <div>
        {tasksToRender.length !== 0 && (
          <div className="global-search-text-intermission">{t('common.tasks')}</div>
        )}
        {tasksToRender}
        {projectsToRender.length !== 0 && (
          <div className="global-search-text-intermission">{t('common.projects')}</div>
        )}
        {projectsToRender}
        {sprintsToRender.length !== 0 && (
          <div className="global-search-text-intermission">{t('common.sprints')}</div>
        )}
        {sprintsToRender}
        {tasksToRender.length === 0 &&
          projectsToRender.length === 0 &&
          sprintsToRender.length === 0 && (
            <div className="global-search-text-intermission">{t('search.nothingFound')}</div>
          )}
      </div>
    );
  };

  return (
    <div>
      <div className="input-group global-search-box">
        <OutsideAlerter
          handler={() => {
            setIsPopoverOpen(false);
          }}
        >
          <input
            className="global-search global-search-font"
            onFocus={() => {
              setIsPopoverOpen(true);
            }}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder={t('search.placeholder')}
          />
          {searchValue.length === 0 && (
            <img src={searchImage} className="global-search-button" alt="" />
          )}
          {searchValue.length !== 0 && (
            <img
              src={cancelImage}
              className="global-search-reset-button"
              onClick={() => setSearchValue('')}
              alt=""
            />
          )}
          {isPopoverOpen && <div className="global-search-popover">{renderList()}</div>}
        </OutsideAlerter>
      </div>
    </div>
  );
};

export default GlobalSearch;
