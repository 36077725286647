import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';
import ChangePriority from '../../changePriority';
import { IAvailableItemTypes, TaskType } from '../../../types';
import cl from './style.module.scss';
import ChangeStatus from '../../changeStatus';
import { projectsStatuses } from '../../../utils/constants';
import clsx from 'clsx';
import {
  changeProjectPriorityDetailAsync,
  changeProjectStatusDetailAsync,
} from '../../../store/slices/project';
import { Permissions } from '../../../types/permissions';

interface ProjectCardProps {
  onEdit: () => void;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ onEdit }) => {
  const { project } = useAppSelector((state) => state.project);
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  let arrayDisabledItems: string[] = [];

  if (project?.disabled_items.length) {
    arrayDisabledItems = project.disabled_items.split(',');
  }

  const availableItemTypes: IAvailableItemTypes = {
    [TaskType.Bug]: !arrayDisabledItems.includes(TaskType.Bug),
    [TaskType.Milestone]: !arrayDisabledItems.includes(TaskType.Milestone),
    [TaskType.Request]: !arrayDisabledItems.includes(TaskType.Request),
    [TaskType.Task]: !arrayDisabledItems.includes(TaskType.Task),
    [TaskType.Topic]: !arrayDisabledItems.includes(TaskType.Topic),
    [TaskType.UIIssue]: !arrayDisabledItems.includes(TaskType.UIIssue),
  };

  const changePriorityHandler = (newPriority: number) => {
    if (project) {
      dispatch(
        changeProjectPriorityDetailAsync({
          id: project.id,
          priority: +newPriority,
        })
      );
    }
  };

  const changeStatusHandler = (newStatus: number) => {
    if (project) {
      dispatch(
        changeProjectStatusDetailAsync({
          id: project.id,
          status: +newStatus,
        })
      );
    }
  };

  if (project === null) return null;

  return (
    <div className={cl.projectCard}>
      <div className={cl.header}>
        <div className={cl.headerName}>{t('detailPage.general')}</div>
        <div className={cl.headerName}></div>
      </div>
      <div className={cl.cardBody}>
        <div className={cl.bodyLeft}>
          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.name')}</div>
              <div className={cl.bodyValue}>{project.name}</div>
            </Col>
            <Col>
              <div className={cl.bodyParam}>{t('project.id')}</div>
              <div className={cl.bodyValue}>{project.id}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.description')}</div>
              <div className={cl.bodyValue}>{project.description}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.status')}</div>
              <div className={clsx(cl.bodyValue, cl.status)}>
                <ChangeStatus
                  status={project.status}
                  onChangeStatus={changeStatusHandler}
                  statuses={projectsStatuses}
                  permission={Permissions.update_projects}
                />
              </div>
            </Col>
            <Col>
              <div className={cl.bodyParam}>{t('project.priority')}</div>
              <div className={cl.bodyValue}>
                <ChangePriority
                  priority={project.priority}
                  onChangePriority={changePriorityHandler}
                  permission={Permissions.update_projects}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.repositoryName')}</div>
              <div className={cl.bodyValue}>{project.repository_name}</div>
            </Col>
            <Col>
              <div className={cl.bodyParam}>{t('project.organization')}</div>
              <div className={cl.bodyValue}>
                {project.organization ? project.organization.name : ''}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.hourlyRate')}</div>
              <div className={cl.bodyValue}>{project.hourly_rate}</div>
            </Col>
            <Col>
              <div className={cl.bodyParam}>{t('project.language')}</div>
              <div className={cl.bodyValue}>{project.language ? project.language : '—'}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.checkboxes.hoursShowCustomer')}</div>
              <div className={cl.bodyValue}>
                {project.hours_show_customer ? t('common.yes') : t('common.no')}
              </div>
            </Col>
            <Col>
              <div className={cl.bodyParam}>{t('project.checkboxes.autoActionsForClients')}</div>
              <div className={cl.bodyValue}>
                {project.auto_actions_for_clients ? t('common.yes') : t('common.no')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.checkboxes.showWorkedTimeToCustomer')}</div>
              <div className={cl.bodyValue}>
                {project.show_worked_time_to_customer ? t('common.yes') : t('common.no')}
              </div>
            </Col>
            <Col>
              <div className={cl.bodyParam}>{t('project.checkboxes.allowClientActions')}</div>
              <div className={cl.bodyValue}>
                {project.allow_client_actions ? t('common.yes') : t('common.no')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.checkboxes.msDatesVary')}</div>
              <div className={cl.bodyValue}>
                {project.ms_dates_vary ? t('common.yes') : t('common.no')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={clsx(cl.availableItemTypes, cl.bodyParam)}>
                {t('project.availableItemTypes')}:
              </div>
              <div className={cl.bodyValue}>
                {Object.keys(availableItemTypes).map((type) => (
                  <Form.Check
                    inline
                    checked={availableItemTypes[type as TaskType]}
                    type="checkbox"
                    id={`availableItemTypes-${type}`}
                    label={t(`taskTypes.${type}`)}
                  />
                ))}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className={cl.bodyParam}>{t('project.listTags')}</div>
              <div className={cl.bodyValue}>
                <div className={cl.tags}>
                  {project.tags.map((tag) => (
                    <span className={cl.tag}>{tag}</span>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={cl.bodyRight}></div>
      </div>
    </div>
  );
};
