import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFilterParam } from '../../../store/slices/tasks';
import { IPriority, ISelectOptions } from '../../../types';
import { priorities } from '../../../utils/constants';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const ANY_PRIORITY = '';

interface FilterProps {}

const PriorityFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { priority } = useAppSelector((state) => state.tasks.filter);

  const anyPriority: IPriority = {
    name: 'filter.anyPriorities',
    value: ANY_PRIORITY,
    color: '',
  };
  const prioritiesList: IPriority[] = [anyPriority, ...priorities];

  const [currentPriority, setCurrentPriority] = useState<ISelectOptions | null>(anyPriority);

  const onChangePriority = (priority: ISelectOptions | null) => {
    if (priority) {
      dispatch(changeFilterParam({ priority: priority.value }));
    }
  };

  useEffect(() => {
    if (priority) {
      const priorityCheck = prioritiesList.find((item) => item.value === priority);
      if (priorityCheck) {
        setCurrentPriority(priorityCheck);
      }
    }
  }, []);

  return (
    <div className="b-select-light">
      <Select
        className={styles.select}
        options={prioritiesList}
        value={currentPriority}
        getOptionLabel={(option) => t(option.name)}
        onChange={(option) => {
          setCurrentPriority(option);
          onChangePriority(option);
        }}
        isSearchable={false}
        classNamePrefix="custom-select"
      />
    </div>
  );
};

export default PriorityFilter;
