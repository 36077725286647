import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { declOfNum } from '../../utils/helpers';
import './style.css';
import filterImage from './../../assets/images/icons/filter.svg';
import ProjectFilter from '../filter/kanbanFilters/projectFilter';
import SprintFilter from '../filter/kanbanFilters/sprintFilter';
import dayjs from 'dayjs';
import { Toggle } from '../toggle';
import { changeShowAs } from '../../store/slices/tasks';
import { KanbalListEnum } from '../../store/slices/tasks/types';
import EntityCreator from '../filter/entityCreator';

interface KanbanFilterProps {}

const KanbanFilter: React.FC<KanbanFilterProps> = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { kanban, showAs } = useAppSelector((state) => state.tasks);
  const items = [KanbalListEnum.List, KanbalListEnum.Kanban];

  const functionHandler = (activeField: string) => {
    dispatch(changeShowAs(activeField as KanbalListEnum));
  };

  // const getActiveTasks = () => {
  //   const count = kanban.tasks.filter((task) => task.status !== 1 && task.status !== 4).length;
  //   return (
  //     <div className="b-kanban-filter-tasks-item">
  //       <span>{count}</span>{' '}
  //       {localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'en'
  //         ? 'active'
  //         : declOfNum(count, ['активная', 'активных', 'активных'])}
  //     </div>
  //   );
  // };

  // const getOverdueTasks = () => {
  //   const count = kanban.tasks.filter((task) => {
  //     return task.due_date !== '--' && dayjs(task.due_date).unix() < dayjs().unix();
  //   }).length;
  //   return (
  //     <div className="b-kanban-filter-tasks-item">
  //       <span>{count}</span>{' '}
  //       {localStorage.getItem('lang') === '' || localStorage.getItem('lang') === 'en'
  //         ? 'overdue'
  //         : declOfNum(count, ['просроченная', 'просроченных', 'просроченных'])}
  //     </div>
  //   );
  // };

  return (
    <div className="b-filter-cont">
      <div className="b-kanban-filter">
        <EntityCreator />
        <div className="b-kanban-filter-show">
          <div className="b-kanban-toggle">
            <Toggle
              translationPrefix="toggle."
              items={items}
              activeItem={showAs}
              handler={functionHandler}
            />
          </div>
          <SprintFilter />
        </div>
        {/*         <div className="b-kanban-filter-tasks">
          <div className="b-kanban-filter-tasks-text">{t('common.tasks')}:</div>
          {getActiveTasks()}
          {getOverdueTasks()}
        </div> */}
        <div className="b-filter-list">
          <div className="filter-icon">
            <img src={filterImage} alt="" />
          </div>
          <ProjectFilter />
        </div>
      </div>
    </div>
  );
};

export default KanbanFilter;
