import React, { useRef, useEffect } from 'react';

/**
 * Component that alerts if you click outside of it
 */

type handler = () => void;

interface OutsideAlerterProps {
  handler: handler;
  children: React.ReactNode;
}

function useOutsideAlerter(ref: React.RefObject<Element>, handler: handler) {
  function handleClickOutside(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target as Element)) {
      handler();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const OutsideAlerter: React.FC<OutsideAlerterProps> = ({ handler, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handler);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
