import { IChangeDescriptionProject } from '../../../store/slices/project/types';
import { IChangePriority, IChangeStatus, IEntity, SortDirection } from '../../../types';
import { axios } from '../axios';

type ProjectRequestType = {
  id: number;
};

type TagRequestType = {
  project_id: number;
};

type MyProjectRequestType = {
  open_only?: boolean;
};

export type SubscribersType = {
  name: string;
  id: number;
}[];

export interface IProjectsListRequest {
  filter?: {
    status?: number;
    name?: string;
  };
  pagination: {
    page?: number;
    count?: number;
  } | null;
  sort?: {
    direction?: SortDirection;
    name?: string;
  };
}

// interface IPutProject {
//   name: string;
//   organization: string;
//   priority: string;
//   status: number;
//   description: string;
//   hourly_rate: number;
//   repository_name: string;
//   allow_other_time: number;
//   hours_show_customer: number;
//   auto_actions_for_clients: number;
//   show_worked_time_to_customer: number;
//   allow_client_actions: number;
//   use_milestones: number;
//   use_tags: number;
//   ms_dates_vary: number;
//   disabled_items: string;
//   billing: number;
//   language: string;
// }

export interface INewProjectRequest {
  name: string;
  organization?: number;
  priority?: string;
  status?: number;
  description?: string;
  hourly_rate?: number;
  repository_name?: string;
  hours_show_customer?: number;
  auto_actions_for_clients?: number;
  show_worked_time_to_customer?: number;
  allow_client_actions?: number;
  ms_dates_vary?: number;
  disabled_items?: string;
  billing?: number;
  language?: string;
  tags?: string[];
}

const endpoints = {
  users: (data: ProjectRequestType) =>
    axios.get<SubscribersType>('/project/users', {
      params: data,
    }),
  tag: (data: TagRequestType) =>
    axios.get('/project/tag', {
      params: data,
    }),
  myProjects: (data: MyProjectRequestType = {}) =>
    axios.get('/project/my-projects', {
      params: data,
    }),
  projectsList: (data: IProjectsListRequest) =>
    axios.get('/project/list', {
      params: data,
    }),
  allProjects: () => axios.get<IEntity[]>('/project/get-all'),
  getInfoProject: (id: number) => axios.get(`/project/${id}`),
  add: (data: INewProjectRequest) => axios.post('/project', data),
  changeProjectPriority: (data: IChangePriority) => axios.put('/project/priority', data),
  changeProjectStatus: (data: IChangeStatus) => axios.put('/project/status', data),
  changeProjectDescription: (data: IChangeDescriptionProject) =>
    axios.put('/project/description', data),
};

export default endpoints;
