import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import attachFileImage from '../../../assets/images/icons/attach-file.svg';
import FileItem from '../fileItem';
import { IEntity } from '../../../types';
import { v4 as uuidv4 } from 'uuid';

interface FileInputProps {
  files: File[];
  setFiles: (files: File[]) => void;
  filesUploaded: IEntity[];
  setFilesUploaded: (files: IEntity[]) => void;
  filesDeleted: number[];
  setFilesDeleted: (files: number[]) => void;
}

const FileInput: React.FC<FileInputProps> = ({
  files,
  setFiles,
  filesUploaded,
  setFilesUploaded,
  filesDeleted,
  setFilesDeleted,
}) => {
  const { t } = useTranslation('common');
  const uuid = uuidv4();

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = selectedFiles ? Array.from(selectedFiles) : [];
    setFiles([...files, ...selectedFilesArray]);
  };

  const removeFile = (e: React.MouseEvent<HTMLElement>, index: number) => {
    setFiles(files.filter((file, i) => i !== index));
    e.stopPropagation();
  };

  const removeUploadedFile = (e: React.MouseEvent<HTMLElement>, id: number) => {
    setFilesUploaded(filesUploaded.filter((file) => file.id !== id));
    setFilesDeleted([...filesDeleted, id]);
    e.stopPropagation();
  };

  const GetFileUploadedList = (files: IEntity[]) => {
    return files.map((file) => (
      <FileItem key={file.id} index={file.id} name={file.name} removeFile={removeUploadedFile} />
    ));
  };

  const GetFileList = (files: File[]) => {
    return files.map((file, i) => (
      <FileItem key={i} index={i} name={file.name} removeFile={removeFile} />
    ));
  };

  return (
    <div className={styles.files}>
      <label>{t('fileUpload.label')}</label>
      <div className={styles.content}>
        <div className={styles.filesInput}>
          <label htmlFor={`task-files-${uuid}`}>
            <img src={attachFileImage} alt="" />
            <p>{t('fileUpload.attachFile')}</p>
          </label>
          <div className={styles.maxSizeMessage}>{t('fileUpload.maxSizeMessage')}</div>
          <input
            type="file"
            id={`task-files-${uuid}`}
            className={styles.fileInputHtml}
            onChange={onSelectFile}
            multiple
          />
        </div>
        {filesUploaded.length ? (
          <div className={styles.filesList}>{GetFileUploadedList(filesUploaded)}</div>
        ) : (
          ''
        )}
        {files.length ? <div className={styles.filesList}>{GetFileList(files)}</div> : ''}
      </div>
    </div>
  );
};

export default FileInput;
