import React, { useEffect, useState } from 'react';
import calendarImage from '../../../../assets/images/icons/calendar.svg';
import '../style.css';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { changeFilterParams } from '../../../../store/slices/timeReportsTab';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { due_time_report } from '../../../../utils/constants';
import { ISelectOptions, DateRange } from '../../../../types';
import dayjs from 'dayjs';
import Select from 'react-select';

const NO_DUE = -1;

interface FilterProps {}

export const TimeReportDueFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { filter, loading } = useAppSelector((state) => state.timeReportsTab);
  const noDue: ISelectOptions = {
    name: 'due.anyDate',
    value: NO_DUE,
  };
  const dueList: ISelectOptions[] = [noDue, ...due_time_report];
  const [currentDue, setCurrentDue] = useState<ISelectOptions | null>(due_time_report[4]);
  const [isShowDateDue, setShowDateDue] = useState(false);
  const [dateRangeDue, setDateRangeDue] = useState<DateRange>([
    filter.date ? new Date(filter.date) : null,
    filter.date_until ? new Date(filter.date_until) : null,
  ]);
  const [startDateDue, endDateDue] = dateRangeDue;

  const handleDueFilterChange = (option: ISelectOptions | null) => {
    if (option) {
      if (option.value === '0') {
        setShowDateDue(true);
      } else {
        if (option.value === '-1') {
          setShowDateDue(false);
          dispatch(changeFilterParams({ date_enum: '', date: '', date_until: '' }));
        } else {
          setShowDateDue(false);
          dispatch(changeFilterParams({ date_enum: option.value, date: '', date_until: '' }));
        }
      }
      setCurrentDue(option);
    }
  };

  const handleDueDatePicker = (rangeDate: DateRange) => {
    if (rangeDate[0]) {
      if (rangeDate[1]) {
        let [dateStart, dateEnd] = rangeDate;
        dateStart.setDate(dateStart.getDate());
        dateEnd.setDate(dateEnd.getDate());
        dispatch(
          changeFilterParams({
            date_enum: '0',
            date: dayjs(dateStart).format('YYYY-MM-DD'),
            date_until: dayjs(dateEnd).format('YYYY-MM-DD'),
          })
        );
      }
    } else {
      dispatch(changeFilterParams({ date_enum: '', date: '', date_until: '' }));
    }
  };

  useEffect(() => {
    if (filter.date_enum === '0') {
      setShowDateDue(true);
    }
  }, []);

  return (
    <>
      <div className="b-select-light">
        <Select
          className="b-timereport-select"
          options={dueList}
          value={currentDue}
          getOptionLabel={(option) => t(option.name)}
          onChange={(option) => {
            setCurrentDue(option);
            handleDueFilterChange(option);
          }}
          isSearchable={false}
          classNamePrefix="custom-select"
        />
      </div>
      {isShowDateDue ? (
        <div className="b-filter-field date-picker-size">
          <DatePicker
            className="dropdown-box"
            selectsRange={true}
            startDate={startDateDue}
            endDate={endDateDue}
            placeholderText={t('datePicker.placeholder')}
            onChange={(dates: DateRange) => {
              setDateRangeDue(dates);
              handleDueDatePicker(dates);
            }}
            isClearable={true}
            calendarStartDay={1}
            dateFormat="dd.MM.yyyy"
          />
          {!isShowDateDue ? (
            <div className="calendar">
              <img src={calendarImage} alt="" />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};
