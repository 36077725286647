import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import Permissions from '../../components/admin/permissions';
import { Tab, Tabs } from 'react-bootstrap';
import { useAppSelector } from '../../hooks';
import TabContent from '../../components/tabContent';
import { checkLeastOneUserAccess, checkUserAccess } from '../../utils/helpers/permissions';
import { Permissions as PermissionsEnum } from '../../types/permissions';
import Companies from '../../components/admin/companies';

enum TabsEnum {
  Companies = 'companies',
  Permissions = 'permissions',
}

interface AdminProps {}

const Admin: React.FC<AdminProps> = () => {
  const { t } = useTranslation('common');
  const { id: userId } = useAppSelector((state) => state.currentUser);
  const { loading: permissionsLoading } = useAppSelector((state) => state.permissions);
  const { loading: companiesLoading } = useAppSelector((state) => state.companiesAndUsers);

  const [currentTab, setCurrentTab] = useState(TabsEnum.Companies);

  useEffect(() => {
    document.title = t('header.menu.admin');
  }, []);

  useEffect(() => {
    let tab = TabsEnum.Companies;
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      if (Object.values(TabsEnum).includes(hash as TabsEnum)) {
        tab = hash as TabsEnum;
      }
    }

    setCurrentTab(tab);
  }, [userId]);

  useEffect(() => {
    if (currentTab) {
      window.history.replaceState(null, '', `#${currentTab}`);
    }
  }, [currentTab]);

  return (
    <div className={styles.container}>
      <Tabs activeKey={currentTab} onSelect={(nextTab) => setCurrentTab(nextTab as TabsEnum)}>
        {!(
          userId === null ||
          !checkLeastOneUserAccess([PermissionsEnum.view_companies, PermissionsEnum.view_users])
        ) ? (
          <Tab
            eventKey={TabsEnum.Companies}
            title={<TabContent text={t('admin.tabs.companies')} loading={companiesLoading} />}
          >
            <div className={styles.contentWrapper}>
              <Companies />
            </div>
          </Tab>
        ) : null}

        {!(userId === null || !checkUserAccess(PermissionsEnum.view_permissions)) ? (
          <Tab
            eventKey={TabsEnum.Permissions}
            title={<TabContent text={t('admin.tabs.permissions')} loading={permissionsLoading} />}
          >
            <div className={styles.contentWrapper}>
              <Permissions />
            </div>
          </Tab>
        ) : null}
      </Tabs>
    </div>
  );
};

export default Admin;
