import React, { useEffect, useState } from 'react';
import { IFile } from '../../types';
import api from '../../services/api';
import { onDownloadFile } from '../../utils/helpers';
import './style.css';
import { useDebounce } from '../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';

const supportedPreviews = ['jpg', 'png', 'pdf', 'svg'];

interface PreviewFileProps {
  file: IFile;
}

const PreviewFile: React.FC<PreviewFileProps> = ({ file }) => {
  const { t } = useTranslation('common');
  const [hoverFileLink, setHoverFileLink] = useState<string>('');
  const [hoverNow, setHoverNow] = useState(false);
  const debounced = useDebounce(hoverNow, 200);

  const onHoverImg = () => {
    return <img src={hoverFileLink} className="preview-file__img" alt="" />;
  };

  const onHoverPdf = () => {
    return <embed src={hoverFileLink} className="preview-file__pdf" />;
  };

  const renderPreview = () => {
    if (debounced && supportedPreviews.includes(file.extension)) {
      if (file.extension === 'pdf') {
        return onHoverPdf();
      } else {
        return onHoverImg();
      }
    }
    return null;
  };

  const onMouseEnter = () => {
    setHoverNow(true);
  };

  const onLeaveHover = () => {
    setHoverNow(false);
    setHoverFileLink('');
  };

  useEffect(() => {
    const fetchLinkAsync = async (fileId: number) => {
      if (debounced) {
        await api.files.get(fileId).then((result) => {
          if (file.extension === 'pdf') {
            setHoverFileLink(
              URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }))
            );
          } else if (file.extension === 'svg') {
            setHoverFileLink(
              URL.createObjectURL(new Blob([result.data], { type: 'image/svg+xml' }))
            );
          } else {
            setHoverFileLink(URL.createObjectURL(new Blob([result.data])));
          }
        });
      }
    };
    if (supportedPreviews.includes(file.extension)) {
      fetchLinkAsync(file.id);
    }
  }, [debounced]);

  return (
    <div
      id="preview-file"
      className="preview-file"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onLeaveHover}
    >
      <span
        className="preview-file__name"
        id={`filename${file.id}`}
        title={t('fileUpload.click')}
        onClick={(e) => {
          e.stopPropagation();
          onDownloadFile(file.id, file.original_name);
        }}
      >
        {file.original_name}
      </span>
      {renderPreview()}
    </div>
  );
};

export default PreviewFile;
