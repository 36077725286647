import React from 'react';
import styles from './style.module.scss';
import fileImage from '../../../assets/images/icons/file.svg';
import closeImage from '../../../assets/images/icons/close.svg';

interface FileItemProps {
  index: number;
  name: string;
  removeFile: (e: React.MouseEvent<HTMLElement>, index: number) => void;
}

const FileItem: React.FC<FileItemProps> = ({ index, name, removeFile }) => {
  return (
    <div className={styles.fileItem}>
      <img src={fileImage} alt="" className={styles.icon} />
      <p>{name}</p>
      <div className={styles.deleteButton} onClick={(e) => removeFile(e, index)}>
        <img src={closeImage} alt="" />
      </div>
    </div>
  );
};

export default FileItem;
