import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import cl from './style.module.scss';
import Preloader from '../../components/preloader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchProjectAsync, resetProject } from '../../store/slices/project';
import { ProjectCard } from '../../components/detailProject/projectCard';
import { DetailProjectTabs } from '../../components/detailProject/detailProjectTabs';
import { useTranslation } from 'react-i18next';

interface ProjectProps {}

const Project: React.FC<ProjectProps> = () => {
  const urlParams = useParams();
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();
  const { project, loading, error } = useAppSelector((state) => state.project);
  const [editCard, setEditCard] = useState<boolean>(false);

  const destroyProject = () => {
    dispatch(resetProject());
  };

  useEffect(() => {
    destroyProject();
    dispatch(fetchProjectAsync(Number(urlParams.id)));
    return () => {
      destroyProject();
    };
  }, [urlParams.id]);

  useEffect(() => {
    document.title = project ? project.name : `${t('common.project')} #${urlParams.id}`;
  }, [project]);

  if (error)
    return (
      <div className={cl.error}>
        <div className={cl.errorMessage}>{error}</div>
        <Link to="/projects">{t('common.backToProjects')}</Link>
      </div>
    );

  return (
    <>
      {loading.project || !project ? (
        <Preloader />
      ) : (
        <div className={cl.projectPage}>
          <ul className={cl.breadcrumbs}>
            <li>
              <Link to="/projects">{t('common.projects')}</Link>
            </li>
            <li>{project.name}</li>
          </ul>

          <div className={cl.projectDetail}>
            <div className={cl.projectForm}>
              <ProjectCard onEdit={() => setEditCard((value) => !value)} />
            </div>

            <div className={cl.detailProjectTabs}>
              <DetailProjectTabs />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Project;
