import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import api from '../../../services/api';
import DatePicker from 'react-datepicker';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import { IErrorResponse, ISelectOptions } from '../../../types';
import dayjs from 'dayjs';
import { addSprint, updateSprintAsync } from '../../../store/slices/tasks';
import { getValidSelectOption, getValidSelectOptions } from '../../../utils/helpers/selects';
import { ISprintFormFields, SprintFormFields } from './types';
import cl from './style.module.scss';
import { ISprint } from '../../../store/slices/tasks/types';
import { IMilestonesCreateRequest } from '../../../services/api/endpoints/milestone';
import clsx from 'clsx';

interface SprintPopoverProps {
  closeModal: () => void;
  sprint?: ISprint | null;
}

const getDateOffset = (date: Date, offsetInDays: number) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + offsetInDays);
};

const SprintForm: React.FC<SprintPopoverProps> = ({ closeModal, sprint = null }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { data, filter } = useAppSelector((state) => state.tasks);
  const { projects } = data;

  const [pending, setPending] = useState(false);

  const getInitialProject = (currentProject: number | string) => {
    const project = projects.find((project) => Number(currentProject) === project.id);
    return project ? getValidSelectOption(project) : null;
  };

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ISprintFormFields>({
    defaultValues: {
      [SprintFormFields.name]: sprint ? sprint.name : '',
      [SprintFormFields.project]: getInitialProject(sprint ? sprint.project_id : filter.project),
      [SprintFormFields.startDate]:
        sprint && sprint.start_date ? new Date(sprint.start_date) : null,
      [SprintFormFields.dueDate]: sprint && sprint.due_date ? new Date(sprint.due_date) : null,
    },
  });

  const watchedFieldProject = useWatch({ name: SprintFormFields.project, control });
  const watchedFieldStartDate = useWatch({ name: SprintFormFields.startDate, control });
  const watchedFieldDueDate = useWatch({ name: SprintFormFields.dueDate, control });
  console.log(control);

  useEffect(() => {
    async function setDate() {
      if (watchedFieldProject !== null && !sprint) {
        const lastSprintDate = await getLastSprintDate(watchedFieldProject.value);
        const newStartDate = lastSprintDate ? new Date(lastSprintDate) : new Date();

        setValue(SprintFormFields.startDate, getDateOffset(newStartDate, 1));
        setValue(SprintFormFields.dueDate, getDateOffset(newStartDate, 14));
      }
    }
    setDate();
  }, [watchedFieldProject, sprint]);

  const getLastSprintDate = async (project_id: number) => {
    const milestones = await api.milestone.getList(project_id);
    if (milestones.length !== 0) {
      return milestones[0].due_date;
    } else {
      return null;
    }
  };

  const onSubmit: SubmitHandler<ISprintFormFields> = async (data) => {
    setPending(true);

    const params: IMilestonesCreateRequest = {
      name: data[SprintFormFields.name],
      project_id: data[SprintFormFields.project]?.value || 0,
    };

    if (data[SprintFormFields.startDate] !== null) {
      params.start_date = dayjs(data[SprintFormFields.startDate]).format('YYYY-MM-DD');
    }
    if (data[SprintFormFields.dueDate] !== null) {
      params.due_date = dayjs(data[SprintFormFields.dueDate]).format('YYYY-MM-DD');
    }

    if (sprint) {
      const updatedSprint = { sprintId: sprint.id, sprint: params };
      dispatch(updateSprintAsync(updatedSprint));
    } else {
      await api.milestone
        .add(params)
        .then((response) => {
          if (response.data.result === 'OK') {
            const newSprint = response.data.model;
            dispatch(
              addSprint({
                newSprint: {
                  ...newSprint,
                  project_id: Number(newSprint.project_id),
                },
              })
            );
          }
        })
        .catch((error: Error | AxiosError<IErrorResponse>) => {
          alert(axios.isAxiosError(error) ? error.response?.data.error_message : error);
        });
    }

    setPending(false);
    closeModal();
  };

  console.log(errors);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={cl.container}>
      <Row className="mb-3">
        <Col className="b-input-light">
          <Form.Label>{t('addEntity.sprint.name')}</Form.Label>
          <Form.Control
            type="text"
            {...register(SprintFormFields.name, { required: true })}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">{t('formErrors.required')}</Form.Control.Feedback>
        </Col>
        <Col>
          <div className="b-select-light">
            <label>{t('common.project')}</label>
            <Controller
              name={SprintFormFields.project}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  className={clsx(cl.date, !!errors[SprintFormFields.project] && cl.error)}
                  options={getValidSelectOptions(projects)}
                  getOptionLabel={(option: ISelectOptions) => option.name}
                  classNamePrefix="custom-select"
                  placeholder={t('common.project')}
                  isDisabled={Boolean(sprint)}
                />
              )}
            />
            {sprint && <label>{t('addEntity.sprint.projectWarning')}</label>}
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="b-input-light">
            <label>{t('addEntity.sprint.startDate')}</label>
            <Controller
              name={SprintFormFields.startDate}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  className={clsx(cl.date, !!errors[SprintFormFields.startDate] && cl.error)}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  calendarStartDay={1}
                  dateFormat="dd.MM.yyyy"
                  isClearable={true}
                  maxDate={watchedFieldDueDate}
                />
              )}
            />
            {errors[SprintFormFields.startDate] && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {t('formErrors.required')}
              </div>
            )}
          </div>
        </Col>
        <Col>
          <div className="b-input-light">
            <label>{t('addEntity.sprint.due')}</label>
            <Controller
              name={SprintFormFields.dueDate}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  className={clsx(cl.date, !!errors[SprintFormFields.dueDate] && cl.error)}
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  calendarStartDay={1}
                  dateFormat="dd.MM.yyyy"
                  isClearable={true}
                  minDate={watchedFieldStartDate}
                />
              )}
            />
            {errors[SprintFormFields.dueDate] && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {t('formErrors.required')}
              </div>
            )}
          </div>
        </Col>
      </Row>

      <div className={cl.buttons}>
        <Button variant="outline-secondary" onClick={closeModal}>
          {t('common.cancel')}
        </Button>
        <Button type="submit" disabled={pending}>
          {t('common.save')}
        </Button>
      </div>
    </Form>
  );
};

export default SprintForm;
