import { ISelectOptions, TaskType } from '../../../types';

export enum ProjectFormFields {
  name = 'name',
  organization = 'organization',
  priority = 'priority',
  status = 'status',
  description = 'description',
  hourly_rate = 'hourly_rate',
  repository_name = 'repository_name',
  hours_show_customer = 'hours_show_customer',
  auto_actions_for_clients = 'auto_actions_for_clients',
  show_worked_time_to_customer = 'show_worked_time_to_customer',
  allow_client_actions = 'allow_client_actions',
  ms_dates_vary = 'ms_dates_vary',
  disabled_items = 'disabled_items',
  billing = 'billing',
  language = 'language',
  tags = 'tags',
}

export type IProjectFormFields = {
  [ProjectFormFields.name]: string;
  [ProjectFormFields.organization]: ISelectOptions<number> | null;
  [ProjectFormFields.priority]: ISelectOptions<string>;
  [ProjectFormFields.status]: ISelectOptions<number>;
  [ProjectFormFields.description]: string;
  [ProjectFormFields.hourly_rate]: string;
  [ProjectFormFields.repository_name]: string;
  [ProjectFormFields.hours_show_customer]: boolean;
  [ProjectFormFields.auto_actions_for_clients]: boolean;
  [ProjectFormFields.show_worked_time_to_customer]: boolean;
  [ProjectFormFields.allow_client_actions]: boolean;
  [ProjectFormFields.ms_dates_vary]: boolean;
  [ProjectFormFields.billing]: string;
  [ProjectFormFields.language]: ISelectOptions<string>;
  [ProjectFormFields.tags]: ISelectOptions<string>[];
  [TaskType.Bug]: boolean;
  [TaskType.Milestone]: boolean;
  [TaskType.Request]: boolean;
  [TaskType.Task]: boolean;
  [TaskType.Topic]: boolean;
  [TaskType.UIIssue]: boolean;
};
