import React, { useState } from 'react';
import './style.css';
import { useDrop } from 'react-dnd';
import { useAppDispatch } from '../../../hooks';
import { changeStatus } from '../../../store/slices/tasks';
import { ITask } from '../../../store/slices/tasks/types';
import api from '../../../services/api';
import { IErrorResponse, IStatus } from '../../../types';
import axios, { AxiosError } from 'axios';

interface ListProps {
  status: IStatus;
  tasks: JSX.Element[];
}

interface IDropItem {
  task: ITask;
}

const List: React.FC<ListProps> = ({ status, tasks }) => {
  const dispatch = useAppDispatch();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'task',
    drop: (item: IDropItem) => changeStatusDnd(item.task),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const changeStatusDnd = (task: ITask) => {
    if (task.status !== +status.value) {
      dispatch(changeStatus({ id: task.id, status: +status.value }));
      api.task
        .setStatus({
          id: task.id,
          status: status.value,
        })
        .catch((error: Error | AxiosError<IErrorResponse>) => {
          dispatch(changeStatus({ id: task.id, status: task.status }));
          alert(axios.isAxiosError(error) ? error.response?.data.error_message : error);
        });
    }
  };

  return (
    <div className="b-kanban-list" ref={drop} style={{ backgroundColor: isOver ? '#eaeaea' : '' }}>
      {tasks}
    </div>
  );
};

export default List;
