import { IFileInfoModel } from '../../../store/slices/task/types';
import { axios } from '../axios';

let qs = require('qs');

type TasksRequestType = {
  filter: {
    [key: string]: string; // TODO: рефакторинг типов
    // priority: string;
    // status: string;
    // project: string;
    // owner: string;
    // billing: string;
    // created_enum: string;
    // created: string;
    // created_until: string;
    // due_enum: string;
    // due_date: string;
    // due_date_until: string;
  };
};

export type NewTaskType = {
  project: number;
  priority: number;
  type: string;
  name: string;
  description: string;
  parent_task?: number;
  assigned_to?: number;
  status?: number;
  due_date?: string;
  billing?: number;
  billable_time?: string | null;
  estimated_time?: string;
  milestone_id?: number;
  files?: File | null | undefined;
  tags?: any[];
  published?: number;
};

export interface INewComment {
  message: string;
  isPinned: boolean;
  files: File[];
  filesDeleted: number[];
}

type AssigneeChangeDataType = {
  id: number;
  user_id: number;
};

type SetStatusType = {
  id: number;
  status: number | string;
};

type SetPriorityType = {
  id: number;
  priority: number | string;
};

type SetDueType = {
  id: number;
  due_date: string;
};

export interface ISubscriberModel {
  task_id: number;
  status: number;
  user: {
    id: number;
    name: string;
  };
}

export interface IChangeSubscriber {
  task_id: number;
  published: string;
  status: number;
  user_id: number;
}

type FilesResponse = {
  result: string;
  files: IFileInfoModel[];
};

export interface IMultipleChangeRequest {
  tasks: number[];
  project?: number;
  milestone_id?: number;
  priority?: number;
  status?: number;
  type?: string;
  assigned_to?: string;
}

const endpoints = {
  list: (data: TasksRequestType) =>
    axios.get('/task/list', {
      params: data,
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    }),
  get: (id: number | string) => axios.get('/task/' + id),
  add: (data: FormData) => axios.post('/task', data),
  edit: (id: number | string, data: FormData) => axios.put('/task/' + id, data),
  editAssignee: (data: AssigneeChangeDataType) => axios.put('/task/assignee', data),
  setStatus: (data: SetStatusType) => axios.put('/task/status', data),
  setPriority: (data: SetPriorityType) => axios.put('/task/priority', data),
  setDue: (data: SetDueType) => axios.put('/task/due', data),
  delete: (id: number) => axios.delete('/task/' + id),
  deleteGroup: (id: number[]) =>
    axios.delete('/task/delete', {
      params: {
        id: id,
      },
    }),
  getSubscribers: (task_id: number, published: number) =>
    axios.get('/task/subscribers', { params: { task_id, published } }),
  changeStatusUser: (data: IChangeSubscriber) => axios.post('/task/change-subscriber', data),
  getFiles: (id: number) => axios.get<FilesResponse>('/task/' + id + '/files'),
  multipleChange: (data: IMultipleChangeRequest) => axios.put('/task/multiple', data),
};

export default endpoints;
