import React, { useEffect, useState } from 'react';
import calendarImage from '../../../assets/images/icons/calendar.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFilterParam } from '../../../store/slices/tasks';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { created } from '../../../utils/constants';
import { DateRange, ISelectOptions } from '../../../types';
import dayjs from 'dayjs';
import Select from 'react-select';
import styles from './styles.module.scss';

const ANY_CREATED_DATE = '';

interface FilterProps {}

const CreatedFilter: React.FC<FilterProps> = () => {
  const { filter } = useAppSelector((state) => state.tasks);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  const anyCreatedDate: ISelectOptions = {
    name: 'created.anyCreatedDate',
    value: ANY_CREATED_DATE,
  };
  const createdList: ISelectOptions[] = [anyCreatedDate, ...created];

  const [currentCreated, setCurrentCreated] = useState<ISelectOptions | null>(anyCreatedDate);
  const [isShowDateCreated, setShowDateCreated] = useState(false);
  const [dateRangeCreated, setDateRangeCreated] = useState<DateRange>([
    filter.created ? new Date(filter.created) : null,
    filter.created_until ? new Date(filter.created_until) : null,
  ]);
  const [startDateCreated, endDateCreated] = dateRangeCreated;

  const handleCreatedFilterChange = (option: ISelectOptions | null) => {
    if (option) {
      if (option.value === '0') {
        setShowDateCreated(true);
      } else {
        setShowDateCreated(false);
        dispatch(changeFilterParam({ created_enum: option.value, created: '', created_until: '' }));
      }
    }
  };

  const handleCreatedDatePicker = (rangeDate: DateRange) => {
    // TODO: пересмотреть логику
    if (rangeDate[0] && rangeDate[1]) {
      let [dateStart, dateEnd] = rangeDate;
      dateStart.setDate(dateStart.getDate());
      dateEnd.setDate(dateEnd.getDate());
      dispatch(
        changeFilterParam({
          created_enum: '0',
          created: dayjs(dateStart).format('YYYY-MM-DD'),
          created_until: dayjs(dateEnd).format('YYYY-MM-DD'),
        })
      );
    } else {
      dispatch(changeFilterParam({ created_enum: '', created: '', created_until: '' }));
    }
  };

  useEffect(() => {
    if (filter.created_enum) {
      const createdCheck = createdList.find((item) => item.value === filter.created_enum);
      if (createdCheck) {
        setCurrentCreated(createdCheck);
      }
    }
    if (filter.created_enum === '0') {
      setShowDateCreated(true);
    }
  }, []);

  return (
    <>
      <div className="b-select-light">
        <Select
          className={styles.select}
          options={createdList}
          value={currentCreated}
          getOptionLabel={(option) => t(option.name)}
          onChange={(option) => {
            setCurrentCreated(option);
            handleCreatedFilterChange(option);
          }}
          classNamePrefix="custom-select"
          isSearchable={false}
        />
      </div>
      {isShowDateCreated ? (
        <div className="b-filter-field date-picker-size">
          <DatePicker
            className="dropdown-box"
            selectsRange={true}
            startDate={startDateCreated}
            endDate={endDateCreated}
            placeholderText={t('datePicker.placeholder')}
            onChange={(dates: DateRange) => {
              setDateRangeCreated(dates);
              handleCreatedDatePicker(dates);
            }}
            isClearable={true}
            calendarStartDay={1}
            dateFormat="dd.MM.yyyy"
          />
          {!startDateCreated ? (
            <div className="calendar">
              <img src={calendarImage} alt="" />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CreatedFilter;
