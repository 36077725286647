import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import wastebasketImage from '../../assets/images/icons/wastebasket.svg';
import quoteImage from '../../assets/images/icons/quote.svg';
import linkImage from '../../assets/images/icons/link.svg';
import './style.css';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  toggleGroupByParam,
  toggleEverythingChecked,
  changeShowAs,
} from '../../store/slices/tasks';
import { GroupBy, KanbalListEnum } from '../../store/slices/tasks/types';
import { useTranslation } from 'react-i18next';
import filterImage from './../../assets/images/icons/filter.svg';
import ProjectFilter from './filters/projectFilter';
import TypeFilter from './filters/typeFilter';
import StatusFilter from './filters/statusFilter';
import AssigneeFilter from './filters/assigneeFilter';
import DueFilter from './filters/dueFilter';
import CreatedFilter from './filters/createdFilter';
import BillingFilter from './filters/billingFilter';
import AuthorFilter from './filters/authorFilter';
import PriorityFilter from './filters/priorityFilter';
import { Toggle } from '../toggle';
import { fetchTasksAsync } from '../../store/slices/tasks';
import api from '../../services/api';
import { LinkPopover } from '../popovers/linkPopover';
import { getCheckedTasksIds } from '../../utils/helpers';
import { QuotePopover } from '../popovers/quotePopover';
import GroupEditingPopover from '../popovers/groupEditingPopover';
import EntityCreator from './entityCreator';
import PermissionsGate from '../permissionsGate';
import { Permissions } from '../../types/permissions';

interface FilterProps {}

const Filter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { groupBy, showAs, data } = useAppSelector((state) => state.tasks);
  const [showExtraFilters, setShowExtraFilters] = useState(false);
  const items = [KanbalListEnum.List, KanbalListEnum.Kanban];

  const checkedTasksIds = getCheckedTasksIds(data.tasks);

  const functionHandler = (activeField: string) => {
    dispatch(changeShowAs(activeField as KanbalListEnum));
  };

  const deleteTasks = async () => {
    const checkedTasks = data.tasks.filter((task) => task.isChecked === true);
    const checkedObjectsId: number[] = checkedTasks.map((obj) => obj.id);
    if (checkedObjectsId.length) {
      if (
        window.confirm(
          `${t('tasks.delete')}: ${checkedTasks
            .map((obj) => obj.type.charAt(0).toLocaleUpperCase() + '-' + obj.id)
            .join(', ')}?`
        )
      )
        try {
          if (checkedTasks.some((item) => item.has_subitems === true)) {
            alert(t('tasks.haveSubtasks'));
          } else {
            await api.task.deleteGroup(checkedObjectsId);
            dispatch(fetchTasksAsync());
          }
        } catch (err) {
          alert(err);
        }
    } else {
      alert(t('tasks.chooseTasks'));
    }
  };

  return (
    <div className="b-filter-cont sticky">
      <div className="b-filter-top">
        <div className="b-filter-top-left">
          <EntityCreator />
          <div className="b-filter-controls">
            <PermissionsGate permissions={[Permissions.delete_tasks]}>
              <img
                className={`wastebasket-image link-image ${checkedTasksIds.length ? 'active' : ''}`}
                src={wastebasketImage}
                alt=""
                onClick={deleteTasks}
              />
            </PermissionsGate>

            <PermissionsGate permissions={[Permissions.update_tasks]}>
              <GroupEditingPopover checkedTasksIds={checkedTasksIds} />
            </PermissionsGate>

            <LinkPopover linksArray={checkedTasksIds}>
              <img
                className={`link-image ${checkedTasksIds.length ? 'active' : ''}`}
                src={linkImage}
                alt=""
              />
            </LinkPopover>

            <QuotePopover linksArray={checkedTasksIds}>
              <img
                className={`link-image ${checkedTasksIds.length ? 'active' : ''}`}
                src={quoteImage}
                alt=""
              />
            </QuotePopover>
          </div>
        </div>
        <div className="b-filter-show">
          <div className="b-filter-toggle">
            <Toggle
              translationPrefix="toggle."
              items={items}
              activeItem={showAs}
              handler={functionHandler}
            />
          </div>
          <div className="b-filter-grouping">
            <p className="grouping-margin">{t('filter.groupBy')}</p>
            <Form.Check type="checkbox" id="sprints">
              <Form.Check.Input
                type="checkbox"
                checked={groupBy.sprint}
                onChange={() => {
                  dispatch(toggleEverythingChecked({ value: false }));
                  dispatch(toggleGroupByParam({ groupBy: GroupBy.Sprint }));
                  if (!groupBy.project) {
                    dispatch(toggleGroupByParam({ groupBy: GroupBy.Project }));
                  }
                }}
              />

              <Form.Check.Label className="grouping-margin">{t('common.sprint')}</Form.Check.Label>
            </Form.Check>
          </div>
        </div>
        <div className="b-filter-list">
          <div className="filter-icon">
            <img src={filterImage} alt="" />
          </div>
          <ProjectFilter />
          <TypeFilter />
          <StatusFilter />
          <AssigneeFilter />
          <Button variant="primary" onClick={() => setShowExtraFilters(!showExtraFilters)}>
            {showExtraFilters ? t('filter.lessFilters') : t('filter.moreFilters')}
          </Button>
        </div>
      </div>
      <div className={`b-filter-extra ${!showExtraFilters ? 'hidden-extra-filters' : ''}`}>
        <DueFilter />
        <CreatedFilter />
        <BillingFilter />
        <AuthorFilter />
        <PriorityFilter />
      </div>
    </div>
  );
};

export default Filter;
