import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tasksReducer from './slices/tasks';
import taskReducer from './slices/task';
import taskFilesReducer from './slices/task/files';
import usersReducer from './slices/users';
import projectsReducer from './slices/projects';
import timeReportsTabReducer from './slices/timeReportsTab';
import timeTaskReducer from './slices/task/timeReports';
import taskCommentsReducer from './slices/task/comments';
import taskActivityReducer from './slices/task/activity';
import projectReducer from './slices/project';
import currentUserReducer from './slices/currentUser';
import PermissionsReducer from './slices/admin/permissions';
import CompaniesAndUsersReducer from './slices/admin/companiesAndUsers';

export const store = configureStore({
  reducer: {
    tasks: tasksReducer,
    task: taskReducer,
    users: usersReducer,
    projects: projectsReducer,
    project: projectReducer,
    timeReportsTab: timeReportsTabReducer,
    taskTime: timeTaskReducer,
    taskFiles: taskFilesReducer,
    taskComments: taskCommentsReducer,
    taskActivity: taskActivityReducer,
    currentUser: currentUserReducer,
    permissions: PermissionsReducer,
    companiesAndUsers: CompaniesAndUsersReducer,
  },
  middleware: (getDefaultMiddleWare) => getDefaultMiddleWare({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
