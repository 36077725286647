import React from 'react';
import defaultImage from '../../assets/images/icons/person.svg';

interface AvatarProps {
  url?: string | null;
  width?: number;
  height?: number;
}

const Avatar: React.FC<AvatarProps> = ({ url = null, width = 40, height = 40 }) => {
  return <img style={{ width, height }} src={url ? url : defaultImage} alt="avatar" />;
};

export default Avatar;
