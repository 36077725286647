import { IMilestonesCreateRequest } from './../../../services/api/endpoints/milestone';
import { ITask, KanbalListEnum } from './types/index';
import { RootState } from '../../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addExtraParams } from '../../../utils/helpers';
import api from '../../../services/api';
import { v4 as uuidv4 } from 'uuid';
import {
  GroupBy,
  IProject,
  IProjectModel,
  ISprintModel,
  ISprint,
  ITaskModel,
  ITasksResponse,
  TasksState,
} from './types';
import { AxiosError } from 'axios';
import { IChangePriority, IChangeStatus, IErrorResponse } from '../../../types';

export const fetchTasksAsync = createAsyncThunk<any, void, { state: RootState }>(
  'tasks/fetchTasks',
  async function (_, { getState }) {
    const state = getState();

    const response = await api.task.list({
      filter: state.tasks.filter,
    });

    const data: ITasksResponse = response.data;
    const tasks = addExtraParams(data.tasks, state.tasks.minimisedTasksList);
    return {
      tasks,
    };
  }
);

export const fetchKanbanTasksAsync = createAsyncThunk<any, void, { state: RootState }>(
  'tasks/fetchKanbanTasks',
  async function (_, { getState }) {
    const state = getState();

    const response = await api.task.list({
      filter: {
        project: state.tasks.filter.project,
        milestone: state.tasks.kanban.filter.milestone,
      },
    });

    const data: ITasksResponse = response.data;
    const tasks = addExtraParams(data.tasks, state.tasks.minimisedTasksList);
    return {
      tasks,
    };
  }
);

export const fetchProjectsAsync = createAsyncThunk<any, void, { state: RootState }>(
  'tasks/fetchProjects',
  async function (_, { getState }) {
    const response = await api.project.myProjects({ open_only: true });
    const data: IProjectModel[] = response.data;

    const projects = data.map((project: IProjectModel) => {
      return {
        ...project,
        isChecked: false,
        isMinimised: false,
      };
    });

    return {
      projects,
    };
  }
);

export const changeTaskPriorityAsync = createAsyncThunk<any, IChangePriority, { state: RootState }>(
  'tasks/changePriorityTask',
  async function (params: IChangePriority, { rejectWithValue, dispatch }) {
    try {
      await api.task.setPriority({ id: params.id, priority: params.priority });
      dispatch(changePriority({ id: params.id, priority: +params.priority }));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changeTaskStatusAsync = createAsyncThunk<any, IChangeStatus, { state: RootState }>(
  'tasks/changeStatusTask',
  async function (params: IChangeStatus, { rejectWithValue, dispatch }) {
    try {
      await api.task.setStatus({ id: params.id, status: params.status });
      dispatch(changeStatus({ id: params.id, status: +params.status }));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const fetchSprintsAsync = createAsyncThunk<any, void, { state: RootState }>(
  'tasks/fetchSprints',
  async function (_, { getState }) {
    const state = getState();

    const response = await api.milestone.myMilestones();
    const data: ISprintModel[] = response.data;

    const sprints = data.map((sprint: ISprintModel) => {
      return {
        ...sprint,
        isChecked: false,
        isMinimised: false,
        isUnsorted: false,
      };
    });

    state.tasks.data.projects.forEach((project: IProject) => {
      sprints.push({
        id: Number(uuidv4()),
        name: 'Без спринта',
        project_id: project.id,
        isChecked: false,
        isMinimised: false,
        isUnsorted: true,
      });
    });

    return {
      sprints,
    };
  }
);

export const deleteSprintAsync = createAsyncThunk<any, ISprint, { state: RootState }>(
  'tasks/deleteSprint',
  async function (sprint: ISprint, { rejectWithValue }) {
    try {
      await api.milestone.delete(sprint.id);

      return { sprint };
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const updateSprintAsync = createAsyncThunk(
  'tasks/updateSprint',
  async (
    { sprint, sprintId }: { sprint: IMilestonesCreateRequest; sprintId: number },
    { rejectWithValue }
  ) => {
    try {
      await api.milestone.update(sprintId, sprint);
      return { sprintId, sprint };
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const initialState: TasksState = {
  filter: {
    priority: '',
    status: '',
    project: '',
    milestone: '',
    owner: '',
    billing: '',
    created_enum: '',
    created: '',
    created_until: '',
    due_enum: '',
    due_date: '',
    due_date_until: '',
    assigned_to: '',
    type: '',
  },
  showAs: KanbalListEnum.List,
  sort: {
    column: 'priority',
    direction: 0,
  },
  groupBy: {
    sprint: false,
    project: true,
  },
  data: {
    projects: [],
    sprints: [],
    tasks: [],
  },
  loading: {
    projects: false,
    sprints: false,
    tasks: false,
  },
  kanban: {
    filter: {
      milestone: '',
    },
    tasks: [],
    loading: false,
  },
  minimisedTasksList: [],
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    toggleGroupByParam(state, action: PayloadAction<{ groupBy: GroupBy }>) {
      state.groupBy[action.payload.groupBy] = !state.groupBy[action.payload.groupBy];
    },
    toggleProjectOpen(state, action: PayloadAction<{ id: number | string }>) {
      const project = state.data.projects.find((project) => project.id === action.payload.id);
      if (project) {
        project.isMinimised = !project.isMinimised;
      }
    },
    toggleSprintOpen(state, action: PayloadAction<{ id: number | string }>) {
      const sprint = state.data.sprints.find((sprint) => sprint.id === action.payload.id);
      if (sprint) {
        sprint.isMinimised = !sprint.isMinimised;
      }
    },
    toggleTaskOpen(state, action: PayloadAction<{ id: number; value: boolean }>) {
      state.minimisedTasksList = state.minimisedTasksList.find((task) => task === action.payload.id)
        ? state.minimisedTasksList.filter((task) => task !== action.payload.id)
        : [...state.minimisedTasksList, action.payload.id];

      const task = state.data.tasks.find((task) => task.id === action.payload.id);
      if (task) {
        task.isMinimised = action.payload.value;
      }
    },
    toggleProjectChecked(state, action: PayloadAction<{ id: number | string; value: boolean }>) {
      const project = state.data.projects.find((project) => project.id === action.payload.id);
      if (project) {
        project.isChecked = action.payload.value;
      }
    },
    toggleCheckedAllProjectTasks(state, action: PayloadAction<{ id: number; value: boolean }>) {
      const project = state.data.projects.find((project) => project.id === action.payload.id);
      if (project) {
        project.isChecked = action.payload.value;
        state.data.tasks.map((item) => {
          if (item.project_id === project.id) {
            item.isChecked = project.isChecked;
            item.childrenAreChecked = project.isChecked;
          }
          return item;
        });
      }
    },
    toggleCheckedAllProjectSprints(state, action: PayloadAction<{ id: number; value: boolean }>) {
      state.data.sprints
        .filter((sprint) => sprint.project_id === action.payload.id)
        .forEach((s) => {
          s.isChecked = action.payload.value;
        });
    },
    toggleSprintChecked(state, action: PayloadAction<{ id: number | string; value: boolean }>) {
      const sprint = state.data.sprints.find((sprint) => sprint.id === action.payload.id);
      if (sprint) {
        sprint.isChecked = action.payload.value;
      }
    },
    toggleCheckedAllSprintTasks(state, action: PayloadAction<{ id: number | string }>) {
      const sprint = state.data.sprints.find((sprint) => sprint.id === action.payload.id);
      if (sprint) {
        if (sprint.isUnsorted) {
          state.data.tasks.map((item) => {
            if (item.project_id === sprint.project_id && item.sprint_id === null) {
              item.isChecked = sprint.isChecked;
            }
            return item;
          });
        } else {
          state.data.tasks.map((item) => {
            if (item.sprint_id === sprint.id) {
              item.isChecked = sprint.isChecked;
            }
            return item;
          });
        }
      }
    },
    toggleCheckedAllTasks(state, action: PayloadAction<{ value: boolean }>) {
      state.data.tasks.forEach((t) => {
        t.isChecked = action.payload.value;
      });
    },
    toggleEverythingChecked(state, action: PayloadAction<{ value: boolean }>) {
      state.data.projects.forEach((t) => {
        t.isChecked = action.payload.value;
      });
      state.data.sprints.forEach((t) => {
        t.isChecked = action.payload.value;
      });
      state.data.tasks.forEach((t) => {
        t.isChecked = action.payload.value;
      });
    },
    toggleEverythingMinimized(state, action: PayloadAction<{ value: boolean }>) {
      state.data.projects.forEach((t) => {
        t.isMinimised = action.payload.value;
      });
      state.data.sprints.forEach((t) => {
        t.isMinimised = action.payload.value;
      });
      if (!action.payload.value) {
        state.data.tasks.forEach((t) => {
          t.isMinimised = action.payload.value;
        });
      }

      if (action.payload.value) {
        state.minimisedTasksList = [];
      } else {
        state.minimisedTasksList = state.data.tasks.map((item) => item.id);
      }
    },
    toggleStateCheckboxTask(state, action: PayloadAction<{ id: number }>) {
      const item = state.data.tasks.find((task) => task.id === action.payload.id);
      if (item) {
        item.isChecked = !item.isChecked;
      }
    },
    setAllChildrenTaskCheckboxes(state, action: PayloadAction<{ id: number; data: boolean }>) {
      const item = state.data.tasks.find((task) => task.id === action.payload.id);
      if (item) {
        state.data.tasks
          .filter((task) => item.id === task.parent_task)
          .forEach((subItem) => {
            subItem.isChecked = action.payload.data;
          });
      }
    },
    toggleTaskChildrenCheckbox(state, action: PayloadAction<{ id: number }>) {
      const item = state.data.tasks.find((task) => task.id === action.payload.id);
      if (item) {
        item.childrenAreChecked = !item.childrenAreChecked;
      }
    },
    changeFilterParam(state, action: PayloadAction<{ [key: string]: any }>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    changeKanbanFilterParam(state, action: PayloadAction<{ [key: string]: any }>) {
      state.kanban.filter = {
        ...state.kanban.filter,
        ...action.payload,
      };
    },
    changeSortParam(state, action: PayloadAction<{ [key: string]: any }>) {
      state.sort = {
        ...state.sort,
        ...action.payload,
      };
    },
    changeStatus(state, action: PayloadAction<{ id: number; status: number }>) {
      const item = state.data.tasks.find((task) => task.id === action.payload.id);
      if (item) {
        item.status = action.payload.status;
      }
      const kanbanItem = state.kanban.tasks.find((task) => task.id === action.payload.id);
      if (kanbanItem) {
        kanbanItem.status = action.payload.status;
      }
    },
    changePriority(state, action: PayloadAction<{ id: number; priority: number }>) {
      const task = state.data.tasks.find((task) => task.id === action.payload.id);
      if (task) {
        task.priority = action.payload.priority;
      }
      const kanbanItem = state.kanban.tasks.find((task) => task.id === action.payload.id);
      if (kanbanItem) {
        kanbanItem.priority = action.payload.priority;
      }
    },
    changeTimeEntry(state, action: PayloadAction<{ id: number; actual_time: string }>) {
      const item = state.data.tasks.find((task) => task.id === action.payload.id);
      if (item) {
        item.actual_time = action.payload.actual_time;
      }
    },
    changeDue(state, action: PayloadAction<{ id: number; due_date: string }>) {
      const item = state.data.tasks.find((task) => task.id === action.payload.id);
      if (item) {
        item.due_date = action.payload.due_date;
      }
      const kanbanItem = state.kanban.tasks.find((task) => task.id === action.payload.id);
      if (kanbanItem) {
        kanbanItem.due_date = action.payload.due_date;
      }
    },
    changeAssignee(
      state,
      action: PayloadAction<{ id: number; user_id: number; user_name: string }>
    ) {
      const item = state.data.tasks.find((task) => task.id === action.payload.id);
      if (item) {
        item.assigned_to = { id: action.payload.user_id, name: action.payload.user_name };
      }
      const kanbanItem = state.kanban.tasks.find((task) => task.id === action.payload.id);
      if (kanbanItem) {
        kanbanItem.assigned_to = { id: action.payload.user_id, name: action.payload.user_name };
      }
    },
    addTask(state, action: PayloadAction<{ newTask: ITaskModel }>) {
      const newTask = action.payload.newTask;
      state.data.tasks.unshift({
        ...newTask,
        has_subitems: false,
        isChecked: false,
        isMinimised: true,
        childrenAreChecked: false,
      });
      if (newTask.parent_task) {
        const item = state.data.tasks.find((task) => task.id === newTask.parent_task);
        if (item) {
          item.has_subitems = true;
          item.isMinimised = false;
        }
      }
    },
    addSprint(state, action: PayloadAction<{ newSprint: ISprintModel }>) {
      const newSprint = action.payload.newSprint;
      state.data.sprints.unshift({
        ...newSprint,
        isChecked: false,
        isMinimised: true,
        isUnsorted: false,
      });
    },
    changeShowAs(state, action: PayloadAction<KanbalListEnum>) {
      state.showAs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasksAsync.pending, (state, action) => {
        state.loading.tasks = true;
      })
      .addCase(fetchTasksAsync.fulfilled, (state, action: PayloadAction<{ tasks: ITask[] }>) => {
        state.data.tasks = action.payload.tasks;
        state.loading.tasks = false;
      })
      .addCase(fetchKanbanTasksAsync.pending, (state, action) => {
        state.kanban.loading = true;
      })
      .addCase(
        fetchKanbanTasksAsync.fulfilled,
        (state, action: PayloadAction<{ tasks: ITask[] }>) => {
          state.kanban.tasks = action.payload.tasks;
          state.kanban.loading = false;
        }
      )
      .addCase(fetchProjectsAsync.pending, (state, action) => {
        state.loading.projects = true;
        state.loading.tasks = true;
        state.kanban.loading = true;
      })
      .addCase(
        fetchProjectsAsync.fulfilled,
        (state, action: PayloadAction<{ projects: IProject[] }>) => {
          state.data.projects = action.payload.projects;
          if (action.payload.projects.length) {
            //для основного списка
            if (localStorage.getItem('default_project_id')) {
              const project = action.payload.projects.find(
                (item) => item.id === Number(localStorage.getItem('default_project_id'))
              );
              if (project) {
                state.filter.project = String(project.id);
              } else {
                state.filter.project = String(action.payload.projects[0].id);
              }
            } else {
              state.filter.project = String(action.payload.projects[0].id);
            }
          }
          state.loading.projects = false;
        }
      )
      .addCase(fetchSprintsAsync.pending, (state, action) => {
        state.loading.sprints = true;
      })
      .addCase(
        fetchSprintsAsync.fulfilled,
        (state, action: PayloadAction<{ sprints: ISprint[] }>) => {
          state.data.sprints = action.payload.sprints;
          state.loading.sprints = false;
        }
      )
      .addCase(deleteSprintAsync.pending, (state) => {
        state.loading.projects = true;
        state.loading.sprints = true;
      })
      .addCase(deleteSprintAsync.fulfilled, (state, action) => {
        state.loading.projects = false;
        state.loading.sprints = false;

        state.data = {
          ...state.data,
          sprints: state.data.sprints.filter((sprint) => sprint.id !== action.payload.sprint.id),
          tasks: state.data.tasks.map((task) =>
            task.sprint_id === action.payload.sprint.id ? { ...task, sprint_id: null } : task
          ),
        };
      })
      .addCase(deleteSprintAsync.rejected, (state, action) => {
        state.loading.projects = false;
        state.loading.sprints = false;

        alert(action.payload || 'Не удалось удалить спринт');
      })
      .addCase(updateSprintAsync.rejected, (state, action) => {
        state.loading.sprints = true;

        alert(action.payload || 'Не удалось обновить спринт');
      })
      .addCase(updateSprintAsync.fulfilled, (state, action) => {
        state.loading.sprints = false;

        const { name, start_date, due_date } = action.payload.sprint;
        const index = state.data.sprints.findIndex((item) => item.id === action.payload.sprintId);
        if (index !== -1) {
          state.data.sprints = state.data.sprints.map((sprint, idx) => {
            return idx === index ? { ...sprint, name, start_date, due_date } : sprint;
          });
        }
      });
  },
});

export const {
  toggleGroupByParam,
  toggleCheckedAllProjectTasks,
  toggleStateCheckboxTask,
  changeStatus,
  changePriority,
  changeTimeEntry,
  changeFilterParam,
  changeKanbanFilterParam,
  changeAssignee,
  toggleProjectOpen,
  toggleSprintOpen,
  toggleTaskChildrenCheckbox,
  setAllChildrenTaskCheckboxes,
  toggleProjectChecked,
  toggleCheckedAllTasks,
  toggleSprintChecked,
  toggleCheckedAllSprintTasks,
  toggleEverythingChecked,
  toggleCheckedAllProjectSprints,
  changeSortParam,
  toggleEverythingMinimized,
  toggleTaskOpen,
  changeDue,
  addTask,
  addSprint,
  changeShowAs,
} = tasksSlice.actions;

export default tasksSlice.reducer;
