import React from 'react';
import messageLeftImg from '../../assets/images/icons/message-left.svg';
import messageRightImg from '../../assets/images/icons/message-right.svg';
import fileImg from '../../assets/images/icons/file.svg';
import { useTranslation } from 'react-i18next';
import sortImage from '../../assets/images/icons/sort.svg';
import arrowUpImage from '../../assets/images/icons/arrow-up.svg';
import arrowDownImage from '../../assets/images/icons/arrow-down.svg';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { changeSortParam } from '../../store/slices/tasks';

interface HeaderTableProps {}

const HeaderTable: React.FC<HeaderTableProps> = () => {
  const dispatch = useAppDispatch();
  const { sort, groupBy } = useAppSelector((state) => state.tasks);
  const { t } = useTranslation('common');

  const getSourceImage = (name: string) => {
    if (sort.column === name) {
      switch (sort.direction) {
        case 0: {
          return sortImage;
        }
        case 1: {
          return arrowDownImage;
        }
        case 2: {
          return arrowUpImage;
        }
      }
    } else {
      return sortImage;
    }
  };

  const setSortMode = (name: string) => {
    if (sort.column === name) {
      switch (sort.direction) {
        case 0: {
          dispatch(changeSortParam({ column: name, direction: 1 }));
          break;
        }
        case 1: {
          dispatch(changeSortParam({ column: name, direction: 2 }));
          break;
        }
        case 2: {
          dispatch(changeSortParam({ column: name, direction: 1 }));
          break;
        }
      }
    } else {
      dispatch(changeSortParam({ column: name, direction: 1 }));
    }
  };

  return (
    <div className="b-table b-table-header table-sticky">
      <div className="tr">
        {/* <div className="td td-checkboxes">
            {groupBy.project ? <GlobalMinimizationRow></GlobalMinimizationRow> : ''}
          </div> */}
        <div className="td td-checkboxes">
          {t('headerTable.priority')}
          <img
            className="sort-image"
            src={getSourceImage('priority')}
            onClick={() => {
              setSortMode('priority');
            }}
            alt=""
          />
        </div>
        <div className="td td-status">
          {t('headerTable.status')}
          <img
            className="sort-image"
            src={getSourceImage('status')}
            onClick={() => {
              setSortMode('status');
            }}
            alt=""
          />
        </div>
        <div className="td td-id">
          {t('headerTable.ID')}
          <img
            className="sort-image"
            src={getSourceImage('id')}
            onClick={() => {
              setSortMode('id');
            }}
            alt=""
          />
        </div>
        <div className="td td-name">
          {t('headerTable.name')}
          <img
            className="sort-image"
            src={getSourceImage('name')}
            onClick={() => {
              setSortMode('name');
            }}
            alt=""
          />
        </div>
        <div className="td td-tag">{t('headerTable.tag')}</div>
        <div className="td td-message-count-team">
          <img src={messageLeftImg} alt="" />
        </div>
        <div className="td td-message-count-customer">
          <img src={messageRightImg} alt="" />
        </div>
        <div className="td td-files-count">
          <img src={fileImg} alt="" />
        </div>
        <div className="td td-assigned-to">
          {t('headerTable.assignee')}
          <img
            className="sort-image"
            src={getSourceImage('assignee')}
            onClick={() => {
              setSortMode('assignee');
            }}
            alt=""
          />
        </div>
        <div className="td td-due-date">
          {t('headerTable.due')}
          <img
            className="sort-image"
            src={getSourceImage('due')}
            onClick={() => {
              setSortMode('due');
            }}
            alt=""
          />
        </div>
        <div className="td td-time">{t('headerTable.time')}</div>
      </div>
    </div>
  );
};

export default HeaderTable;
