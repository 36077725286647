export enum Permissions {
  view_permissions = 'view_permissions',
  update_permissions = 'update_permissions',
  view_activity = 'view_activity',
  add_milestones = 'add_milestones',
  update_milestones = 'update_milestones',
  delete_milestones = 'delete_milestones',
  view_team_discussion = 'view_team_discussion',
  create_posts = 'create_posts',
  update_other_users_posts = 'update_other_users_posts',
  delete_other_users_posts = 'delete_other_users_posts',
  move_posts = 'move_posts', // ещё нет UI
  create_projects = 'create_projects',
  update_projects = 'update_projects',
  create_tasks = 'create_tasks',
  update_tasks = 'update_tasks',
  delete_tasks = 'delete_tasks',
  add_time = 'add_time',
  update_time = 'update_time',
  delete_time = 'delete_time',
  view_task_time = 'view_task_time',
  view_time_report = 'view_time_report',
  view_companies = 'view_companies',
  create_companies = 'create_companies',
  update_companies = 'update_companies',
  delete_companies = 'delete_companies',
  view_users = 'view_users',
  create_users = 'create_users',
  update_users = 'update_users',
  delete_users = 'delete_users',
}
