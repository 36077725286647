import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../style.css';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import {
  changeRigthEdit,
  getEditIdWorkedTime,
  toggleCheckbox,
} from '../../../../../store/slices/task/timeReports';
import { TimeReportTask } from '../../../../../services/api/endpoints/type';

interface ITimeTabRowProps {
  item: TimeReportTask;
  userId: number;
}

export const TimeTabRow: React.FC<ITimeTabRowProps> = ({ item, userId }) => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.taskTime);

  const onChangeCheckboxHandler = () => {
    dispatch(toggleCheckbox(item.id));
  };

  const onModalHandler = () => {
    if (item.author.id === userId) {
      dispatch(getEditIdWorkedTime(item.id));
    }
  };

  useEffect(() => {
    if (item.author.id === userId) {
      dispatch(changeRigthEdit(item.id));
    }
  }, [userId, data.timeReports]);

  return (
    <tr className="timerow">
      <td className="checkbox">
        {userId === item.author.id ? (
          <Form.Check.Input
            type="checkbox"
            checked={item.checked}
            onChange={onChangeCheckboxHandler}
          />
        ) : (
          ''
        )}
      </td>
      <td
        className={['datetab', item.author.id !== userId ? 'datetab_disabled' : ''].join(' ')}
        onClick={onModalHandler}
      >
        {item.date}
      </td>
      <td className="created">{item.created}</td>
      <td onClick={() => console.log('Перейди на страницу пользователя')} className="usertab">
        {item.author.name}
      </td>
      <td className="comment">{item.comments}</td>
      <td className="hours">{item.hours}</td>
    </tr>
  );
};
