import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { changeCurrentTab } from '../../store/slices/task';
import { DetailTabsEnum } from '../../utils/constants';
import TabContent from '../tabContent';
import { ActivityTab } from './ActivityTab';
import { CommentsTab } from './CommentsTab';
import { FilesTab } from './FilesTab';
import { TimeTab } from './TimeTab';
import { checkUserAccess } from '../../utils/helpers/permissions';
import { Permissions } from '../../types/permissions';
import PermissionsGate from '../permissionsGate';

interface DeatilTabsProps {}

export const DetailTabs: React.FC<DeatilTabsProps> = () => {
  const { t } = useTranslation('common');
  const { currentTab } = useAppSelector((state) => state.task);
  const { files, loading: loadingFiles } = useAppSelector((state) => state.taskFiles);
  const { teamComments, customerComments } = useAppSelector((state) => state.taskComments);
  const { pagination: paginationTime, loading: loadingTimeReports } = useAppSelector(
    (state) => state.taskTime
  );
  const { pagination: paginationActivity, loading: loadingActivities } = useAppSelector(
    (state) => state.taskActivity
  );
  const { id: userId } = useAppSelector((state) => state.currentUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let tab = DetailTabsEnum.commentTeam;

    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      if (Object.values(DetailTabsEnum).includes(hash as DetailTabsEnum)) {
        tab = hash as DetailTabsEnum;
      }
    }

    if (tab === DetailTabsEnum.commentTeam && !checkUserAccess(Permissions.view_team_discussion)) {
      tab = DetailTabsEnum.commentCustomer;
    }
    if (tab === DetailTabsEnum.activity && !checkUserAccess(Permissions.view_activity)) {
      tab = DetailTabsEnum.commentCustomer;
    }

    dispatch(changeCurrentTab(tab));
  }, [userId]);

  useEffect(() => {
    if (currentTab) {
      window.history.replaceState(null, '', `#${currentTab}`);
    }
  }, [currentTab]);

  // Нет возможности обернуть Tab в PermissionsGate - Bootstrap считает его невалидным чилдреном для Tabs
  // Поэтому для табов нужна отдельная функция
  const isAccessTabAllowed = (permission: Permissions) => {
    return !(userId === null || !checkUserAccess(permission));
  };

  if (!currentTab) return null;

  return (
    <div className="detailTaskTabs">
      <Tabs
        onSelect={(tab) => dispatch(changeCurrentTab(tab as DetailTabsEnum))}
        activeKey={currentTab}
      >
        {isAccessTabAllowed(Permissions.view_team_discussion) ? (
          <Tab
            eventKey={DetailTabsEnum.commentTeam}
            title={
              <TabContent
                text={`${t('detailTabs.CommTeam')}  (` + teamComments.comments.length + `)`}
                loading={teamComments.loading}
              />
            }
          >
            <CommentsTab published="1" />
          </Tab>
        ) : null}

        <Tab
          eventKey={DetailTabsEnum.commentCustomer}
          title={
            <TabContent
              text={`${t('detailTabs.Comments')} (` + customerComments.comments.length + `)`}
              loading={customerComments.loading}
            />
          }
        >
          <CommentsTab published="0" />
        </Tab>

        <Tab
          eventKey={DetailTabsEnum.files}
          title={
            <TabContent
              text={`${t('detailTabs.Files')} (${files.length})`}
              loading={loadingFiles}
            />
          }
        >
          <FilesTab />
        </Tab>

        {isAccessTabAllowed(Permissions.view_task_time) ? (
          <Tab
            eventKey={DetailTabsEnum.time}
            title={
              <TabContent
                text={`${t('detailTabs.Time')} (${paginationTime ? paginationTime.total : 0})`}
                loading={loadingTimeReports}
              />
            }
          >
            <TimeTab />
          </Tab>
        ) : null}

        {isAccessTabAllowed(Permissions.view_activity) ? (
          <Tab
            eventKey={DetailTabsEnum.activity}
            title={
              <TabContent
                text={`${t('detailTabs.Activity')} (${
                  paginationActivity ? paginationActivity.total : 0
                })`}
                loading={loadingActivities}
              />
            }
          >
            <ActivityTab />
          </Tab>
        ) : null}
      </Tabs>
    </div>
  );
};
