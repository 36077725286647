import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../..';
import { TaskFilesState } from './types';
import { IErrorResponse } from '../../../../types';
import api from '../../../../services/api';
import { IFileInfo, IFileInfoModel } from '../types';

const initialState: TaskFilesState = {
  files: [],
  loading: false,
};

const expandData = (files: IFileInfoModel[]) => {
  return files.map((file) => {
    return {
      ...file,
      checked: false,
    };
  });
};

export const fetchFilesAsync = createAsyncThunk<any, void, { state: RootState }>(
  'task/fetchFiles',
  async function (_, { rejectWithValue, getState }) {
    try {
      const state = getState();
      if (!state.task.task) return [];

      const files = await api.task.getFiles(state.task.task.id);

      return expandData(files.data.files);
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const deleteFilesAsync = createAsyncThunk<any, void, { state: RootState }>(
  'task/deleteFiles',
  async function (_, { rejectWithValue, getState }) {
    try {
      const state = getState();
      if (!state.task.task) return;

      const fileIds = state.taskFiles.files.filter((item) => item.checked).map((item) => item.id);
      if (fileIds.length === 0) return;

      const response = await api.files.deleteFiles(fileIds);

      const files = await api.task.getFiles(state.task.task.id);
      return expandData(files.data.files);
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const taskFilesSlice = createSlice({
  name: 'taskFiles',
  initialState,
  reducers: {
    toggleFileChecked(state, action: PayloadAction<number>) {
      const file = state.files.find((item) => item.id === action.payload);
      if (file) {
        file.checked = !file.checked;
      }
    },
    toggleCheckedAllFiles(state, action: PayloadAction<boolean>) {
      state.files.forEach((item) => {
        item.checked = action.payload;
      });
    },
    resetFiles() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilesAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchFilesAsync.fulfilled, (state, action: PayloadAction<IFileInfo[]>) => {
        state.files = action.payload;
        state.loading = false;
      })
      .addCase(fetchFilesAsync.rejected, (state, action) => {
        alert(action.payload || 'Не удалось получить список файлов');
        state.loading = false;
      })
      .addCase(deleteFilesAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteFilesAsync.fulfilled, (state, action: PayloadAction<IFileInfo[]>) => {
        state.files = action.payload;
        state.loading = false;
      })
      .addCase(deleteFilesAsync.rejected, (state, action) => {
        alert(action.payload || 'Произошла ошибка при удалении файла');
        state.loading = false;
      });
  },
});

export const { toggleFileChecked, toggleCheckedAllFiles, resetFiles } = taskFilesSlice.actions;

export default taskFilesSlice.reducer;
