import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IActivityModel, IActivitySort, ActivityState } from './types';
import api from '../../../../services/api';
import { RootState } from '../../..';
import { IErrorResponse } from '../../../../types';
import { IPagination } from '../../../../services/api/endpoints/type';

export const DEFAULT_COUNT = 50;

const initialState: ActivityState = {
  data: [],
  loading: false,
  pagination: null,
  filter: {
    task_id: null,
  },
  count: DEFAULT_COUNT,
  sort: null,
};

export const fetchActivityAsync = createAsyncThunk<any, boolean, { state: RootState }>(
  'activity/fetchActivityAsync',
  async function (isPagination: boolean, { rejectWithValue, getState, dispatch }) {
    if (!isPagination) {
      dispatch(resetPagination());
    }

    try {
      const state = getState();

      const params = {
        pagination: {
          page: state.taskActivity.pagination ? state.taskActivity.pagination.page : undefined,
          count: state.taskActivity.count || undefined,
        },
        sort: state.taskActivity.sort ? state.taskActivity.sort : undefined,
        filter: state.taskActivity.filter ? state.taskActivity.filter : undefined,
      };

      const response = await api.activity.getActivity(params);
      return response.data;
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resetSort(state) {
      state.sort = null;
    },
    resetPagination(state) {
      state.pagination = null;
    },
    changeSortColumn(state, action: PayloadAction<IActivitySort>) {
      state.sort = {
        [action.payload.field]: action.payload.direction,
      };
    },
    changeFilterParam(state, action: PayloadAction<{ [key: string]: any }>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },

    changePage(state, action: PayloadAction<number>) {
      if (!state.pagination) return;

      if (action.payload <= state.pagination.pages_total && action.payload > 0) {
        state.pagination.page = action.payload;
      }
    },
    goToPrevPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page - 1 > 0) {
        state.pagination.page -= 1;
      }
    },
    goToNextPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page + 1 <= state.pagination.pages_total) {
        state.pagination.page += 1;
      }
    },
    goToFirstPage(state) {
      if (!state.pagination) return;

      state.pagination.page = 1;
    },
    goToLastPage(state) {
      if (!state.pagination) return;

      state.pagination.page = state.pagination.pages_total;
    },
    changePaginationCount(state, action: PayloadAction<string>) {
      if (action.payload === '' || action.payload === null) {
        state.count = null;
      } else {
        state.count = Number(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        fetchActivityAsync.fulfilled,
        (state, action: PayloadAction<IPagination<IActivityModel>>) => {
          state.pagination = action.payload.pagination;
          state.data = action.payload.data;
          state.loading = false;
        }
      )
      .addCase(fetchActivityAsync.rejected, (state, action) => {});
  },
});

export const {
  goToLastPage,
  goToNextPage,
  changePage,
  goToPrevPage,
  changePaginationCount,
  resetSort,
  changeFilterParam,
  goToFirstPage,
  resetPagination,
  changeSortColumn,
} = activitySlice.actions;
export default activitySlice.reducer;
