import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { Button, Form } from 'react-bootstrap';
import FileInput from '../../../files/fileInput';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICommentModel } from '../../../../store/slices/task/types';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { replaceLineBreaksToBr } from '../../../../utils/helpers';
import { fetchFilesAsync } from '../../../../store/slices/task/files';
import { addCommentAsync, editCommentAsync } from '../../../../store/slices/task/comments';
import Preloader from '../../../preloader';
import { IEntity } from '../../../../types';

interface CommentEditorProps {
  comment?: ICommentModel;
  closeEditorHandler?: () => void;
  published: string;
}

export const CommentEditor: React.FC<CommentEditorProps> = ({
  comment = null,
  closeEditorHandler,
  published,
}) => {
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();
  const { task } = useAppSelector((state) => state.task);

  const [message, setMessage] = useState<string>(
    comment ? replaceLineBreaksToBr(comment.message) : ''
  );
  const [isPinned, setIsPinned] = useState<boolean>(comment ? Boolean(comment.show_on_top) : false);
  const [files, setFiles] = useState<File[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<IEntity[]>(comment ? comment.files : []);
  const [filesDeleted, setFilesDeleted] = useState<number[]>([]);
  const [pending, setPending] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async () => {
    if (!task) return;

    setPending(true);

    const formData = new FormData();

    formData.append('message', message);
    formData.append('show_on_top', String(Number(isPinned)));

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }
    }

    if (filesDeleted.length) {
      for (let i = 0; i < filesDeleted.length; i++) {
        formData.append('delete_files[]', String(filesDeleted[i]));
      }
    }

    if (comment) {
      await dispatch(
        editCommentAsync({
          data: formData,
          commentId: comment.id,
          published: published,
        })
      );
    } else {
      await dispatch(
        addCommentAsync({
          data: formData,
          taskId: task.id,
          published: published,
        })
      );
    }

    setPending(false);
    setIsPinned(false);
    setMessage('');
    setFiles([]);
    setFilesDeleted([]);
    setFilesUploaded([]);
    if (closeEditorHandler) {
      closeEditorHandler();
    }

    // Обновить вкладку файлов
    if (files.length || filesDeleted.length) {
      dispatch(fetchFilesAsync());
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="comment-editor">
        <div className="comment-editor-pinnedcheck">
          <input
            type="checkbox"
            checked={isPinned}
            onChange={() => setIsPinned(!isPinned)}
            className="comment-editor-pinnedcheck-box"
          />
          <p>{t('comments.pinnedComment')}</p>
        </div>
        <label className="comment-editor-label">
          {comment ? `${t('comments.editComment')} ${comment.id}` : t('comments.newComment')}
        </label>
        <div className="comment-editor-CK">
          <CKEditor
            editor={Editor}
            data={message}
            onChange={(event, editor) => {
              setMessage(editor.getData());
            }}
          />
        </div>
        <div className="comment-editor-fileinput">
          <FileInput
            files={files}
            setFiles={setFiles}
            filesUploaded={filesUploaded}
            setFilesUploaded={setFilesUploaded}
            filesDeleted={filesDeleted}
            setFilesDeleted={setFilesDeleted}
          />
        </div>

        <div className="comment-editor-buttons">
          {comment && closeEditorHandler ? (
            <Button className="comment-editor-cancel-button" onClick={() => closeEditorHandler()}>
              {t('comments.cancel')}
            </Button>
          ) : null}

          <Button
            type="submit"
            variant="primary"
            className="comment-editor-post-button"
            disabled={!message || pending}
          >
            {t('comments.post')}
          </Button>

          {pending ? (
            <div className="comment-preloader">
              <Preloader size={30} />
            </div>
          ) : null}
        </div>
      </div>
    </Form>
  );
};
