import { useTable, Column, useRowSelect } from 'react-table';
import cl from './style.module.scss';
import sortIcon from '../../../../assets/images/icons/sort.svg';
import arrowUpImage from '../../../../assets/images/icons/arrow-up.svg';
import arrowDownImage from '../../../../assets/images/icons/arrow-down.svg';
import { useMemo } from 'react';
import { COLUMNS } from './Columns';
import Preloader from '../../../preloader';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { changeSortColumn, resetSort } from '../../../../store/slices/task/activity';

const TableActivity = () => {
  const { loading, data, sort } = useAppSelector((state) => state.taskActivity);
  const columns: Column[] = useMemo(() => COLUMNS, []);
  const dispatch = useAppDispatch();

  const onSortTable = (column: string) => {
    if (column === 'description' || column === 'author') {
      return;
    }
    if (!sort) {
      dispatch(changeSortColumn({ field: column, direction: 'desc' }));
      return;
    }
    if (sort && Object.keys(sort)[0] !== column) {
      dispatch(changeSortColumn({ field: column, direction: 'desc' }));
      return;
    }
    if (sort) {
      dispatch(resetSort());
      if (Object.values(sort)[0] === 'asc') {
        dispatch(changeSortColumn({ field: column, direction: 'desc' }));
      } else {
        dispatch(changeSortColumn({ field: column, direction: 'asc' }));
      }
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useRowSelect
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className={cl.activityTable}>
      {loading ? (
        <Preloader />
      ) : (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    onClick={() => onSortTable(column.id)}
                    className={
                      column.id === 'description' || column.id === 'author' ? '' : cl.sortableColumn
                    }
                  >
                    <div>
                      {column.render('Header')}
                      {!(column.id === 'description' || column.id === 'author') && (
                        <span>
                          {sort && Object.keys(sort)[0] === column.id ? (
                            <img
                              src={
                                Object.values(sort)[0] === 'desc' ? arrowDownImage : arrowUpImage
                              }
                              alt=""
                            />
                          ) : (
                            <img src={sortIcon} alt="" />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default TableActivity;
