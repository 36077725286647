import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import OutsideAlerter from '../../outsideAlerter';
import editImage from '../../../assets/images/icons/edit.svg';
import styles from './style.module.scss';
import clsx from 'clsx';
import GroupEditingForm from './groupEditingForm';

interface GroupEditingPopoverProps {
  checkedTasksIds: number[];
}

const GroupEditingPopover: React.FC<GroupEditingPopoverProps> = ({ checkedTasksIds }) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const openPopover = () => {
    if (checkedTasksIds.length) {
      setIsOpenPopover(true);
    }
  };

  const closePopover = () => {
    setIsOpenPopover(false);
  };

  const popover = (
    <Popover className={styles.popover}>
      <OutsideAlerter handler={closePopover}>
        <Popover.Body>
          <GroupEditingForm
            checkedTasksIds={checkedTasksIds}
            closePopover={closePopover}
          ></GroupEditingForm>
        </Popover.Body>
      </OutsideAlerter>
    </Popover>
  );

  return (
    <OverlayTrigger show={isOpenPopover} placement="bottom-start" overlay={popover}>
      <div className={styles.container} onClick={openPopover}>
        <img
          className={clsx(styles.icon, checkedTasksIds.length > 0 && styles.active)}
          src={editImage}
          alt=""
        />
      </div>
    </OverlayTrigger>
  );
};

export default GroupEditingPopover;
