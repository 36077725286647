import React from 'react';
import { Pagination as PaginationBS } from 'react-bootstrap';
import styles from './style.module.scss';

interface PaginationProps {
  currentPage: number;
  pagesTotal: number;
  onChangePage: (page: number) => void;
  onGoToFirstPage: () => void;
  onGoToPrevPage: () => void;
  onGoToNextPage: () => void;
  onGoToLastPage: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pagesTotal,
  onChangePage,
  onGoToFirstPage,
  onGoToPrevPage,
  onGoToNextPage,
  onGoToLastPage,
}) => {
  const generatePages = () => {
    const pages: JSX.Element[] = [];
    const start = currentPage > 10 ? currentPage - 10 : 1;
    const finish = pagesTotal - currentPage <= 10 ? pagesTotal : currentPage + 10;

    for (let i = start; i <= finish; i++) {
      pages.push(
        <PaginationBS.Item key={i} active={i === currentPage} onClick={() => onChangePage(i)}>
          {i}
        </PaginationBS.Item>
      );
    }

    return pages;
  };

  if (pagesTotal === 0) return null;

  return (
    <div className={styles.pagination}>
      <PaginationBS>
        <>
          <PaginationBS.First onClick={onGoToFirstPage} disabled={currentPage === 1} />
          <PaginationBS.Prev onClick={onGoToPrevPage} disabled={currentPage === 1} />
        </>

        {currentPage > 10 && <PaginationBS.Ellipsis />}
        {generatePages()}
        {pagesTotal - currentPage > 10 && <PaginationBS.Ellipsis />}

        <>
          <PaginationBS.Next onClick={onGoToNextPage} disabled={currentPage === pagesTotal} />
          <PaginationBS.Last onClick={onGoToLastPage} disabled={currentPage === pagesTotal} />
        </>
      </PaginationBS>
    </div>
  );
};

export default Pagination;
