import React from 'react';
import './style.scss';
import person_add from '../../../../assets/images/icons/person_add.svg';
import { Modal } from '../../../modal';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { changeModalEdit } from '../../../../store/slices/task/timeReports';
import { ISubscriberModel } from '../../../../services/api/endpoints/task';
import { useTranslation } from 'react-i18next';
import { TaskSubscribers } from '../TaskSubscribers';
import { SubScriberCard } from '../TaskSubscribers/SubscriberCard';

interface CommentHeaderProps {
  subscribers: ISubscriberModel[];
  published: string;
}

export const CommentHeader: React.FC<CommentHeaderProps> = ({ subscribers, published }) => {
  const { t } = useTranslation('common');
  const { modalEdit } = useAppSelector((state) => state.taskTime);
  const dispatch = useAppDispatch();

  return (
    <div className="comments-header">
      <div className="greycorner">
        <div className="wrapper comm">
          <div className="subscribers">
            <div className="subscriber-list">
              <div className="topic">
                <p className="topic-name">Topic31972@collab.zennex.ru</p>
                <p className="subscriber-card-post">{t('comments.mailGateway')}</p>
              </div>
              {subscribers
                .filter((item) => item.status === 1)
                .map((item) => (
                  <div key={item.user.id}>
                    <SubScriberCard
                      status={item.status}
                      published={published}
                      name={item.user.name}
                      userId={item.user.id}
                    />
                  </div>
                ))}
              <img
                src={person_add}
                alt=""
                className="add-subscriber"
                onClick={() => dispatch(changeModalEdit(true))}
              />
              {modalEdit && (
                <Modal title={t('modals.subscribers.title')}>
                  <TaskSubscribers subscribers={subscribers} published={published} />
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
