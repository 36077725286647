import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CsvDownloader from 'react-csv-downloader';
import filterImage from '../../../../assets/images/icons/filter.svg';
import { Button, Modal } from 'react-bootstrap';
import cl from './style.module.scss';
import downloadArrow from '../../../../assets/images/icons/download.svg';
import { useAppSelector } from '../../../../hooks';
import { IProjectModel } from '../../../../store/slices/projects/types';
import { ProjectFilterStatus } from '../projectFilterStatus';
import ProjectForm from '../../../forms/projectForm';
import { Permissions } from '../../../../types/permissions';
import PermissionsGate from '../../../permissionsGate';

export const ProjectFilters: React.FC = () => {
  const { t } = useTranslation('common');
  const { data } = useAppSelector((state) => state.projects);

  const [modalShow, setModalShow] = useState(false);

  const csv_Headers = [
    { displayName: t('project.id'), id: 'Id' },
    { displayName: t('project.name'), id: 'Name' },
    { displayName: t('project.priority'), id: 'Priority' },
    { displayName: t('project.status'), id: 'Status' },
    { displayName: t('project.organization'), id: 'Organization' },
  ];

  const makeExportData = (projects: IProjectModel[]) => {
    return projects.map((project) => {
      return {
        Id: project.id.toString(),
        Name: project.name,
        Priority: project.priority.toString(),
        Status: project.status.toString(),
        Organization: project.organization ? project.organization.name : '',
      };
    });
  };

  const closeModal = () => setModalShow(false);
  const showModal = () => setModalShow(true);

  const modal = (
    <Modal size="lg" centered show={modalShow} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t('project.title.create')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectForm closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  );

  return (
    <div className={cl.projectFilters}>
      <div className={cl.leftCont}>
        <div className={cl.csvButton}>
          <CsvDownloader
            datas={makeExportData(data)}
            columns={csv_Headers}
            filename="CSV_EXPORT"
            separator=";"
          >
            <Button className={cl.downloadBtn}>
              <span className={cl.downloadBtnText}>{t('timeReport.exportCSV')}</span>
              <img src={downloadArrow} className={cl.downloadBtnIcon} alt="" />
            </Button>
          </CsvDownloader>
        </div>

        <PermissionsGate permissions={[Permissions.create_projects]}>
          <Button variant="primary" onClick={showModal}>
            {t('project.title.create')}
          </Button>
        </PermissionsGate>

        {modal}
      </div>

      <div className={cl.filters}>
        <div className={cl.filterIcon}>
          <img src={filterImage} alt="" />
        </div>
        <ProjectFilterStatus />
      </div>
    </div>
  );
};
