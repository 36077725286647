import { axios } from '../axios';

export type LoginRequestType = {
  username: string;
  password: string;
};

const endpoints = {
  login: (data: LoginRequestType) => axios.post("/auth/login", data),
  refreshToken: () => axios.post("/auth/refresh-token"),
  logout: () => axios.post("/auth/logout"),
};

export default endpoints;
