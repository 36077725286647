import { IChangePriority, IChangeStatus, IErrorResponse } from './../../../types/index';
import { AxiosError } from 'axios';
import { RootState } from '../../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { DetailTabsProjectEnum } from '../../../utils/constants';
import { IProjectDetailModel, ProjectState } from './types';

const initialState: ProjectState = {
  project: null,
  loading: {
    project: false,
  },
  currentTab: null,
  error: null,
};

export const fetchProjectAsync = createAsyncThunk<any, number, { state: RootState }>(
  'project/fetchProject',
  async function (id: number, { rejectWithValue }) {
    try {
      const response = await api.project.getInfoProject(id);
      // const users = await api.project.users({ id: response.data.model.project.id });
      return response.data;
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changeProjectPriorityDetailAsync = createAsyncThunk<
  any,
  IChangePriority,
  { state: RootState }
>(
  'project/changePriorityProjectDetail',
  async function (params: IChangePriority, { rejectWithValue, dispatch }) {
    try {
      await api.project.changeProjectPriority({ id: params.id, priority: params.priority });
      dispatch(changePriorityDetail(params.priority));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changeProjectStatusDetailAsync = createAsyncThunk<
  any,
  IChangeStatus,
  { state: RootState }
>(
  'project/changeStatusProjectDetail',
  async function (params: IChangeStatus, { rejectWithValue, dispatch }) {
    try {
      await api.project.changeProjectStatus({ id: params.id, status: params.status });
      dispatch(changeStatusDetail(params.status));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    changeCurrentTab(state, action: PayloadAction<DetailTabsProjectEnum>) {
      state.currentTab = action.payload;
    },
    changePriorityDetail(state, action: PayloadAction<number>) {
      if (state.project) {
        state.project.priority = action.payload;
      }
    },
    changeStatusDetail(state, action: PayloadAction<number>) {
      if (state.project) {
        state.project.status = action.payload;
      }
    },
    resetProject() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectAsync.pending, (state, action) => {
        state.loading.project = true;
      })
      .addCase(fetchProjectAsync.fulfilled, (state, action: PayloadAction<IProjectDetailModel>) => {
        state.project = action.payload;
        state.loading.project = false;
      })
      .addCase(fetchProjectAsync.rejected, (state, action) => {
        state.error = String(action.payload);
        state.loading.project = false;
      });
  },
});

export const { changeCurrentTab, changePriorityDetail, changeStatusDetail, resetProject } =
  projectSlice.actions;

export default projectSlice.reducer;
