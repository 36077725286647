import React, { useEffect, useState } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { TimeTabRow } from './TimeTabRow';
import { Form } from 'react-bootstrap';
import arrowUpImage from '../../../../assets/images/icons/arrow-up.svg';
import arrowDownImage from '../../../../assets/images/icons/arrow-down.svg';
import {
  isEverythingChecked,
  toggleEverythingChecked,
} from '../../../../store/slices/task/timeReports';
import { useGetCheckedIds } from '../../../../utils/helpers';
interface TimeTabTableComponentProps {
  setSelectedSort: (selectedSort: string) => void;
  userId: number;
}

export const TaskTimeTable: React.FC<TimeTabTableComponentProps> = ({
  setSelectedSort,
  userId,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.taskTime.data.timeReports);
  const { loading, isAllChecked } = useAppSelector((state) => state.taskTime);
  const [currentSortColumn, setCurrentSortCollumn] = useState<number>(0);
  const checkedIds = useGetCheckedIds();

  const handleChangeAllCheckbox = () => {
    if (isAllChecked === false) {
      dispatch(isEverythingChecked(true));
      dispatch(toggleEverythingChecked(true));
    } else {
      dispatch(isEverythingChecked(false));
      dispatch(toggleEverythingChecked(false));
    }
  };

  const sortHandler = (id: number) => {
    if (id !== currentSortColumn) {
      setSelectedSort(`${id}`);
      setCurrentSortCollumn(id);
    } else {
      setSelectedSort(`-${id}`);
      setCurrentSortCollumn(-id);
    }
  };

  useEffect(() => {
    if (checkedIds?.length === data.filter((el) => el.edit === true).length && checkedIds.length)
      dispatch(isEverythingChecked(true));
    else dispatch(isEverythingChecked(false));
  }, [checkedIds?.length, data.length]);

  if (!data.length && !loading)
    return <div className="tablewrapper_empty">{t('timeEntry.emptyTime')}</div>;

  return (
    <div className="tablewrapper">
      <table className="tab-table">
        <thead>
          <tr className="tab-header">
            <th
              className={[
                'checkbox headertab',
                data.find((el) => el.edit === false) ? '' : 'headertab_disabled',
              ].join(' ')}
            >
              <Form.Check.Input
                disabled={!data.length || data.find((el) => el.edit === true) ? false : true}
                checked={isAllChecked}
                onChange={handleChangeAllCheckbox}
              />
            </th>
            <th onClick={() => sortHandler(1)} className="datetab headertab">
              {' '}
              {t('timeEntry.date')}
              <img
                className="icon-sort"
                src={
                  currentSortColumn === 1
                    ? arrowUpImage
                    : currentSortColumn === -1
                    ? arrowDownImage
                    : undefined
                }
                alt=""
              />
            </th>
            <th onClick={() => sortHandler(2)} className="created headertab">
              {t('filter.created')}
              <img
                className="icon-sort"
                src={
                  currentSortColumn === 2
                    ? arrowUpImage
                    : currentSortColumn === -2
                    ? arrowDownImage
                    : undefined
                }
                alt=""
              />
            </th>
            <th onClick={() => sortHandler(3)} className="usertab headertab">
              {t('common.user')}
              <img
                className="icon-sort"
                src={
                  currentSortColumn === 3
                    ? arrowUpImage
                    : currentSortColumn === -3
                    ? arrowDownImage
                    : undefined
                }
                alt=""
              />
            </th>
            <th className="comment headertab">
              {<div style={{ cursor: 'default', color: 'grey' }}>{t('timeEntry.comment')}</div>}
            </th>
            <th onClick={() => sortHandler(5)} className="hours">
              {t('headerTable.time')}
              <img
                className="icon-sort"
                src={
                  currentSortColumn === 5
                    ? arrowUpImage
                    : currentSortColumn === -5
                    ? arrowDownImage
                    : undefined
                }
                alt=""
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length
            ? data.map((item) => <TimeTabRow userId={userId} item={item} key={item.id} />)
            : null}
        </tbody>
      </table>
    </div>
  );
};
