import { OverlayTrigger, Popover } from 'react-bootstrap';
import OutsideAlerter from '../outsideAlerter';
import { useTranslation } from 'react-i18next';
import { ISelectOptions } from '../../types';

interface RadioButtonListProps {
  list: ISelectOptions<string | number>[];
  callback: any;
  checked: string | number;
  isStatusShow: boolean;
  setStatusShow: any;
  string?: boolean;
  arrowVisible?: boolean;
}

const RadioButtonList: React.FC<RadioButtonListProps> = ({
  list,
  callback,
  checked,
  isStatusShow,
  setStatusShow,
  string = false,
  arrowVisible = false,
  children,
}) => {
  const { t } = useTranslation('common');

  const statusChangeHandler = (status: string | number) => {
    setStatusShow(false);
    callback(status);
  };

  const createItems = (options: ISelectOptions<string | number>[]) => {
    return (
      <>
        {options.map((item) => (
          <div className="status-row" key={item.value}>
            <div className="form-check form-check-status-row">
              <input
                className="form-check-input input-status"
                type="radio"
                name="status"
                id={`radio-button-item-${String(item.value)}`}
                onClick={() => statusChangeHandler(item.value)}
                checked={string ? checked === String(item.value) : checked === Number(item.value)}
                readOnly
              />
              <label
                className={`form-check-label ${
                  string
                    ? checked === String(item.value)
                      ? 'bold-font'
                      : 'normal-font'
                    : checked === Number(item.value)
                    ? 'bold-font'
                    : 'normal-font'
                }`}
                htmlFor={`radio-button-item-${String(item.value)}`}
              >
                {t(item.name)}
              </label>
            </div>
          </div>
        ))}
      </>
    );
  };

  const popoverStatus = (
    <Popover
      id="popover-basic"
      className={arrowVisible ? 'popover-status popover-status-hidden' : 'popover-status'}
    >
      <OutsideAlerter handler={() => setStatusShow(false)}>
        <Popover.Body className="popover-status-body">
          <div className="radio-button-list">{createItems(list)}</div>
        </Popover.Body>
      </OutsideAlerter>
    </Popover>
  );

  return (
    <OverlayTrigger show={isStatusShow} placement="bottom" overlay={popoverStatus}>
      <div onClick={() => setStatusShow(true)}>{children}</div>
    </OverlayTrigger>
  );
};

export default RadioButtonList;
