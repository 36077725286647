import React, { useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import { changeFilterParam } from '../../../store/slices/tasks';
import { ISelectOptions } from '../../../types';
import { statuses } from '../../../utils/constants';
import Select, { GroupProps, components } from 'react-select';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

interface FilterProps {}

const groupStyles = {
  borderRadius: '5px',
  background: '#f2fcff',
};

interface GroupedOption {
  readonly label: string;
  readonly options: readonly ISelectOptions<string>[];
}

const StatusFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');

  const anyStatus: ISelectOptions<string> = {
    name: 'filter.anyStatus',
    value: '',
  };
  const activeStatuses: ISelectOptions<string> = {
    name: 'filter.anyActiveStatus',
    value: 'all_active',
  };
  const inactiveStatuses: ISelectOptions<string> = {
    name: 'filter.anyInactiveStatus',
    value: 'all_inactive',
  };

  const activeStatusesList: ISelectOptions<string>[] = [
    activeStatuses,
    ...statuses.filter((item) => item.type === 'active'),
  ];
  const inActiveStatusesList: ISelectOptions<string>[] = [
    inactiveStatuses,
    ...statuses.filter((item) => item.type === 'inActive'),
  ];

  const anyStatusList: ISelectOptions<string>[] = [anyStatus];
  const [currentStatus, setCurrentStatus] = useState<ISelectOptions<string> | null>(anyStatus);

  const groupedOptions: readonly GroupedOption[] = [
    {
      label: '',
      options: anyStatusList,
    },
    {
      label: t('filter.activeStatuses'),
      options: activeStatusesList,
    },
    {
      label: t('filter.inactiveStatuses'),
      options: inActiveStatusesList,
    },
  ];

  const handleStatusFilterChange = (status: ISelectOptions<string> | null) => {
    if (status) {
      dispatch(changeFilterParam({ status: status.value }));
      setCurrentStatus(status);
    }
  };

  const Group = (props: GroupProps<ISelectOptions<string>, false>) => (
    <div style={groupStyles}>
      <components.Group {...props} />
    </div>
  );

  return (
    <div className="b-select-light">
      <Select
        className={styles.select}
        styles={{
          group: (baseStyles) => ({
            ...baseStyles,
            paddingBottom: 5,
            paddingTop: 0,
            backgroundColor: '#fff',
          }),
          groupHeading: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: 11,
            paddingLeft: 10,
            paddingRight: 10,
          }),
        }}
        components={{ Group }}
        options={groupedOptions}
        value={currentStatus}
        getOptionLabel={(option) => t(option.name)}
        onChange={(option) => {
          setCurrentStatus(option);
          handleStatusFilterChange(option);
        }}
        isSearchable={false}
        classNamePrefix="custom-select"
      />
    </div>
  );
};

export default StatusFilter;
