import { IPagination, TimeReport } from '../../../../services/api/endpoints/type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IEditWorkedTime, IModalParams, IMultyplyEditWorkedTime, TaskTimeState } from './types';
import { RootState } from '../../..';
import { ITimeReportTaskParams } from '../../../../services/api/endpoints/timeReport';
import api from '../../../../services/api';
import { TimeReportTask } from '../../../../services/api/endpoints/type';
import { IProjectTask } from '../types';

export const DEFAULT_COUNT = 50;

const initialState: TaskTimeState = {
  filter: {
    date: '',
    date_until: '',
    user: 0,
    date_enum: 0,
  },
  modalEdit: false,
  pagination: null,
  count: DEFAULT_COUNT,
  loading: false,
  data: {
    timeReports: [],
  },
  modalParams: {
    startDate: undefined,
    data: [],
    hrs: '0',
    mins: '0',
    time: '0:0',
    comment: '',
  },
  projectTasks: [],
  isAllChecked: false,
  checkedId: null,
  // sort: {
  //   date: 'desc',
  // },
};

export const fetchTimeTaskAsync = createAsyncThunk<any, boolean, { state: RootState }>(
  'task/fetchTimeTask',
  async function (isPagination: boolean, { getState, dispatch }) {
    if (!isPagination) {
      dispatch(resetPagination());
    }
    const state = getState();

    const params: ITimeReportTaskParams = {
      filter: state.taskTime.filter,
      pagination: {
        page: state.taskTime.pagination ? state.taskTime.pagination.page : undefined,
        count: state.taskTime.count || undefined,
      },
      // sort: state.taskTime.sort,
    };

    if (state.task.task?.id) {
      const response = await api.timeReports.getTaskTime(state.task.task.id, params);
      const timeReports: TimeReportTask[] = response.data.map((el) => ({
        ...el,
        edit: false,
        checked: false,
      }));

      return {
        pagination: response.pagination,
        data: timeReports,
      };
    }
  }
);

const taskTimeSlice = createSlice({
  name: 'taskTime',
  initialState,
  reducers: {
    resetPagination(state) {
      state.pagination = null;
    },
    changePage(state, action: PayloadAction<number>) {
      if (!state.pagination) return;

      if (action.payload <= state.pagination.pages_total && action.payload > 0) {
        state.pagination.page = action.payload;
      }
    },
    goToPrevPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page - 1 > 0) {
        state.pagination.page = state.pagination.page - 1;
      }
    },
    goToNextPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page + 1 <= state.pagination.pages_total) {
        state.pagination.page = state.pagination.page + 1;
      }
    },
    addWorkedTime(state, action: PayloadAction<TimeReport>) {
      state.data.timeReports = [
        {
          ...action.payload,
          edit: false,
          checked: false,
        },
        ...state.data.timeReports,
      ];
    },
    changeTaskFilterParam(state, action: PayloadAction<{ [key: string]: any }>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    toggleEverythingChecked(state, action: PayloadAction<boolean>) {
      state.data.timeReports
        .filter((el) => el.edit === true)
        .map((el: TimeReportTask) => (el.checked = action.payload));
    },
    // changeSortParam(state, action: PayloadAction<{ [key: string]: any }>) {
    //   state.sort = {
    //     ...state.sort,
    //     ...action.payload,
    //   };
    // },
    changePaginationParam(state, action: PayloadAction<{ [key: string]: any }>) {
      if (state.pagination) {
        state.pagination = {
          ...state.pagination,
          ...action.payload,
        };
      }
    },
    changeWorkedTime(state, action: PayloadAction<IEditWorkedTime>) {
      const date =
        action.payload.startDate.getFullYear() +
        '-' +
        ('0' + (action.payload.startDate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + action.payload.startDate.getDate()).slice(-2);
      let editTime = state.data.timeReports.find((el) => el.id === action.payload.idWorkedTime);
      if (editTime) {
        if (action.payload.isChild) {
          editTime.date = date;
          editTime.comments = action.payload.comment;
          let localeTime;
          localeTime =
            action.payload.time.split(':')[1] === '0' || action.payload.time.split(':')[1] === '5'
              ? action.payload.time.split(':')[0] + ':0' + action.payload.time.split(':')[1]
              : action.payload.time;
          editTime.hours = localeTime;
        } else {
          state.data.timeReports = state.data.timeReports.filter((el) => el !== editTime);
        }
      }
    },
    changeMultiplyWorkedTime(state, action: PayloadAction<IMultyplyEditWorkedTime>) {
      const date =
        action.payload.startDate.getFullYear() +
        '-' +
        ('0' + (action.payload.startDate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + action.payload.startDate.getDate()).slice(-2);
      let checkedElems = state.data.timeReports.filter((el) => action.payload.ids.includes(el.id));
      if (checkedElems.length) {
        if (action.payload.isChild) {
          checkedElems.map((el) => (el.date = date));
        } else {
          state.data.timeReports = state.data.timeReports.filter((el) => !el.checked);
        }
      }
    },
    toggleCheckbox(state, action: PayloadAction<number>) {
      const el = state.data.timeReports.find((item: TimeReportTask) => item.id === action.payload);
      if (el) el.checked = !el.checked;
    },
    goToFirstPage(state) {
      if (!state.pagination) return;

      state.pagination.page = 1;
    },
    goToLastPage(state) {
      if (!state.pagination) return;

      state.pagination.page = state.pagination.pages_total;
    },
    changeRigthEdit(state, action: PayloadAction<number>) {
      state.data.timeReports.filter((el) => el.id === action.payload).map((el) => (el.edit = true));
    },
    changePaginationCount(state, action: PayloadAction<string>) {
      if (action.payload === '' || action.payload === null) {
        state.count = null;
      } else {
        state.count = Number(action.payload);
      }
    },
    changeModalEdit(state, action: PayloadAction<boolean>) {
      state.modalEdit = action.payload;
    },
    changeModalParams(state, action: PayloadAction<IModalParams>) {
      state.modalParams = action.payload;
    },
    getTasks(state, action: PayloadAction<IProjectTask[]>) {
      state.projectTasks = action.payload;
    },
    isEverythingChecked(state, action: PayloadAction<boolean>) {
      state.isAllChecked = action.payload;
    },
    getEditIdWorkedTime(state, action: PayloadAction<number | null>) {
      state.checkedId = action.payload;
    },
    resetTimeReports() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeTaskAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        fetchTimeTaskAsync.fulfilled,
        (state, action: PayloadAction<IPagination<TimeReportTask>>) => {
          state.pagination = action.payload.pagination;
          state.data.timeReports = action.payload.data;
          state.loading = false;
        }
      )
      .addCase(fetchTimeTaskAsync.rejected, (state, action) => {});
  },
});

export const {
  resetPagination,
  changePage,
  changeModalEdit,
  changeRigthEdit,
  goToPrevPage,
  changeMultiplyWorkedTime,
  goToNextPage,
  goToFirstPage,
  goToLastPage,
  changeModalParams,
  changeWorkedTime,
  changePaginationCount,
  // changeSortParam,
  changeTaskFilterParam,
  changePaginationParam,
  addWorkedTime,
  toggleEverythingChecked,
  toggleCheckbox,
  getTasks,
  isEverythingChecked,
  getEditIdWorkedTime,
  resetTimeReports,
} = taskTimeSlice.actions;

export default taskTimeSlice.reducer;
