import { ISelectOptions, ISelectOptionsWithId } from '../../../types';

export enum UserFormFields {
  login = 'login',
  name = 'name',
  password = 'password',
  password_confirm = 'password_confirm',
  title = 'title',
  mobile_phone = 'mobile_phone',
  email = 'email',
  backup_email = 'backup_email',
  vpn_access = 'vpn_access',
  role = 'role',
  projects = 'projects',
}

export type IUserFormFields = {
  [UserFormFields.login]: string;
  [UserFormFields.name]: string;
  [UserFormFields.password]: string;
  [UserFormFields.password_confirm]: string;
  [UserFormFields.title]: string;
  [UserFormFields.mobile_phone]: string;
  [UserFormFields.email]: string;
  [UserFormFields.backup_email]: string;
  [UserFormFields.vpn_access]: boolean;
  [UserFormFields.role]: ISelectOptionsWithId<number>;
  [UserFormFields.projects]: ISelectOptions<number>[];
};
