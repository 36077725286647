import { RootState } from '../../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { IProjectSort, ProjectsState } from './types';
import { IPagination } from '../../../services/api/endpoints/type';
import { IProjectModel } from '../projects/types';
import { IChangePriority, IChangeStatus, IErrorResponse } from '../../../types';
import { AxiosError } from 'axios';
import { INewProjectRequest } from '../../../services/api/endpoints/project';
import { IChangeDescriptionProject } from '../project/types';

export const DEFAULT_COUNT = 50;

const initialState: ProjectsState = {
  data: [],
  loading: false,
  filter: {
    status: 3, // opened
  },
  pagination: null,
  count: DEFAULT_COUNT,
  sort: null,
};

export const fetchProjectsAsync = createAsyncThunk<any, boolean, { state: RootState }>(
  'projects/fetchProjects',
  async function (isPagination: boolean, { rejectWithValue, getState, dispatch }) {
    if (!isPagination) {
      dispatch(resetPagination());
    }

    try {
      const state = getState();

      const params = {
        filter: state.projects.filter.status ? state.projects.filter : undefined,
        pagination: {
          page: state.projects.pagination ? state.projects.pagination.page : undefined,
          count: state.projects.count || undefined,
        },
        sort: state.projects.sort ? state.projects.sort : undefined,
      };

      const response = await api.project.projectsList(params);
      return response.data;
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const addProjectAsync = createAsyncThunk<any, INewProjectRequest, { state: RootState }>(
  'projects/addProject',
  async function (params: INewProjectRequest, { rejectWithValue, dispatch }) {
    try {
      const response = await api.project.add(params);
      const project: IProjectModel = response.data.model;

      dispatch(
        addProject({
          id: project.id,
          name: project.name,
          organization: project.organization,
          priority: project.priority,
          status: project.status,
          description: project.description,
        })
      );
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changeProjectPriorityAsync = createAsyncThunk<
  any,
  IChangePriority,
  { state: RootState }
>(
  'projects/changePriorityProject',
  async function (params: IChangePriority, { rejectWithValue, dispatch }) {
    try {
      await api.project.changeProjectPriority({ id: params.id, priority: params.priority });
      dispatch(changePriorityProject({ id: params.id, priority: +params.priority }));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changeProjectStatusAsync = createAsyncThunk<any, IChangeStatus, { state: RootState }>(
  'projects/changeStatusProject',
  async function (params: IChangeStatus, { rejectWithValue, dispatch }) {
    try {
      await api.project.changeProjectStatus({ id: params.id, status: params.status });
      dispatch(changeStatusProject({ id: params.id, status: params.status }));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

export const changeProjectDescriptionAsync = createAsyncThunk<
  any,
  IChangeDescriptionProject,
  { state: RootState }
>(
  'projects/changeDescriptionProject',
  async function (params: IChangeDescriptionProject, { rejectWithValue, dispatch }) {
    try {
      await api.project.changeProjectDescription({
        id: params.id,
        description: params.description,
      });
      dispatch(changeProjectDescription({ id: params.id, description: params.description }));
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetSort(state) {
      state.sort = null;
    },
    changeSortColumn(state, action: PayloadAction<IProjectSort>) {
      state.sort = {
        [action.payload.field]: action.payload.direction,
      };
    },
    changeProjectDescription(state, action: PayloadAction<IChangeDescriptionProject>) {
      const item = state.data.find((project) => project.id === action.payload.id);
      if (item) {
        item.description = action.payload.description;
      }
    },
    changeFilterParams(state, action: PayloadAction<{ [key: string]: any }>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    resetFilter(state) {
      state.pagination = null;
    },
    resetPagination(state) {
      state.pagination = null;
    },
    changePage(state, action: PayloadAction<number>) {
      if (!state.pagination) return;

      if (action.payload <= state.pagination.pages_total && action.payload > 0) {
        state.pagination.page = action.payload;
      }
    },
    goToPrevPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page - 1 > 0) {
        state.pagination.page -= 1;
      }
    },
    changePriorityProject(state, action: PayloadAction<{ id: number; priority: number }>) {
      const item = state.data.find((project) => project.id === action.payload.id);
      if (item) {
        item.priority = action.payload.priority;
      }
    },
    changeStatusProject(state, action: PayloadAction<{ id: number; status: number }>) {
      const item = state.data.find((project) => project.id === action.payload.id);
      if (item) {
        item.status = action.payload.status;
      }
    },
    goToNextPage(state) {
      if (!state.pagination) return;

      if (state.pagination.page + 1 <= state.pagination.pages_total) {
        state.pagination.page += 1;
      }
    },
    goToFirstPage(state) {
      if (!state.pagination) return;

      state.pagination.page = 1;
    },
    goToLastPage(state) {
      if (!state.pagination) return;

      state.pagination.page = state.pagination.pages_total;
    },
    changePaginationCount(state, action: PayloadAction<string>) {
      if (action.payload === '' || action.payload === null) {
        state.count = null;
      } else {
        state.count = Number(action.payload);
      }
    },
    addProject(state, action: PayloadAction<IProjectModel>) {
      state.data.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        fetchProjectsAsync.fulfilled,
        (state, action: PayloadAction<IPagination<IProjectModel>>) => {
          state.pagination = action.payload.pagination;
          state.data = action.payload.data;
          state.loading = false;
        }
      )
      .addCase(fetchProjectsAsync.rejected, (state, action) => {});
  },
});

export const {
  goToLastPage,
  goToNextPage,
  changeFilterParams,
  changeStatusProject,
  changePage,
  changePriorityProject,
  goToPrevPage,
  changePaginationCount,
  resetSort,
  goToFirstPage,
  resetPagination,
  changeSortColumn,
  changeProjectDescription,
  addProject,
} = projectsSlice.actions;
export default projectsSlice.reducer;
