import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { changeFilterParam, changeKanbanFilterParam } from '../../../store/slices/tasks';
import Select from 'react-select';
import { ISelectOptions } from '../../../types';
import { getValidSelectOptions } from '../../../utils/helpers/selects';
import styles from './styles.module.scss';

interface FilterProps {}

const ProjectFilter: React.FC<FilterProps> = () => {
  const dispatch = useAppDispatch();
  const { data, loading, filter } = useAppSelector((state) => state.tasks);
  const { projects } = data;
  const [selectedProject, setSelectedProject] = useState<ISelectOptions | null>(null);

  const handleProjectFilterChange = (project: ISelectOptions | null) => {
    if (project) {
      dispatch(changeKanbanFilterParam({ milestone: '' }));
      dispatch(changeFilterParam({ project: String(project.value) }));
      localStorage.setItem('default_project_id', String(project.value));
    }
  };

  useEffect(() => {
    if (projects.length && !loading.projects) {
      const project = projects.find((item) => String(item.id) === filter.project);
      if (project) {
        setSelectedProject({ name: project.name, value: project.id });
      }
    }
  }, [projects, loading.projects]);

  return (
    <div className="b-select-light">
      <Select
        className={styles.select}
        options={getValidSelectOptions(projects)}
        value={selectedProject}
        getOptionLabel={(option) => option.name}
        onChange={(option) => {
          setSelectedProject(option);
          handleProjectFilterChange(option);
        }}
        classNamePrefix="custom-select"
        isLoading={loading.projects}
      />
    </div>
  );
};

export default ProjectFilter;
