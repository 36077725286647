import React, { useEffect } from 'react';
import Filter from '../../components/filter';
import TasksTable from '../../components/tasksTable/tasksTable';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import Kanban from '../../components/kanban';
import KanbanFilter from '../../components/kanbanFilter';
import { useAppSelector } from '../../hooks';
import { KanbalListEnum } from '../../store/slices/tasks/types';
import PermissionsGate from '../../components/permissionsGate';
import { Permissions } from '../../types/permissions';
import { Alert } from 'react-bootstrap';

interface MainProps {}

const Main: React.FC<MainProps> = () => {
  const { t } = useTranslation('common');
  const { showAs } = useAppSelector((state) => state.tasks);

  useEffect(() => {
    document.title = t('common.tasks');
  }, []);

  return showAs === KanbalListEnum.List ? (
    <div>
      <Filter></Filter>
      <TasksTable></TasksTable>
    </div>
  ) : (
    <div>
      <KanbanFilter></KanbanFilter>
      <PermissionsGate
        permissions={[Permissions.update_tasks]}
        errorContent={<Alert variant={'danger'}>{t('common.accessDenied')}</Alert>}
      >
        <DndProvider backend={HTML5Backend}>
          <Kanban></Kanban>
        </DndProvider>
      </PermissionsGate>
    </div>
  );
};

export default Main;
