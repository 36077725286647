import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { CommentHeader } from './CommentHeader';
import ArrowRight from '../../../assets/images/icons/arrow-right.svg';
import Pin from '../../../assets/images/icons/pin.svg';
import './style.css';
import { CommentCard } from '../../commentCard';
import { CommentEditor } from './CommentEditor';
import { Button } from 'react-bootstrap';
import {
  deleteCommentAsync,
  fetchCustomerComments,
  fetchTeamComments,
  startDiscussionAsync,
} from '../../../store/slices/task/comments';
import { ICommentModel } from '../../../store/slices/task/types';
import { fetchFilesAsync } from '../../../store/slices/task/files';
import { Permissions } from '../../../types/permissions';
import PermissionsGate from '../../permissionsGate';

interface CommentsSectionProps {
  published: string;
}

export const CommentsTab: React.FC<CommentsSectionProps> = ({ published }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { task } = useAppSelector((state) => state.task);
  const { teamComments, customerComments } = useAppSelector((state) => state.taskComments);

  const [stickyStatus, setStickyStatus] = useState<boolean>(false);

  const stickyMessages =
    published === '1'
      ? teamComments.comments.filter((item) => item.show_on_top === 1)
      : customerComments.comments.filter((item) => item.show_on_top === 1);
  const notStickyMessages =
    published === '1'
      ? teamComments.comments.filter((item) => item.show_on_top === 0)
      : customerComments.comments.filter((item) => item.show_on_top === 0);
  const discussionStatus = published === '1' ? task?.has_discussion_team : task?.has_discussion;

  useEffect(() => {
    if (published === '1') {
      dispatch(fetchTeamComments());
    } else {
      dispatch(fetchCustomerComments());
    }
  }, []);

  const deleteComment = async (comment: ICommentModel) => {
    if (window.confirm(`${t('comments.delete')} #${comment.id}?`)) {
      try {
        await dispatch(
          deleteCommentAsync({
            commentId: comment.id,
            teamComment: published === '1',
            published,
          })
        );

        // Обновить вкладку файлов
        if (comment.files.length) {
          dispatch(fetchFilesAsync());
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const startDiscussion = async () => {
    if (task) {
      dispatch(
        startDiscussionAsync({
          taskId: task.id,
          published,
        })
      );
    }
  };

  return task ? (
    <div className="comment-team-tab">
      <CommentHeader
        published={published}
        subscribers={published === '1' ? teamComments.subscribers : customerComments.subscribers}
      />
      {discussionStatus ? (
        <>
          <PermissionsGate permissions={[Permissions.create_posts]}>
            <CommentEditor published={published} />
          </PermissionsGate>

          {stickyMessages && stickyMessages.length ? (
            <>
              <div className="sticky-show" onClick={() => setStickyStatus(!stickyStatus)}>
                <img src={Pin} alt="" />
                <p>{t('comments.sticky')}</p>
                <img
                  src={ArrowRight}
                  alt=""
                  className={'sticky-show-image' + (stickyStatus ? ' rotate' : '')}
                />
              </div>
              {stickyStatus ? (
                <div className="stickyMessages">
                  {stickyMessages?.map((item) => (
                    <CommentCard
                      key={item.id}
                      published={published}
                      pinnedStatus={true}
                      comment={item}
                      onDelete={() => deleteComment(item)}
                    />
                  ))}
                </div>
              ) : null}
            </>
          ) : null}
          <div className="comments">
            {notStickyMessages?.map((item) => (
              <CommentCard
                key={item.id}
                published={published}
                pinnedStatus={false}
                comment={item}
                onDelete={() => deleteComment(item)}
              />
            ))}
          </div>
        </>
      ) : (
        <Button className="comment-start-discussion" onClick={() => startDiscussion()}>
          {t('comments.startDiscussion')}
        </Button>
      )}
    </div>
  ) : null;
};
