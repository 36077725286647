import { RootState } from '../../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { CurrentUserState } from './types';
import { AxiosError } from 'axios';
import { IErrorResponse } from '../../../types';
import { IUserInfo } from '../../../services/api/endpoints/user';

const initialState: CurrentUserState = {
  id: null,
  name: '',
  ui_lang: '',
  role: '',
  permissions: [],
};

export const fetchUserInfo = createAsyncThunk<any, void, { state: RootState }>(
  'currentUser/fetchUserInfo',
  async function (_, { rejectWithValue }) {
    try {
      const response = await api.user.self();
      return response.data.user;
    } catch (err: any) {
      let error: AxiosError<IErrorResponse> = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data.error_message);
    }
  }
);

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state, action) => {})
      .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<IUserInfo>) => {
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.ui_lang = action.payload.ui_lang;
        state.role = action.payload.role;
        state.permissions = action.payload.permissions;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {});
  },
});

// export const {  } = tasksSlice.actions;

export default currentUserSlice.reducer;
