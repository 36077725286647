import React from 'react';
import { IUserData } from '../../../../store/slices/admin/companiesAndUsers/types';
import { useTranslation } from 'react-i18next';
import { userRoles } from '../../../../utils/constants';
import editImage from '../../../../assets/images/icons/edit.svg';

interface UsersTableProps {
  users: IUserData[];
  editUser: (id: number) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ users, editUser }) => {
  const { t } = useTranslation('common');

  const getUserRole = (roleID: number) => {
    const userRole = userRoles.find((role) => role.id === roleID);
    return userRole ? t(userRole.name) : '?';
  };

  return (
    <div className="b-table-companies">
      <div className="b-table b-table-header">
        <div className="tr">
          <div className="td">{t('headerCompaniesTable.name')}</div>
          <div className="td">ID</div>
          <div className="td">{t('headerCompaniesTable.title')}</div>
          <div className="td">{t('headerCompaniesTable.role')}</div>
          <div className="td">{t('headerCompaniesTable.company')}</div>
        </div>
      </div>
      <table className="b-table">
        {users.map((user) => (
          <tr key={user.id} className="tr">
            <td className="td">
              <span>{user.name}</span>
              <div className="b-table-companies-edit" onClick={() => editUser(user.id)}>
                <img src={editImage} alt="" />
              </div>
            </td>
            <td className="td">{user.id}</td>
            <td className="td">{user.title}</td>
            <td className="td">{getUserRole(+user.role)}</td>
            <td className="td">{user.organization ? user.organization.name : ''}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default UsersTable;
