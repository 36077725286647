import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import api from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ILoginFormFields, LoginFormFields } from './types';

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();
  const { token, setToken } = useAuth();

  const [error, setError] = useState('');
  const [pending, setPending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormFields>({
    defaultValues: {
      [LoginFormFields.username]: '',
      [LoginFormFields.password]: '',
    },
  });

  useEffect(() => {
    document.title = t('common.login');
  }, []);

  const onSubmit: SubmitHandler<ILoginFormFields> = async (data) => {
    setPending(true);

    await api.auth
      .login({
        username: data.username,
        password: data.password,
      })
      .then((response) => {
        if (response.data.result === 'OK') {
          setToken(response.data.token);
          navigate('/', { replace: true });
        }
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.data?.error_message) {
          setError(error.response.data.error_message);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  if (token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h2>Log In</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              {...register(LoginFormFields.username, { required: true })}
              isInvalid={!!errors.username}
            />
            <Form.Control.Feedback type="invalid">{t('formErrors.required')}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              {...register(LoginFormFields.password, { required: true })}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{t('formErrors.required')}</Form.Control.Feedback>
          </Form.Group>
          <div className={styles.center}>
            <Button type="submit" disabled={pending}>
              Log in
            </Button>
          </div>
        </Form>
        {error ? <div className={clsx(styles.center, styles.errorMessage)}>{error}</div> : null}
      </div>
    </div>
  );
};

export default Login;
