import { BooleanNumber, IEntity, IOKResult, SortDirection } from '../../../types';
import { axios } from '../axios';
import { IPagination } from './type';

let qs = require('qs');

interface IUserRequestData {
  id: number;
}

interface IUserListData {
  filter?: {
    exclude_customers: boolean;
    exclude_inactive: boolean;
  };
}

export interface IUserInfo {
  id: number;
  name: string;
  ui_lang: string;
  role: string;
  permissions: string[];
}
interface IUserInfoOKResult {
  result: string;
  user: IUserInfo;
}

export interface IUsersTableRequest {
  pagination?: {
    page?: number;
    count?: number;
  };
  sort?: {
    [key: string]: SortDirection;
  };
  filter?: {
    name: string;
  };
}

export interface IUsersTableModel {
  id: number;
  name: string;
  title: string;
  role: number;
  organization: {
    id: number;
    name: string;
  };
}

export interface IUsersCreateRequest {
  login: string;
  name: string;
  password: string;
  password_confirm: string;
  title?: string;
  email?: string;
  backup_email?: string;
  mobile_phone?: string;
  vpn_access?: BooleanNumber;
  role?: number;
  projects?: number[];
}

export interface IUsersInfoModel {
  id: number;
  login: string;
  name: string;
  title: string;
  role: number;
  email_work: string;
  email_home: string;
  phone_work: string;
  phone_home: string;
  mobile: string;
  created: string;
  vpn_access: boolean;
  organization: IEntity;
  projects: IEntity[];
}

const endpoints = {
  list: (data: IUserListData = {}) =>
    axios.get('/user/list', {
      params: data,
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    }),
  table: (data: IUsersTableRequest = {}) =>
    axios.get<IPagination<IUsersTableModel>>('/user/table', {
      params: data,
    }),
  get: (data: IUserRequestData) =>
    axios.get('/project/users', {
      params: data,
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    }),
  self: () => axios.get<IUserInfoOKResult>('/user/self'),
  getInfoUser: (id: number) => axios.get<IUsersInfoModel>(`/user/${id}`),
  create: (data: IUsersCreateRequest) => axios.post('/user/create', data),
  update: (id: number, data: IUsersCreateRequest) => axios.put(`/user/update/${id}`, data),
};

export default endpoints;
