import i18n from '../../i18n/instance';
import { IEntity, ISelectOptions } from '../../types';

export const getValidSelectOption = (option: IEntity) => {
  return {
    ...option,
    value: option.id,
  };
};

export const getValidSelectOptions = (options: IEntity[]) => {
  return options.map((option) => {
    return getValidSelectOption(option);
  });
};

export const translateOption = (option: ISelectOptions<any>) => {
  return { ...option, name: i18n.t(option.name, { ns: 'common' }) };
};

export const translateOptions = (options: ISelectOptions<any>[]) => {
  return options.map((option: ISelectOptions<any>) => {
    return translateOption(option);
  });
};
