import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OutsideAlerter from '../../outsideAlerter';
import './style.css';

interface linkPopoverProps {
  linksArray?: number[];
}

export const LinkPopover: React.FC<linkPopoverProps> = ({ children, linksArray = null }) => {
  const [linkPopoverClose, setLinkPopoverClose] = useState(false);
  const [coppiedRecently, setCoppiedRecently] = useState(false);
  const { t } = useTranslation('common');

  const closeHandler = () => {
    setLinkPopoverClose(false);
  };

  const onTextClick = (linksArray: number[] | null) => {
    setCoppiedRecently(true);
    if (linksArray) {
      if (linksArray.length > 0) {
        const copyText = linksArray.reduce(
          (prev, item) => prev + `- ${window.location.origin}/tasks/${item}\r\n`,
          ''
        );
        navigator.clipboard.writeText(copyText);
      } else {
        setCoppiedRecently(false);
      }
    } else {
      navigator.clipboard.writeText(window.location.origin + window.location.pathname);
    }
    setTimeout(() => setCoppiedRecently(false), 1000);
  };

  const popoverLink = (
    <Popover className="popoverlink">
      <OutsideAlerter handler={() => closeHandler()}>
        <Popover.Body className="linkpopover-wrapper">
          <div className="linkpopover-header">
            <Button
              size="sm"
              className="linkpopover-button"
              onClick={() => onTextClick(linksArray)}
            >
              {t('detailPage.copyTaskPath')}
            </Button>
            <p className="linkpopover-warning">
              {coppiedRecently ? `${t('popover.coppied')}` : null}
            </p>
          </div>
          <div className="linkpopover-content">
            {linksArray
              ? linksArray.map((item) => {
                  return (
                    <div className="task-link" key={item}>
                      - {window.location.origin}/tasks/{item}
                    </div>
                  );
                })
              : `${window.location.origin}${window.location.pathname}`}
          </div>
        </Popover.Body>
      </OutsideAlerter>
    </Popover>
  );
  return (
    <OverlayTrigger show={linkPopoverClose} placement="bottom" overlay={popoverLink}>
      <div
        onClick={() => {
          if (linksArray) {
            if (linksArray.length > 0) {
              setLinkPopoverClose(true);
            } else {
              setLinkPopoverClose(false);
            }
          } else {
            setLinkPopoverClose(true);
          }
        }}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
};
