import React from 'react';
import './globalSearch.css';
import { SearchItemType } from './globalSearch';
import { Link } from 'react-router-dom';

interface SearchItemRowProps {
  id: number | string;
  name: string;
  type: SearchItemType;
  closeSearch: () => void;
}

const SearchItemRow: React.FC<SearchItemRowProps> = ({ id, name, type, closeSearch }) => {
  return (
    <div className="global-search-row">
      { // TODO: На данный момент работает только для задач, поскольку детальные страницы проектов и спринтов ещё не готовы
        type === SearchItemType.Task ? (
          <Link to={`/tasks/${id}`} onClick={() => closeSearch()} className='link'>{name}</Link>
        ) : <div className='link'>{name}</div>
      }
    </div>
  );
};

export default SearchItemRow;
