import React, { useState } from 'react';
import { priorities } from '../../utils/constants';
import RadioButtonList from '../tasksTable/radioButtonList';
import cl from './style.module.scss';
import editImage from '../../assets/images/icons/edit.svg';
import { Permissions } from '../../types/permissions';
import PermissionsGate from '../permissionsGate';

interface ChangePriorityProps {
  priority: number;
  onChangePriority: (priority: number) => void;
  permission: Permissions;
  showPriority?: boolean;
}

const ChangePriority: React.FC<ChangePriorityProps> = ({
  priority,
  onChangePriority,
  permission,
  showPriority = true,
}) => {
  const [isPriorityPopoverOpen, setIsPriorityPopoverOpen] = useState(false);

  const priorityChangeHandler = (newPriority: number) => {
    if (priority === Number(newPriority)) {
      return;
    }
    onChangePriority(newPriority);
  };

  return (
    <div className={cl.changePriority}>
      {showPriority ? (
        <div
          className={cl.priority}
          style={{ backgroundColor: priorities.find((item) => priority === +item.value)?.color }}
        ></div>
      ) : null}
      <PermissionsGate permissions={[permission]}>
        <RadioButtonList
          list={priorities}
          callback={priorityChangeHandler}
          checked={priority}
          isStatusShow={isPriorityPopoverOpen}
          setStatusShow={setIsPriorityPopoverOpen}
        >
          <div className={cl.btnEdit}>
            <img src={editImage} alt="" />
          </div>
        </RadioButtonList>
      </PermissionsGate>
    </div>
  );
};

export default ChangePriority;
