import React from 'react';
import fileImage from '../../../assets/images/icons/file.svg';
import { IFile } from '../../../types';
import styles from './style.module.scss';
import PreviewFile from '../../previewFile';

interface FileListProps {
  files: IFile[];
}

const FileList: React.FC<FileListProps> = ({ files }) => {
  return (
    <div className={styles.filelist}>
      {files.map((file) => (
        <div key={file.id} className={styles.item}>
          <img src={fileImage} alt="" className={styles.icon} />
          <PreviewFile file={file} />
        </div>
      ))}
    </div>
  );
};

export default FileList;
