import { Dayjs } from 'dayjs';

// Enums

export enum TaskType {
  Milestone = 'milestone',
  Task = 'task',
  Bug = 'bug',
  UIIssue = 'ui_issue',
  Request = 'request',
  Topic = 'topic',
}

export enum UserRoles {
  Admin = 'admin',
  PM = 'pm',
  User = 'user',
  Customer = 'customer',
  Disabled = 'disabled',
}

// Types

export type DateRange = [Date | null, Date | null];
export type DayjsRange = [Dayjs | null, Dayjs | null];
export type BooleanNumber = 0 | 1;
export type SortDirection = 'asc' | 'desc';

// Interfaces

export interface IEntity {
  id: number;
  name: string;
}

export interface ISelectOptions<T = string | number> {
  name: string;
  value: T;
}

export interface ISelectOptionsWithId<T = string | number> {
  id: number;
  name: string;
  value: T;
}

export interface IPriority<T = string | number> extends ISelectOptions<T> {
  color: string;
}

export interface IStatus<T = string | number> extends ISelectOptions<T> {
  borderColor: string;
  color: string;
}

export interface ITagModel {
  id: number;
  project_id: number;
  text: string;
}

export interface IErrorResponse {
  error: string | number;
  error_message: string;
}

export interface IFile {
  id: number;
  name: string;
  original_name: string;
  extension: string;
}

export interface IAvailableItemTypes {
  [TaskType.Bug]: boolean;
  [TaskType.Milestone]: boolean;
  [TaskType.Request]: boolean;
  [TaskType.Task]: boolean;
  [TaskType.Topic]: boolean;
  [TaskType.UIIssue]: boolean;
}

export interface IChangePriority {
  id: number;
  priority: number;
}

export interface IChangeStatus {
  id: number;
  status: number;
}

export interface IOKResult<T = any> {
  result: string;
  model: T;
}
