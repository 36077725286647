import { useState } from 'react';
import { Outlet } from 'react-router';
import GlobalSearch from '../../components/globalSearch/globalSearch';
import styles from './style.module.scss';
import clsx from 'clsx';
import { Navbar } from '../../components/navbar';
import Logo from '../../components/logo';

const Layout = () => {
  const languages = ['En', 'Ру'];
  const [collapsed, setCollapsed] = useState(true);
  const [lang, setLang] = useState('En');

  return (
    <div className={styles.page}>
      <Navbar
        setCollapsed={setCollapsed}
        languages={languages}
        collapsed={collapsed}
        setLang={setLang}
        lang={lang}
      />
      <div className={styles.content}>
        <div className={clsx(styles.header, styles.sticky)}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <GlobalSearch />
        </div>

        <div className={styles.workspace}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
