import React, { useEffect } from 'react';
import cl from './style.module.scss';
import {
  changePage,
  changePaginationCount,
  fetchProjectsAsync,
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPrevPage,
} from '../../store/slices/projects';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Preloader from '../preloader';
import Pagination from '../pagination';
import CountPerPage from '../countPerPage';
import ProjectTable from './projectTable';
import { ProjectFilters } from './filters/projectFilters';

const ProjectTab = () => {
  const dispatch = useAppDispatch();
  const { filter, pagination, count, loading, sort } = useAppSelector((state) => state.projects);

  useEffect(() => {
    dispatch(fetchProjectsAsync(false));
  }, [filter, sort]);

  return (
    <div className={cl.projectTab}>
      <ProjectFilters />
      {loading ? (
        <Preloader />
      ) : (
        <>
          <ProjectTable />
          <div className={cl.container}>
            {pagination ? (
              <Pagination
                currentPage={pagination.page}
                pagesTotal={pagination.pages_total}
                onChangePage={(i: number) => {
                  dispatch(changePage(i));
                  dispatch(fetchProjectsAsync(true));
                }}
                onGoToFirstPage={() => {
                  dispatch(goToFirstPage());
                  dispatch(fetchProjectsAsync(true));
                }}
                onGoToPrevPage={() => {
                  dispatch(goToPrevPage());
                  dispatch(fetchProjectsAsync(true));
                }}
                onGoToNextPage={() => {
                  dispatch(goToNextPage());
                  dispatch(fetchProjectsAsync(true));
                }}
                onGoToLastPage={() => {
                  dispatch(goToLastPage());
                  dispatch(fetchProjectsAsync(true));
                }}
              />
            ) : (
              <div />
            )}
            <CountPerPage
              countPerPage={count}
              onChange={(count: string) => {
                dispatch(changePaginationCount(count));
                dispatch(fetchProjectsAsync(false));
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default ProjectTab;
