import { ITagModel } from '../../../../types';

// Enums

export enum GroupBy {
  Project = 'project',
  Sprint = 'sprint',
}

export enum KanbalListEnum {
  List = 'list',
  Kanban = 'kanban',
}

// Interfaces

export interface TasksState {
  filter: {
    priority: string;
    owner: string;
    billing: string;
    status: string;
    project: string;
    milestone: string;
    created_enum: string;
    created: string;
    created_until: string;
    due_enum: string;
    due_date: string;
    due_date_until: string;
    assigned_to: string;
    type: string;
  };
  showAs: KanbalListEnum;
  sort: {
    column: string;
    direction: number;
  };
  groupBy: {
    sprint: boolean;
    project: boolean;
  };
  data: {
    projects: IProject[];
    sprints: ISprint[];
    tasks: ITask[];
  };
  loading: {
    projects: boolean;
    sprints: boolean;
    tasks: boolean;
  };
  kanban: {
    filter: {
      milestone: string;
    };
    tasks: ITask[];
    loading: boolean;
  };
  minimisedTasksList: number[];
}

export interface IProjectModel {
  id: number;
  name: string;
}

export interface IProject extends IProjectModel {
  isChecked: boolean;
  isMinimised: boolean;
}

export interface ISprintModel {
  id: number;
  name: string;
  project_id: number;
  start_date?: string;
  due_date?: string;
}

export interface ISprint extends ISprintModel {
  isChecked: boolean;
  isMinimised: boolean;
  isUnsorted: boolean;
}

export interface ITaskModel {
  id: number;
  project_id: number;
  sprint_id: number | null;
  parent_task: number | null;
  priority: number;
  status: number;
  type: string;
  name: string;
  tags: ITagModel[];
  assigned_to: {
    id: number;
    name: string;
  } | null;
  due_date: string;
  estimated_time: string;
  actual_time: string;
  msg_count_team: number;
  msg_count_customer: number;
  files_count: number;
  customer_visible: boolean;
  has_subitems?: boolean;
}

export interface ITask extends ITaskModel {
  isChecked: boolean;
  isMinimised: boolean;
  childrenAreChecked: boolean;
}

export interface ITasksResponse {
  projects: IProjectModel[];
  sprints: ISprintModel[];
  tasks: ITaskModel[];
}
