import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import api from '../../../../services/api';
import { IMultipleChangeRequest } from '../../../../services/api/endpoints/task';
import { fetchTasksAsync } from '../../../../store/slices/tasks';
import { ISprintModel } from '../../../../store/slices/tasks/types';
import { priorities, statuses, taskTypes } from '../../../../utils/constants';
import styles from './style.module.scss';
import { ISelectOptions } from '../../../../types';
import { getValidSelectOptions } from '../../../../utils/helpers/selects';

interface GroupEditingFormProps {
  checkedTasksIds: number[];
  closePopover: () => void;
}

type Option = string | number | null;

const GroupEditingForm: React.FC<GroupEditingFormProps> = ({ checkedTasksIds, closePopover }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const { data, filter } = useAppSelector((state) => state.tasks);
  const { projects } = data;

  const emptyOption = { name: 'common.doNotChange', value: null };
  const emptyOptionCustom = { name: 'common.doNotChangeCustom', value: null };

  const [project, setProject] = useState<ISelectOptions<Option> | null>(emptyOption);
  const [sprint, setSprint] = useState<ISelectOptions<Option> | null>(emptyOptionCustom);
  const [status, setStatus] = useState<ISelectOptions<Option> | null>(emptyOption);
  const [priority, setPriority] = useState<ISelectOptions<Option> | null>(emptyOption);
  const [type, setType] = useState<ISelectOptions<Option> | null>(emptyOption);
  const [assignee, setAssignee] = useState<ISelectOptions<Option> | null>(emptyOptionCustom);

  const [assigneeLoading, setAssigneeLoading] = useState<boolean>(false);
  const [sprintLoading, setSprintLoading] = useState<boolean>(false);

  // Параметры которые зависят от выбранного проекта
  const [projectAssignees, setProjectAssignees] = useState<ISelectOptions<Option>[]>([
    emptyOptionCustom,
  ]);
  const [projectSprints, setProjectSprints] = useState<ISelectOptions<Option>[]>([
    emptyOptionCustom,
  ]);

  const fetchSprints = async (projectId: number | string | null) => {
    setProjectSprints([emptyOptionCustom]);
    setSprint(emptyOptionCustom);
    if (projectId === null) return;

    setSprintLoading(true);
    const sprintsResponse = await api.milestone.myMilestones({ project_id: Number(projectId) });
    const sprints: ISelectOptions<Option>[] = [
      emptyOptionCustom,
      ...sprintsResponse.data.map((sprint: ISprintModel) => ({
        ...sprint,
        value: sprint.id,
      })),
    ];
    setProjectSprints(sprints);
    setSprintLoading(false);
  };

  const fetchAssignees = async (projectId: number | string | null) => {
    setProjectAssignees([emptyOptionCustom]);
    setAssignee(emptyOptionCustom);
    if (projectId === null) return;

    setAssigneeLoading(true);
    const usersResponse = await api.project.users({ id: Number(projectId) });
    const users: ISelectOptions<Option>[] = [
      emptyOptionCustom,
      ...usersResponse.data.map((user) => ({
        ...user,
        value: user.id,
      })),
    ];
    setProjectAssignees(users);
    setAssigneeLoading(false);
  };

  useEffect(() => {
    if (project) {
      fetchSprints(project.value);
      fetchAssignees(project.value);
    }
  }, [project]);

  useEffect(() => {
    const currProjectName = projects.find((el) => String(el.id) === filter.project);
    if (currProjectName) {
      setProject({ name: currProjectName.name, value: currProjectName.id });
    }
  }, []);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (checkedTasksIds.length === 0) return;

    const params: IMultipleChangeRequest = {
      tasks: checkedTasksIds,
    };

    if (project && project.value) {
      params.project = Number(project.value);
    }
    if (sprint && sprint.value) {
      params.milestone_id = Number(sprint.value);
    }
    if (priority && priority.value) {
      params.priority = Number(priority.value);
    }
    if (status && status.value) {
      params.status = Number(status.value);
    }
    if (type && type.value) {
      params.type = String(type.value);
    }
    if (assignee && assignee.value) {
      params.assigned_to = String(assignee.value);
    }

    await api.task
      .multipleChange(params)
      .then((response) => {
        dispatch(fetchTasksAsync());
      })
      .catch((error: AxiosError) => {
        alert(error.response?.data.error_message || 'Произошла ошибка');
      });

    closePopover();
  };

  return (
    <Form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>{t('groupEditing.title')}</div>
          <div className={styles.note}>
            {t('groupEditing.selectedTasks')} {checkedTasksIds.length}.{' '}
            {t('groupEditing.description')}
          </div>
        </div>

        <div className={styles.buttons}>
          <Button variant="outline-primary" onClick={closePopover}>
            {t('common.cancel')}
          </Button>
          <Button type="submit">{t('common.save')}</Button>
        </div>
      </div>
      <div className={styles.content}>
        <Row className="mb-2">
          <Col>
            <div className="b-select-light">
              <label>{t('common.project')}</label>
              <Select
                options={getValidSelectOptions(projects)}
                value={project}
                getOptionLabel={(option) => t(option.name)}
                onChange={(option) => {
                  setProject(option);
                }}
                classNamePrefix="custom-select"
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="b-select-light">
              <label>{t('common.sprint')}</label>
              <Select
                options={projectSprints}
                value={sprint}
                getOptionLabel={(option) => t(option.name)}
                onChange={(option) => {
                  setSprint(option);
                }}
                isSearchable={false}
                classNamePrefix="custom-select"
                isLoading={sprintLoading}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="b-select-light">
              <label>{t('filter.priority')}</label>
              <Select
                options={priorities}
                value={priority}
                getOptionLabel={(option) => t(option.name)}
                onChange={(option) => {
                  setPriority(option);
                }}
                isSearchable={false}
                classNamePrefix="custom-select"
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="b-select-light">
              <label>{t('filter.status')}</label>
              <Select
                options={statuses}
                value={status}
                getOptionLabel={(option) => t(option.name)}
                onChange={(option) => {
                  setStatus(option);
                }}
                isSearchable={false}
                classNamePrefix="custom-select"
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="b-select-light">
              <label>{t('filter.type')}</label>
              <Select
                options={taskTypes}
                value={type}
                getOptionLabel={(option) => t(option.name)}
                onChange={(option) => {
                  setType(option);
                }}
                isSearchable={false}
                classNamePrefix="custom-select"
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="b-select-light">
              <label>{t('filter.assignee')}</label>
              <Select
                options={projectAssignees}
                value={assignee}
                getOptionLabel={(option) => (option.value === null ? t(option.name) : option.name)}
                onChange={(option) => {
                  setAssignee(option);
                }}
                classNamePrefix="custom-select"
                isLoading={assigneeLoading}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default GroupEditingForm;
