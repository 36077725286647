import { useAppSelector } from '../../hooks';
import { IProjectTask } from '../../store/slices/task/types';

export const useDefaultTasks = (): Array<IProjectTask | null> => {
  const { task } = useAppSelector((state) => state.task);
  if (!task || !task.parent_task_path) return [null, null, null];

  switch (task.parent_task_path.length) {
    case 0:
      return [
        {
          id: task.id,
          name: task.name,
          value: task.id,
        },
        null,
        null,
      ];
    case 1:
      return [
        {
          ...task.parent_task_path[0],
          value: task.parent_task_path[0].id,
        },
        {
          id: task.id,
          name: task.name,
          value: task.id,
        },
        null,
      ];
    case 2:
      return [
        {
          ...task.parent_task_path[0],
          value: task.parent_task_path[0].id,
        },
        {
          ...task.parent_task_path[1],
          value: task.parent_task_path[1].id,
        },
        {
          id: task.id,
          name: task.name,
          value: task.id,
        },
      ];
    default:
      return [null, null, null];
  }
};
