import React, { useEffect, useState } from 'react';
import { TaskTimeHeader } from './TaskTimeHeader';
import { TaskTimeTable } from './TaskTimeTable';
import './style.css';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import TaskTimeLocal from './TaskTimeLocal';
import Pagination from '../../pagination';
import CountPerPage from '../../countPerPage';
import {
  changePage,
  changePaginationCount,
  fetchTimeTaskAsync,
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPrevPage,
  isEverythingChecked,
  toggleEverythingChecked,
} from '../../../store/slices/task/timeReports';
import Preloader from '../../preloader';

export interface TimeTabProps {}

export const TimeTab: React.FC<TimeTabProps> = () => {
  const dispatch = useAppDispatch();
  const { filter, pagination, count, loading, data } = useAppSelector((state) => state.taskTime);
  const { id: userId } = useAppSelector((state) => state.currentUser);
  const [selectedSort, setSelectedSort] = useState<string>('0');

  useEffect(() => {
    dispatch(fetchTimeTaskAsync(false));
  }, [filter]);

  useEffect(() => {
    dispatch(toggleEverythingChecked(false));
    dispatch(isEverythingChecked(false));
  }, [count]);

  return (
    <div className="timeTab">
      <TaskTimeHeader />
      <TaskTimeTable userId={userId ?? -1} setSelectedSort={setSelectedSort} />
      {loading ? (
        <Preloader />
      ) : (
        <div className="timeTab-container">
          {pagination && data.timeReports.length ? (
            <Pagination
              currentPage={pagination.page}
              pagesTotal={pagination.pages_total}
              onChangePage={(i: number) => {
                dispatch(changePage(i));
                dispatch(fetchTimeTaskAsync(true));
              }}
              onGoToFirstPage={() => {
                dispatch(goToFirstPage());
                dispatch(fetchTimeTaskAsync(true));
              }}
              onGoToPrevPage={() => {
                dispatch(goToPrevPage());
                dispatch(fetchTimeTaskAsync(true));
              }}
              onGoToNextPage={() => {
                dispatch(goToNextPage());
                dispatch(fetchTimeTaskAsync(true));
              }}
              onGoToLastPage={() => {
                dispatch(goToLastPage());
                dispatch(fetchTimeTaskAsync(true));
              }}
            />
          ) : (
            <div />
          )}
          {data.timeReports.length ? (
            <CountPerPage
              countPerPage={count}
              onChange={(count: string) => {
                dispatch(changePaginationCount(count));
                dispatch(fetchTimeTaskAsync(false));
              }}
            />
          ) : (
            ''
          )}
        </div>
      )}
      <TaskTimeLocal />
    </div>
  );
};
