import axios, { AxiosError } from "axios";
import api from "../..";

type subscriberCallback = (access_token: string | null) => void;

let isAlreadyFetchingAccessToken = false;
let subscribers: Array<subscriberCallback> = [];

export const resetTokenAndReattemptRequest = async (error: AxiosError) => {
  try {
    const { response: errorResponse } = error;
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((access_token: string | null) => {
        if (errorResponse && errorResponse.config.headers) {
          errorResponse.config.headers.Authorization = `Bearer ${access_token ?? ''}`;
          resolve(axios(errorResponse.config));
        }
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const response = await api.auth.refreshToken();
      localStorage.setItem('token', response.data.token);
      onAccessTokenFetched(response.data.token);

      isAlreadyFetchingAccessToken = false;
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
};

const onAccessTokenFetched = (access_token: string | null) => {
  subscribers.forEach((callback) => callback(access_token));
  subscribers = [];
};

const addSubscriber = (callback: subscriberCallback) => {
  subscribers.push(callback);
};
