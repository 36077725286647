import React from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

export const TimeReportsHeader = () => {
  const { t } = useTranslation('common');

  return (
    <div className="sticky-header">
      <div className="b-timeTable-header">
        <div className="b-timeTable-header-project timeTable time-table-tr">Project</div>
        <div className="b-timeTable-header-date timeTable time-table-tr">{t('timeEntry.date')}</div>
        <div className="b-timeTable-header-created timeTable time-table-tr">
          {t('filter.created')}
        </div>
        <div className="b-timeTable-header-user timeTable time-table-tr">{t('common.user')}</div>
        <div className="b-timeTable-header-type timeTable time-table-tr">{t('filter.type')}</div>
        <div className="b-timeTable-header-item timeTable time-table-tr">{t('common.item')}</div>
        <div className="b-timeTable-header-status timeTable time-table-tr">
          {t('filter.status')}
        </div>
        <div className="b-timeTable-header-comment timeTable time-table-tr">
          {t('timeEntry.comment')}
        </div>
        <div className="b-timeTable-header-hours timeTable time-table-tr">
          {t('headerTable.time')}
        </div>
      </div>
    </div>
  );
};
