import React from 'react';
import { useAppSelector } from '../../hooks';
import { checkAllUserAccess, checkLeastOneUserAccess } from '../../utils/helpers/permissions';
import { Permissions } from '../../types/permissions';

interface PermissionsGateProps {
  permissions: Permissions[];
  checkingAllPermissions?: boolean;
  errorContent?: JSX.Element;
}

const PermissionsGate: React.FC<PermissionsGateProps> = ({
  children,
  permissions,
  checkingAllPermissions = true, // по умолчанию проверяется наличие каждого разрешения
  errorContent = <></>,
}) => {
  const { id: userId } = useAppSelector((state) => state.currentUser);

  if (userId === null) {
    return null;
  } else if (
    checkingAllPermissions
      ? !checkAllUserAccess(permissions)
      : !checkLeastOneUserAccess(permissions)
  ) {
    return errorContent;
  }

  return <>{children}</>;
};

export default PermissionsGate;
